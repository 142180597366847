import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";

import { getUserByIdApi } from "api/api.clients";
import CitizensDrawerStore from "components/Citizens/store/Citizens.drawer.store";
import BaseModal from "components/Modals/BaseModal";

import ModalConfirmEditCitizenStore from "./store/ModalConfirmEditCitizen.store";

const ModalConfirmEditCitizen = () => {
	const { data: currentUser, isLoading: isLoading } = useQuery({
		queryFn: () => getUserByIdApi({ path: { id: ModalConfirmEditCitizenStore.clickedUserId } }),
		queryKey: ["clickedUserId", ModalConfirmEditCitizenStore.clickedUserId],
		enabled: !!ModalConfirmEditCitizenStore.clickedUserId,
		cacheTime: 0,
	});

	const onOk = () => {
		CitizensDrawerStore.openDrawer({
			mode: "visible",
			user: currentUser,
		});
		ModalConfirmEditCitizenStore.close();
	};

	return (
		<BaseModal
			isVisible={ModalConfirmEditCitizenStore.isVisible}
			title={"Перед тем, как изменять параметры пользователя, с ним необходимо связаться"}
			status="delete"
			okText="Ок"
			onOk={isLoading ? undefined : onOk}
			onExit={ModalConfirmEditCitizenStore.close}
		/>
	);
};

export default observer(ModalConfirmEditCitizen);
