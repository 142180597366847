import { FC, useEffect, useState } from "react";

import { useQuery } from "react-query";
import { List } from "tap2visit-ui-kit";

import { getAnswersByOptionApi } from "api/api.votes";
import useTablePaginationLogic from "hooks/useTablePaginationLogic";
import { IQuestionAnswerDto } from "interfaces/IVote";
import { QueriesKeys } from "interfaces/queriesKeys";
import usePollIdFromURL from "pages/polls/hooks/usePollIdFromURL";

import PollsEditStore from "../../store/PollsItem.store";

import ChartDescription from "./ChartDescription/ChartDescription";
import ChartEmpty from "./ChartEmpty/ChartEmpty";
import { getAnswers } from "./CircleChart/utils/getAnswers";
import usePollAnswers from "./hooks/usePollAnswers";
import AnswersTable from "./PollAnswersTable";
import { getAnswerText } from "./utils/getAnswerText";

interface IFreeAnswer {
	question: IQuestionAnswerDto;
}

const FreeAnswer: FC<IFreeAnswer> = (props) => {
	const [isVisibleTable, setIsVisibleTable] = useState(false);
	const { answersCount, answersChartData } = getAnswers(props.question);

	const pollAnswers = usePollAnswers();
	const questionId = pollAnswers.data.questions.find((question) => question.type === "FREE_CHOICE").id;
	const answerOptionId = pollAnswers.data.questions.find((q) => q.type === "FREE_CHOICE").answerOptions[0].id;
	const [, pollId] = usePollIdFromURL();
	const paginationData = useTablePaginationLogic();

	const answersQuery = useQuery({
		queryFn: () =>
			getAnswersByOptionApi({
				path: {
					pollId,
					questionId,
					answerOptionId,
				},
				params: {
					page: paginationData.clientsCurrentPage - 1,
					size: paginationData.clientsSize,
				},
			}),
		queryKey: [QueriesKeys.pollAnswers, pollId, questionId, answerOptionId, paginationData.clientsSize, paginationData.clientsCurrentPage],
		keepPreviousData: true,
	});

	const toggleVisible = () => {
		setIsVisibleTable((prev) => {
			if (!prev) {
				answersQuery.refetch();
				paginationData.setClientsTotal(answersQuery.data?.totalPages);
			}
			return !prev;
		});
	};

	useEffect(() => {
		if (answersQuery.data?.totalPages) {
			paginationData.setClientsTotal(answersQuery.data.totalPages);
		}
	}, [answersQuery.data?.totalPages, paginationData]);

	return (
		<>
			<ChartDescription title={PollsEditStore.poll?.questions[2]?.value} description={`${answersCount} ${getAnswerText(answersCount)}`} />

			{answersCount === 0 && <ChartEmpty />}

			{answersCount !== 0 && <List onClickItem={toggleVisible} items={answersChartData.map((item) => ({ title: item.name }))} />}

			{isVisibleTable && <AnswersTable answersData={answersQuery.data} paginationData={paginationData} />}
		</>
	);
};

export default FreeAnswer;
