import type { IBuilding, IBuildingObject, IClientBuildingObjectsIds, IComplex, IEntrance, IFloor } from "interfaces/IBuilding";
import type { IComments } from "interfaces/IClient";
import type { IRequestClientWithRole } from "interfaces/IRequest";
import type { IClient, IRole } from "interfaces/IRole";
import { isMockEnabled } from "mocks/mocks.constants";
import * as realEstateMock from "mocks/mocks.realEstate";
import mocksRoles from "mocks/mocks.roles";
import { joinArrParams } from "utils/joinParams";

import apiClient from "./api.client";

export type TGetComplexApi = (args?: { params: { complexIds: string[] } }) => Promise<IComplex[]>;

export const getComplexApi: TGetComplexApi = async (args) => {
	try {
		if (isMockEnabled) {
			return realEstateMock.complexes;
		}

		const res = await apiClient.get("/real-estate/v1/complexes", {
			params: {
				complexIds: joinArrParams(args?.params?.complexIds),
			},
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetBuildingsApi = (args?: { params: { complexIds: string[]; buildingIds?: string[] } }) => Promise<IBuilding[]>;

export const getBuildingsApi: TGetBuildingsApi = async (args) => {
	try {
		if (isMockEnabled) {
			return realEstateMock.buildings;
		}

		const res = await apiClient.get("/real-estate/v1/buildings", {
			params: {
				complexIds: joinArrParams(args?.params?.complexIds),
				buildingIds: joinArrParams(args?.params?.buildingIds),
			},
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetEntranceApi = (args?: { params: { entranceIds?: string[]; buildingIds: string[] } }) => Promise<IEntrance[]>;

export const getEntranceApi: TGetEntranceApi = async (args) => {
	try {
		if (isMockEnabled) {
			return realEstateMock.entrances;
		}

		const res = await apiClient.get("/real-estate/v1/entrances", {
			params: {
				buildingIds: joinArrParams(args?.params?.buildingIds),
			},
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetFloorsApi = (args?: { params: { entranceIds: string[]; floorIds?: string[] } }) => Promise<IFloor[]>;

export const getFloorsApi: TGetFloorsApi = async (args) => {
	try {
		if (isMockEnabled) {
			return realEstateMock.floors;
		}

		const res = await apiClient.get("/real-estate/v1/floors", {
			params: {
				entranceIds: joinArrParams(args?.params?.entranceIds),
			},
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetRealEstateCommentsApi = (args: { params: { buildingObjectId: string } }) => Promise<IComments[]>;

export const getRealEstateCommentsApi: TGetRealEstateCommentsApi = async (args) => {
	try {
		const res = await apiClient.get("/real-estate/v1/comments", {
			params: args.params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TCreateCommentApi = (args: { body: { comment: string; buildingObjectId: string } }) => Promise<IComments>;

export const createRealEstateCommentApi: TCreateCommentApi = async (args) => {
	try {
		const res = await apiClient.post("/real-estate/v1/comments", args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TUpdateCommentByIdApi = (args: { path: { id: string }; body: { comment: string } }) => Promise<IComments>;

export const updateRealEstateCommentApi: TUpdateCommentByIdApi = async (args) => {
	try {
		const res = await apiClient.patch(`/real-estate/v1/comments/${args.path.id}`, args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TDeleteCommentByIdApi = (args: { path: { id: string } }) => Promise<IComments>;

export const deleteRealEstateCommentApi: TDeleteCommentByIdApi = async (args) => {
	try {
		const res = await apiClient.delete(`/real-estate/v1/comments/${args.path.id}`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetBuildingObjectsApi = (args?: {
	params: { floorIds?: string[]; buildingObjectIds?: string[] };
}) => Promise<IBuildingObject[]>;

export const getBuildingObjectsApi: TGetBuildingObjectsApi = async (args) => {
	try {
		const params: Record<string, string> = {};

		if (args?.params?.buildingObjectIds) {
			params.buildingObjectIds = joinArrParams(args?.params?.buildingObjectIds);
		}

		if (args?.params?.floorIds) {
			params.floorIds = joinArrParams(args?.params?.floorIds);
		}

		const res = await apiClient.get("/real-estate/v1/building-objects", {
			params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetRolesApi = () => Promise<IRole[]>;

export const getClientByObjectIdApi: TGetClientByObjectIdApi = async ({ buildingObjectId }) => {
	try {
		const res = await apiClient.get(`/real-estate/v1/building-objects/${buildingObjectId}/clients`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TAddClientToBuildingObjectApi = (args: {
	body: {
		buildingObjectId: string;
		roleIds: string[];
		action: "ADD";
	}[];
	path: { clientId: string };
}) => Promise<void>;

export const addClientToBuildingObjectApi: TAddClientToBuildingObjectApi = async (args) => {
	try {
		const res = await apiClient.post(`/real-estate/v1/building-objects/clients/${args.path.clientId}/roles`, args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TDeleteBuildingObjectClientApi = (args: {
	path: {
		buildingObjectId: string;
		clientId: string;
	};
}) => Promise<any>;

export const deleteBuildingObjectClientApi: TDeleteBuildingObjectClientApi = async (args) => {
	try {
		const res = await apiClient.delete(`/real-estate/v1/building-objects/${args.path.buildingObjectId}/clients/${args.path.clientId}`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetClientByObjectIdApi = ({ buildingObjectId }: { buildingObjectId: string }) => Promise<IClient[]>;

export const getRolesApi: TGetRolesApi = async () => {
	try {
		if (isMockEnabled) {
			return mocksRoles;
		}

		const res = await apiClient.get("/real-estate/v1/roles");

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetBuildingObjectIdsByUserIdApi = (args: { path: { clientId: string } }) => Promise<IClientBuildingObjectsIds[]>;

export const getBuildingObjectIdsByUserIdApi: TGetBuildingObjectIdsByUserIdApi = async (args) => {
	try {
		const res = await apiClient.get(`/real-estate/v1/building-objects/clients/${args.path.clientId}`);
		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getClientsByBuildingObjectAndRoleApi = async (args: {
	buildingObjectIds: string;
	clientIds?: string[] | string;
	roleIds: string;
}) => {
	try {
		const res = await apiClient.get("/real-estate/v1/building-objects/clients", {
			params: {
				buildingObjectIds: args.buildingObjectIds,
				roleIds: args.roleIds,
				clientIds: args.clientIds,
			},
		});
		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetClientsByBuildingObjectId = ({
	buildingObjectId,
	roleId,
}: {
	buildingObjectId: string;
	roleId: string;
}) => Promise<IRequestClientWithRole[]>;

export const getClientsByBuildingObjectId: TGetClientsByBuildingObjectId = async (args: { buildingObjectId: string; roleId: string }) => {
	try {
		const res = await apiClient.get("/api-gateway/v1/requests/building-object-clients", {
			params: {
				buildingObjectId: args.buildingObjectId,
				roleId: args.roleId,
			},
		});
		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TUpdateBuildingObjectApi = (args: {
	body: Pick<IBuildingObject, "totalArea" | "livingArea" | "terraceArea" | "flatType">;
	path: { id: string };
}) => Promise<any>;

export const updateBuildingObjectApi: TUpdateBuildingObjectApi = async (args) => {
	try {
		const res = await apiClient.patch(`/real-estate/v1/building-objects/${args.path.id}`, args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TDeleteClientRolesFromRealEstateApi = (args: {
	path: { clientId: string };
	data: {
		buildingObjectId: string;
		roleIds: string[];
		action: "REMOVE";
	}[];
}) => Promise<any>;

export const deleteClientRolesFromRealEstateApi: TDeleteClientRolesFromRealEstateApi = async (args) => {
	try {
		const res = await apiClient.delete(`/real-estate/v1/building-objects/clients/${args.path.clientId}/roles`, {
			data: args.data,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
