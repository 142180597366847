import { FC, useCallback, useEffect, useState } from "react";

import { Menu as AMenu } from "antd";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Avatar, Text } from "tap2visit-ui-kit";

import { isVisibleFunctionality } from "constants/constants.common";
import { HOME_PAGE } from "constants/constants.paths";
import AnnouncementAreYouSureDialogStore from "pages/announcemets/store/Announcement.areYouSureDialog.store";
import useAvatarLetters from "pages/profileUK/hooks/useAvatarLetters";
import useLogoUK from "pages/profileUK/hooks/useLogoUK";
import profileUKVisibilityStore from "pages/profileUK/profileUKVisibility.store";
import DialogAreYouSureCancelEditOfficeStore from "pages/profileUK/store/DialogAreYouSureCancelEditOffice.store";
import { ReactComponent as Back } from "public/arrow_left_block.svg";
import { ReactComponent as Up } from "public/arrow_right_block.svg";
import { ReactComponent as Help } from "public/help.svg";
import { ReactComponent as ManyDots } from "public/menu/many_dots.svg";

import { TemplateBasicOrganization } from "../../pages/profileUK/components/TemplateBasicOrganization";
import { showModalProfileEditExistIfThereChanges } from "../../pages/profileUK/utils/showModalProfileEditExistIfThereChanges";
import UKStore from "../../store/UK.Store";

import MenuProfile from "./components/Menu.profile";
import { type TMenuItem, useMenuItems } from "./hooks/useMenuItems";
import { RowItem } from "./styles";

interface IMenu {
	setSideBarWidth: React.Dispatch<React.SetStateAction<string>>;
}

const Menu: FC<IMenu> = (props) => {
	const navigate = useNavigate();
	const location = useLocation().pathname;
	const { logoUKLink } = useLogoUK();
	const avatarLetters = useAvatarLetters();
	const { visibleMenuItems } = useMenuItems();

	const [open, setOpen] = useState(false);
	const [collapsed, setCollapsed] = useState(false);

	const currentKey = location.split("/")[1];

	useEffect(() => {
		props.setSideBarWidth(collapsed ? "64px" : "255px");
	}, [collapsed]);

	const clickMenuItem = useCallback(
		(item: TMenuItem) => {
			const navigateToScreen = () => {
				navigate(`${item.key}`);
			};

			if (profileUKVisibilityStore.isEditModeEnabled) {
				showModalProfileEditExistIfThereChanges({
					onContinue: () => {
						navigateToScreen();
						profileUKVisibilityStore.setIsEditModeEnabled(false);
					},
				});
				return;
			}

			if (location === "/announcement/create" || location.indexOf("/announcement/edit") !== -1) {
				AnnouncementAreYouSureDialogStore.setVisibleDialogWithCallbackAfterExiting(navigateToScreen);
				return;
			}

			navigateToScreen();
		},
		[location, navigate],
	);

	const setOpenChoiceAnotherUK = () => {
		const openChoiceUK = () => setOpen(true);

		if (profileUKVisibilityStore.isEditModeEnabled) {
			DialogAreYouSureCancelEditOfficeStore.setVisibleDialogWithCallbackAfterExiting(() => setOpen(true));
		}

		if (location === "/announcement/create" || location.indexOf("/announcement/edit") !== -1) {
			AnnouncementAreYouSureDialogStore.setVisibleDialogWithCallbackAfterExiting(openChoiceUK);
			return;
		}

		openChoiceUK();
	};

	return (
		<>
			{open && <TemplateBasicOrganization setOpen={setOpen} />}
			<AMenu selectedKeys={[currentKey]} mode="inline" inlineCollapsed={collapsed}>
				<RowItem key={"LOGO"} onClick={() => navigate(HOME_PAGE)} collapsed={collapsed}>
					<Avatar size={"32"} img={logoUKLink} firstName={avatarLetters.secondLetter} lastName={avatarLetters.firstLetter} />
					<Text type={"base-medium"}>{(UKStore.getUK().name as unknown as string) || ""}</Text>
				</RowItem>
				<ChangeUK onClick={setOpenChoiceAnotherUK}>
					<RowItem key={"UK_select"} collapsed={collapsed}>
						<ManyDots />
						<Text type="base-medium">Выбрать другую УК</Text>
					</RowItem>
				</ChangeUK>
				{visibleMenuItems.map((item) => (
					<AMenu.Item key={item.key} onClick={() => clickMenuItem(item)}>
						{item.icon}
						<Text type="base-medium">{item.label}</Text>
					</AMenu.Item>
				))}
				<BottomContainer>
					{isVisibleFunctionality && (
						<RowItem key={"subBottom1"} collapsed={collapsed}>
							<Help />
							<Text type={"base-medium"} color={"#4364A6"}>
								Нужна помощь
							</Text>
						</RowItem>
					)}

					<MenuProfile collapsed={collapsed} />
					<RowItem
						key={"subBottom3"}
						collapsed={collapsed}
						onClick={() => setCollapsed((init) => !init)}
						style={{ justifyContent: "space-between" }}>
						{collapsed ? <Up /> : <Back />}
						<Text type={"small-regular"} color={"#12121299"}>
							Версия 0.1
						</Text>
					</RowItem>
				</BottomContainer>
			</AMenu>
		</>
	);
};

export default observer(Menu);

const ChangeUK = styled.div`
	border-bottom: 0.5px solid #12121229;
	padding-bottom: 8px;
`;

const BottomContainer = styled.div`
	position: absolute;
	bottom: 0;
	background: white;
`;
