import apiClient from "./api.client";

export const getCommentsAppels = async ({ id }: { id: string }) => {
	try {
		const res = await apiClient.get(`appeals/v1/comments?appealId=${id}`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const postCommentsAppels = async ({ comment, id }: { comment: string; id: string }) => {
	try {
		const res = await apiClient.post("appeals/v1/comments", {
			comment,
			appealId: id,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteCommentsAppels = async ({ id }: { id: string }) => {
	try {
		const res = await apiClient.delete(`appeals/v1/comments/${id}`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const changeCommentsAppels = async ({ comment, id }: { comment: string; id: string }) => {
	try {
		const res = await apiClient.patch(`appeals/v1/comments/${id}`, {
			comment,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
