import { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { BoldEdit, OutlineArrowRight2 } from "t2v-icons";
import { Space, Button, Text, colors } from "tap2visit-ui-kit";

import { getUserApi } from "api/api.employee";
import { changeAppealApi } from "api/api.gateway";
import { getBuildingObjectsApi } from "api/api.realEstate";
import CitizenBuildingObjectStore from "components/Citizens/store/CitizenBuildingObject.store";
import BuildingFilterStore from "components/TableFilter/BuildingFilter.store";
import TableFilterValidation from "components/TableFilter/store/TableFilterValidation";
import TableFilter from "components/TableFilter/TableFilter";
import { TableFilterContext } from "components/TableFilter/TableFilter.context";
import { Permissions } from "constants/constants.roles";
import type { IBuildingObject } from "interfaces/IBuilding";
import { QueriesKeys } from "interfaces/queriesKeys";
import DrawerStore from "pages/appeals/store/Drawer.store";
import CitizenRealtyDrawer from "pages/citizens/components/CitizenRealtyDrawer";
import { getBuildingObjectType } from "utils/getBuildingObjectType";

import { PersonalEditContent, PersonalEditFooter, PersonalEditWrapper } from "./AppealDrawer.styled";

const TableFilterInstanceStore = new BuildingFilterStore();

const AppealAddress = () => {
	const {
		data: citizenBuildingObjects,
		refetch,
		isFetching: isFetching,
	} = useQuery<IBuildingObject[]>({
		queryFn: () => {
			if ([DrawerStore.selectedAppeals.buildingObjectAddress.buildingObjectId].length) {
				return getBuildingObjectsApi({
					params: { buildingObjectIds: [DrawerStore.selectedAppeals.buildingObjectAddress.buildingObjectId] },
				});
			}
			return [];
		},
		queryKey: [QueriesKeys.citizenBuildingObjects],
	});

	const currentApartment = citizenBuildingObjects ? citizenBuildingObjects[0] : undefined;

	const [isEdit, setIsEdit] = useState(false);
	const { data: user } = useQuery({
		queryFn: () => getUserApi(),
	});

	const changeAppeal = useMutation(changeAppealApi);

	const closeEdit = () => {
		setIsEdit(false);
		TableFilterInstanceStore.clearFilter();
		TableFilterValidation.resetValidation();
	};

	const changeAppealFunc = async () => {
		const isValidAddress = TableFilterValidation.checkValidation({
			selectComplexes: TableFilterInstanceStore.selectedComplexIds[0],
			selectBuildings: TableFilterInstanceStore.selectedBuildingsIds[0],
			selectEntrances: TableFilterInstanceStore.selectedEntrancesIds[0],
			selectBuildingObjects: TableFilterInstanceStore.selectedBuildingObjectIds[0],
			selectFloors: TableFilterInstanceStore.selectedFloorsIds[0],
		});

		if (!isValidAddress) return;

		changeAppeal
			.mutateAsync({
				args: {
					buildingObjectId: TableFilterInstanceStore.selectedBuildingObjectIds[0],
				},
				id: DrawerStore.selectedAppeals.id,
			})
			.then((res) => {
				closeEdit();
				DrawerStore.setSelectedAppeals(res);
			});
	};

	const isAdmin = user.additionalProperties?.role === Permissions.ADMIN;
	const isNotCompletedOrArchived = DrawerStore.selectedAppeals.status !== "COMPLETED" && DrawerStore.selectedAppeals.status !== "ARCHIVED";
	const canEdit = !isEdit;

	useEffect(() => {
		if (DrawerStore.selectedAppeals.buildingObjectAddress?.buildingObjectId) {
			refetch();
		}
	}, [DrawerStore.selectedAppeals.buildingObjectAddress?.buildingObjectId]);

	if (isFetching) return null;

	return (
		<TableFilterContext.Provider value={TableFilterInstanceStore}>
			<CitizenRealtyDrawer />
			<Space width="100%" direction="column" align="start">
				<Space width="100%" justify="space-between">
					<Text h="h5" color={colors.textNeutralPrimary}>
						Адрес
					</Text>
					{isAdmin && isNotCompletedOrArchived && canEdit && (
						<Button onClick={() => setIsEdit(true)} typeButton="secondary" icon={BoldEdit} shape="square" />
					)}
				</Space>
				{isEdit ? (
					<PersonalEditWrapper background={colors.surfaceNeutralBg2}>
						<PersonalEditContent>
							<TableFilter
								customSwitchOptions={{
									size: "l",
									isFullWidth: true,
									isVisibleSwitch: false,
									isNotShowButton: true,
									isRequired: true,
								}}
							/>
						</PersonalEditContent>

						<PersonalEditFooter>
							<Button typeButton="secondary" onClick={closeEdit}>
								Отменить
							</Button>
							<Button onClick={changeAppealFunc}>Сохранить</Button>
						</PersonalEditFooter>
					</PersonalEditWrapper>
				) : (
					<AddressWrapper
						onClick={() =>
							CitizenBuildingObjectStore.openDrawer(
								citizenBuildingObjects[0],
								DrawerStore.selectedAppeals.buildingObjectAddress?.addressValue,
							)
						}>
						<div>
							<Text type="table-cell-l-medium">{getBuildingObjectType(currentApartment?.objectType)}</Text>
							<Text style={{ width: "280px" }} type="table-cell-m-regular" color={colors.textNeutralSecondary}>
								{DrawerStore.selectedAppeals.buildingObjectAddress?.addressValue}
							</Text>
						</div>
						<OutlineArrowRight2 fill={colors.iconNeutralDefault} />
					</AddressWrapper>
				)}
			</Space>
		</TableFilterContext.Provider>
	);
};

const AddressWrapper = styled.div`
	width: 100%;
	background: white;
	padding: 8px 12px;
	border-radius: 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
`;
export default observer(AppealAddress);
