export default (buildingType: string) => {
	const declensions = {
		Цоколь: "цоколе",
		Квартира: "квартире",
		"Коммерческая недвижимость": "коммерческой недвижимости",
		Кладовка: "кладовке",
		Парковка: "парковке",
	};

	return declensions[buildingType] || buildingType;
};
