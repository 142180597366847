import { Space } from "antd";
import { observer } from "mobx-react-lite";
import { colors, Drawer } from "tap2visit-ui-kit";

import { getBuildingObjectType } from "utils/getBuildingObjectType";

import CitizenBuildingObjectStore from "../../../components/Citizens/store/CitizenBuildingObject.store";

import CitizenCommentsCollapse from "./CitizenComments.collapse";
import CitizenHeaderInformation from "./CitizenHeaderInformation";
import CitizenOwnersInformation from "./CitizenOwnersInformation";
import CitizenRealtyInformation from "./CitizenRealtyInformation";

interface ICitizenRealtyDrawer {
	open?: boolean;
	setOpen?: (v: boolean) => void;
}
const CitizenRealtyDrawer = ({ open, setOpen }: ICitizenRealtyDrawer) => {
	const buildingType = getBuildingObjectType(CitizenBuildingObjectStore.selectedBuilding?.objectType);
	const isLivingSpace = CitizenBuildingObjectStore.selectedBuilding?.isLivingSpace;

	/* TEMPORARY HIDDEN */
	// const [openCitizenConfidantModal, setOpenCitizenConfidantModal] = useState(false);
	// const onClickAddTrustedPerson = () => {
	// 	setOpenCitizenConfidantModal(true);
	// 	CitizenBuildingObjectStore.setPreselectedRole("TRUSTED_PERSON");
	// };

	// const onClickAddTenant = () => {
	// 	setOpenCitizenConfidantModal(true);
	// 	CitizenBuildingObjectStore.setPreselectedRole("TENANT");
	// };

	return (
		<Drawer
			backgroundBody={colors.surfaceNeutralBg4}
			title={buildingType}
			showOk={false}
			showCancel={false}
			width="700px"
			onClose={CitizenBuildingObjectStore.closeDrawer}
			visible={CitizenBuildingObjectStore.isOpenBuildingDrawer}>
			{/* TEMPORARY HIDDEN */}
			{/* <CitizenConfidantModal open={true} setOpen={setOpenCitizenConfidantModal} /> */}
			<Space size={30} direction="vertical">
				<CitizenHeaderInformation />

				<CitizenCommentsCollapse type="real-estate" />

				{isLivingSpace ? (
					<>
						<CitizenOwnersInformation />
						<CitizenRealtyInformation />
					</>
				) : (
					<>
						<CitizenRealtyInformation />
						<CitizenOwnersInformation />
					</>
				)}

				{/* TEMPORARY HIDDEN */}
				{/* <CitizenDocumentOwners /> */}

				{/* <CitizenBuildingObjectTruestedClientsInfo
					title="Доверенные лица собственников"
					roleType="TRUSTED_PERSON"
					setOpenDrawerAddTrustedPerson={onClickAddTrustedPerson}
					buttonText="Добавить доверенное лицо"
				/>

				<CitizenBuildingObjectTruestedClientsInfo
					title="Арендаторы"
					roleType="TENANT"
					setOpenDrawerAddTrustedPerson={onClickAddTenant}
					buttonText="Добавить арендатора"
				/> */}
			</Space>
		</Drawer>
	);
};

export default observer(CitizenRealtyDrawer);
