import type { IBuilding, IBuildingsGetParams, IComplex, IUpdatedDataTable, IUpdatedDataTableParams } from "interfaces/IArcusIntegration";
import apiClient from "./api.client";
import dayjs from "dayjs";

type TGetComplexesApi = () => Promise<IComplex[]>;

type TGetBuildingsApi = (args: { params: IBuildingsGetParams }) => Promise<IBuilding[]>;

export type TGetUpdatedDataTableApi = (args: { params: IUpdatedDataTableParams }) => Promise<IUpdatedDataTable>;

const getComplexesApi: TGetComplexesApi = async () => {
	try {
		const res = await apiClient.get("arcus-integration/v1/complexes", {
			headers: {
				"Content-Type": "application/json",
			},
		});
		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

const getBuildingsApi: TGetBuildingsApi = async (args) => {
	try {
		const res = await apiClient.get("arcus-integration/v1/buildings", {
			headers: {
				"Content-Type": "application/json",
			},
			params: args.params,
		});
		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getUpdatedDataTableApi: TGetUpdatedDataTableApi = async (args) => {
	try {
		const { createdBySearch, statuses, types, createDateTimeStart, createDateTimeEnd, page, size, sort, ...otherParams } = args.params;

		let path = "arcus-integration/v1/import-tasks/page";

		const params: Record<string, any> = {
			page: page || 0,
			size: size || 20,
			...otherParams,
		};

		if (statuses?.length) {
			params.statuses = statuses.join(",");
		}

		if (createdBySearch) {
			params.createdBySearch = createdBySearch;
		}

		if (types?.length) {
			params.types = types.join(",");
		}

		if (createDateTimeStart) {
			params.createDateTimeStart = createDateTimeStart;
		}
		if (createDateTimeEnd) {
			params.createDateTimeEnd = createDateTimeEnd;
		}

		if (sort?.length) {
			let sortParams = "?";
			sort.forEach((s, index) => {
				if (index !== 0) {
					sortParams += "&";
				}
				sortParams += `sort=${s.key},${s.direction}`;
			});
			path += sortParams;
		}

		const res = await apiClient.get(path, {
			params,
			headers: {
				"Content-Type": "application/json",
			},
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export { getComplexesApi, getBuildingsApi };
