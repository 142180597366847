import { emailRegExp } from "constants/constants.regexp";
import ValidationStore, { ValidationStatuses } from "modules/validation/Validation.store";

export type TAppealsCreateValidationStoreFields = {
	firstName: keyof typeof ValidationStatuses;
	lastName: keyof typeof ValidationStatuses;
	phone: keyof typeof ValidationStatuses;
	email: keyof typeof ValidationStatuses;
	login: keyof typeof ValidationStatuses;
};

const initialValidationData: TAppealsCreateValidationStoreFields = {
	firstName: ValidationStatuses.VALID,
	lastName: ValidationStatuses.VALID,
	phone: ValidationStatuses.VALID,
	email: ValidationStatuses.VALID,
	login: ValidationStatuses.VALID,
};

const COMMON_REQUIRED_ERROR_MESSAGE = "Обязательно к заполнению";

export const ERROR_MESSAGES: Record<keyof TAppealsCreateValidationStoreFields, Partial<Record<keyof typeof ValidationStatuses, string>>> = {
	firstName: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE, LESS_THEN: "Поле не должно содержать более 150 символов" },
	lastName: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE, LESS_THEN: "Поле не должно содержать более 150 символов" },
	phone: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE, EQUALS: "Номер некорректен" },
	email: {
		REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE,
		MATCH: "Эл. почта некорректна",
		LESS_THEN: "Поле не должно содержать более 255 символов",
	},
	login: { EQUALS: "Логин некорректен" },
};

const checkRules = {
	lastName: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.LESS_THEN]: 150,
	},
	firstName: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.LESS_THEN]: 150,
	},
	phone: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.EQUALS]: 11,
	},
	email: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.MATCH]: emailRegExp,
		[ValidationStatuses.LESS_THEN]: 255,
	},
	login: {
		[ValidationStatuses.EQUALS]: 11,
	},
};

const AppealsCreateValidationStore = new ValidationStore<TAppealsCreateValidationStoreFields>(
	initialValidationData,
	ERROR_MESSAGES,
	checkRules,
);

export default AppealsCreateValidationStore;
