import React, { FC, useState } from "react";

import { observer } from "mobx-react-lite";

import { Spacer } from "components/layout/Spacer";
import BaseModal from "components/Modals/BaseModal";
import RealEstateTreeStore from "hooks/useRealEstateTreeData/store/RealEstateTree.store";
import useSavePoll from "pages/polls/hooks/useSavePoll";
import PollsItemStore from "pages/polls/store/PollsItem.store";

import PollsEditSendModalStore from "../../store/PollsSendModal.store";
import PollsPeriodOfTime from "../PollsPeriodOfTime";
import PollsRoles from "../PollsRoles";
import PollsTreeBuildings from "../PollsTreeBuildings";

import PollSendModalContent from "./components/PollsEditContent.sendUsing";

const PollsSendModal = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const savePoll = useSavePoll();
	const isTemplate = PollsItemStore.poll.isTemplate;

	React.useEffect(() => () => RealEstateTreeStore.dispose(), []);

	const handleOk = async () => {
		if (isSubmitting) return;

		try {
			setIsSubmitting(true);
			await savePoll();
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<BaseModal
			isVisible={PollsEditSendModalStore.isVisibleModal}
			onCancel={() => PollsEditSendModalStore.changeStatusModal(false)}
			onExit={() => PollsEditSendModalStore.changeStatusModal(false)}
			onOk={handleOk}
			title={isTemplate ? "Параметры отправки" : "Отправка активности"}
			contentContainerStyle={{ maxHeight: "80vh", overflow: "scroll" }}
			okText={isTemplate ? "Сохранить" : "Отправить"}
			cancelText="Отмена">
			<>
				<PollsPeriodOfTime />

				<Spacer px={16} />

				<PollSendModalContent />

				<Spacer px={16} />

				<PollsRoles editable={true} />

				<Spacer px={16} />

				<PollsTreeBuildings editable={true} />
			</>
		</BaseModal>
	);
};

export default observer(PollsSendModal);
