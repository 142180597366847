import React from "react";

import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import styled from "styled-components";
import { OutlinePlus4 } from "t2v-icons";
import { Space, colors, Text, Button, Loader } from "tap2visit-ui-kit";

import { getAppealsListByIds } from "api/api.gateway";
import { QueriesKeys } from "interfaces/queriesKeys";
import DrawerStore from "pages/appeals/store/Drawer.store";

import AppealConnectionItem from "./AppealConnectionItem";
import AppealConnectionModal from "./AppealConnectionModal";

const AppealConnection = () => {
	const isShowText = DrawerStore.selectedAppeals.status !== "COMPLETED" && DrawerStore.selectedAppeals.status !== "ARCHIVED";
	const isShowCompletedText = isShowText || (DrawerStore.selectedAppeals.childAppealIds?.length > 0 && !isShowText);

	const isHaveParentAppeal = DrawerStore.selectedAppeals.parentAppealId;
	const showButtonAdd = !isHaveParentAppeal && isShowText;

	const {
		data: childAppeals,
		isLoading,
		isFetching,
	} = useQuery({
		queryFn: () => getAppealsListByIds(isHaveParentAppeal ?? DrawerStore.selectedAppeals.childAppealIds.join(",")),
		queryKey: [QueriesKeys.appealsListByIds, DrawerStore.selectedAppeals.childAppealIds, isHaveParentAppeal],
		enabled: isHaveParentAppeal ? !!isHaveParentAppeal : !!DrawerStore.selectedAppeals.childAppealIds?.length,
	});

	if (isLoading || isFetching) return <Loader />;
	return (
		<Space width="100%" direction="column" align="start">
			<AppealConnectionModal />
			<Text h="h5" color={colors.textNeutralPrimary}>
				{isHaveParentAppeal ? "Обращение связано" : isShowCompletedText && "Связанные обращения"}
			</Text>

			<AppealConnectionWrapper>
				{childAppeals?.map((childAppeal) => <AppealConnectionItem childAppeals={childAppeals} value={childAppeal} />)}
				{showButtonAdd && (
					<Button
						onClick={() => {
							DrawerStore.setIsOpenConnectionAppeal(true);
						}}
						typeButton="default"
						size="large"
						icon={OutlinePlus4}>
						Добавить связь
					</Button>
				)}
			</AppealConnectionWrapper>
		</Space>
	);
};

const AppealConnectionWrapper = styled.div`
	background: white;
	border-radius: 15px;
	width: 100%;
`;

export default observer(AppealConnection);
