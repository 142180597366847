import React from "react";

import dayjs from "dayjs";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { colors, Text, TTableColumn } from "tap2visit-ui-kit";

import { getPollsPageApi } from "api/api.gateway";
import { archivePollApi, deletePollApi, getVotesTempatesPageApi } from "api/api.votes";
import { useTableFilterContext } from "components/TableFilter/TableFilter.context";
import useTablePaginationLogic from "hooks/useTablePaginationLogic";
import { TSortingDirection } from "interfaces/ISorting";
import { TVoteStatuses, TVoteType } from "interfaces/IVote";
import { QueriesKeys } from "interfaces/queriesKeys";
import PollActions from "pages/polls/Polls/PollActions";
import PollsConfirmStore from "pages/polls/Polls/store/Polls.confirm.store";

import PollsTabsStore from "../../../store/Polls.tabs.store";

import getPollText from "./utils/getPollText";

const useTableData = () => {
	const paginationData = useTablePaginationLogic();

	const navigate = useNavigate();
	const tableFilterStore = useTableFilterContext();
	const [selectedTypes, setSelectedTypes] = React.useState<TVoteType[]>([]);
	const [sortState, setSortState] = React.useState<{ [key: string]: TSortingDirection }>({});

	const [creatorNameSearch, setCreatorNameSearch] = React.useState("");
	const [nameSearch, setNameSearch] = React.useState("");

	const [templateNameSearch, setTemplateNameSearch] = React.useState("");
	const [selectedTemplateTypes, setSelectedTemplateTypes] = React.useState<TVoteType[]>([]);

	const handleSortChange = (key: string, direction: TSortingDirection | undefined) => {
		setSortState((prevState) => {
			const newState = { ...prevState };
			if (direction) {
				newState[key] = direction;
			} else {
				delete newState[key];
			}
			return newState;
		});
	};

	const pollsTemplates = useQuery({
		queryFn: () => {
			const types = selectedTemplateTypes.length > 0 ? selectedTemplateTypes : (["POLL", "VOTE"] as TVoteType[]);
			const sort = Object.entries(sortState).map(([key, direction]) => ({ key, direction }));

			return getVotesTempatesPageApi({
				params: {
					page: paginationData.clientsCurrentPage - 1,
					size: paginationData.clientsSize,

					types: types,
					textSearch: templateNameSearch,

					sort,
				},
			});
		},
		queryKey: [
			QueriesKeys.pollTemplates,
			templateNameSearch,
			selectedTemplateTypes,

			paginationData.clientsSize,
			paginationData.clientsCurrentPage,
		],
		enabled: PollsTabsStore.activeTab === "template",
	});

	const polls = useQuery({
		queryFn: () => {
			const statuses: TVoteStatuses[] = [];
			const types = selectedTypes.length > 0 ? selectedTypes : (["POLL", "VOTE"] as TVoteType[]);
			const sort = Object.entries(sortState).map(([key, direction]) => ({ key, direction }));

			if (PollsTabsStore.activeTab === "active") {
				if (PollsTabsStore.onlyActiveStatus) {
					statuses.push("ACTIVE");
				} else {
					statuses.push("NEW", "ACTIVE");
				}
			} else if (PollsTabsStore.activeTab === "archive") {
				statuses.push("ARCHIVE");
			}

			return getPollsPageApi({
				params: {
					page: paginationData.clientsCurrentPage - 1,
					size: paginationData.clientsSize,
					types: types,
					statuses: statuses,
					sort,
					nameSearch: nameSearch,
					createdBySearch: creatorNameSearch,

					complexIds: tableFilterStore.selectedComplexIds,
					buildingIds: tableFilterStore.selectedBuildingsIds,
					entranceIds: tableFilterStore.selectedEntrancesIds,
					floorIds: tableFilterStore.selectedFloorsIds,
					buildingObjectIds: tableFilterStore.selectedBuildingObjectIds,
				},
			});
		},
		queryKey: [
			QueriesKeys.polls,
			sortState,
			PollsTabsStore.activeTab,
			paginationData.clientsSize,
			paginationData.clientsCurrentPage,
			tableFilterStore.selectedComplexIds,
			tableFilterStore.selectedBuildingsIds,
			tableFilterStore.selectedEntrancesIds,
			tableFilterStore.selectedComplexIds,
			tableFilterStore.selectedBuildingObjectIds,

			PollsTabsStore.onlyActiveStatus,
			selectedTypes,
			nameSearch,
			creatorNameSearch,
		],
		enabled: PollsTabsStore.activeTab !== "template",
	});

	React.useEffect(() => {
		paginationData.setClientsCurrentPage(1);
		paginationData.setClientsTotal(1);
		paginationData.setClientsSize(20);
	}, [PollsTabsStore.activeTab]);

	const activeOrArchiveColumns: TTableColumn[] = [
		{
			dataIndex: "type",
			title: "Тип",
			key: "type",
			filters: [
				{ text: "Опрос", key: "POLL" as TVoteType },
				{ text: "Голосование", key: "VOTE" as TVoteType },
			],
			onChangeFilter: (filters) => {
				const selectedTypes = filters.map((filter) => filter.key) as TVoteType[];
				setSelectedTypes(selectedTypes);
				paginationData.setClientsCurrentPage(1);
			},
			onSortedBy: (direction) => handleSortChange("type", direction),
			style: {
				minWidth: "120px",
			},
		},
		{
			dataIndex: "name",
			title: "Название",
			key: "name",
			onSearch: (name) => {
				setNameSearch(name);
				paginationData.setClientsCurrentPage(1);
			},
			onSortedBy: (direction) => handleSortChange("name", direction),
			columnCellStyles: {
				maxWidth: "400px",
			},
		},
		{
			dataIndex: "address",
			title: "Адресаты",
			key: "address",
			columnCellStyles: {
				maxWidth: "400px",
			},
		},
		{
			dataIndex: "dateOfCreation",
			title: "Дата создания",
			key: "dateOfCreation",
			onSortedBy: (direction) => handleSortChange("createDateTime", direction),
			style: {
				minWidth: "150px",
			},
		},
		{
			dataIndex: "startDateTime",
			title: "Начало",
			key: "startDateTime",
			onSortedBy: (direction) => handleSortChange("startDateTime", direction),
			style: {
				minWidth: "150px",
			},
		},
		{
			dataIndex: "endDateTime",
			title: "Окончание",
			key: "endDateTime",
			onSortedBy: (direction) => handleSortChange("endDateTime", direction),
			style: {
				minWidth: "150px",
			},
		},
		{
			dataIndex: "sender",
			title: "Отправитель",
			key: "sender",
			onSearch: (name) => {
				setCreatorNameSearch(name);
				paginationData.setClientsCurrentPage(1);
			},
			style: {
				minWidth: "200px",
			},
		},
		{
			dataIndex: "actions",
			title: "Действия",
			key: "actions",
			style: {
				minWidth: "150px",
			},
		},
	];

	const templateColumns = [
		{
			dataIndex: "type",
			title: "Тип",
			key: "type",
			filters: [
				{ text: "Опрос", key: "POLL" as TVoteType },
				{ text: "Голосование", key: "VOTE" as TVoteType },
			],
			onChangeFilter: (filters) => {
				const selectedTypes = filters.map((filter) => filter.key) as TVoteType[];
				setSelectedTemplateTypes(selectedTypes);
				paginationData.setClientsCurrentPage(1);
			},
			onSortedBy: (direction) => handleSortChange("type", direction),
		},
		{
			dataIndex: "name",
			title: "Название",
			key: "name",
			onSearch: (name: string) => {
				setTemplateNameSearch(name);
				paginationData.setClientsCurrentPage(1);
			},
			onSortedBy: (direction) => handleSortChange("name", direction),
			columnCellStyles: {
				maxWidth: "1000px",
			},
			style: {},
		},
		{
			dataIndex: "actions",
			title: "Действия",
			key: "actions",
			columnCellStyles: {
				width: "260px",
			},
		},
	];

	const archivePoll = useMutation(archivePollApi);
	const deletePoll = useMutation(deletePollApi);

	React.useEffect(() => {
		const totalPages = polls.data?.totalPages ?? pollsTemplates.data?.totalPages;
		paginationData.setClientsTotal(totalPages);
	}, [paginationData, polls.data, pollsTemplates.data, PollsTabsStore.activeTab]);

	const onArchivePoll = (poll: { id: string; name: string; type: string }) => {
		PollsConfirmStore.openConfirmArchiveModal({
			callback: async () => {
				await archivePoll.mutateAsync({ body: { pollId: poll.id } });
				await polls.refetch();
			},
			name: poll.name,
			type: poll.type,
		});
	};

	const onDeletePoll = (poll: { id: string; name: string }) => {
		PollsConfirmStore.openConfirmDeleteModal({
			callback: async () => {
				await deletePoll.mutateAsync({ body: { pollId: poll.id } });
				await polls.refetch();
			},
			name: poll.name,
		});
	};

	const pollsTableData =
		polls.data?.content?.map((poll) => ({
			type: getPollText(poll.type),
			name: poll.name,
			address: [
				poll.complexAddresses?.join("; "),
				poll.buildingAddresses?.join("; "),
				poll.entranceAddresses?.join("; "),
				poll.floorAddresses?.join("; "),
				poll.buildingObjectAddresses?.join("; "),
			]
				.filter(Boolean)
				.join("; "),
			dateOfCreation: dayjs(poll.createDateTime).format("DD.MM.YYYY HH:mm"),
			startDateTime: dayjs(poll.startDateTime).format("DD.MM.YYYY HH:mm"),
			endDateTime: dayjs(poll.endDateTime).format("DD.MM.YYYY HH:mm"),
			meta: { status: poll.status },

			isPlanned: poll.isPlanned ? "Да" : "Нет",
			sender: poll.fullName,
			actions: (
				<ButtonContainer>
					{
						<PollActions
							poll={poll}
							isArchiveTab={PollsTabsStore.activeTab === "archive"}
							onArchivePoll={onArchivePoll}
							onDeletePoll={onDeletePoll}
						/>
					}
				</ButtonContainer>
			),
			id: poll.id,
		})) ?? [];

	const pollsTemplateData =
		pollsTemplates.data?.content?.map((poll) => ({
			id: poll.id,
			type: getPollText(poll.type),
			name: poll.name,
			meta: { status: poll.status, isTemplate: true },
			actions: (
				<FlexContainer>
					<Text
						color={"#5E7BB5"}
						type="table-cell-m-regular"
						onClick={(e) => {
							e.stopPropagation();
							navigate(`/polls/create?from-template=${poll.id}`);
						}}>
						Создать из шаблона
					</Text>
					<CustomDivider />
					<Text
						color={"#5E7BB5"}
						type="table-cell-m-regular"
						onClick={(e) => {
							e.stopPropagation();
							navigate(`/polls/edit/${poll.id}`);
						}}>
						Редактировать
					</Text>
				</FlexContainer>
			),
		})) ?? [];

	const data = PollsTabsStore.activeTab === "template" ? pollsTemplateData : pollsTableData;
	const column = PollsTabsStore.activeTab === "template" ? templateColumns : activeOrArchiveColumns;
	const isFetching = polls.isFetching || pollsTemplates.isFetching;

	return { pollData: data, pollColumn: column, pagination: paginationData, isFetching };
};

export default useTableData;

const FlexContainer = styled.div`
	display: flex;
	gap: 12px;
	justify-content: space-between;
	width: 100%;
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 12px;
`;

const CustomDivider = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1px;
	height: 12px;
	margin-top: 3px;
	background-color: ${colors.strokeDivider};
	transform: rotate(180deg);
`;
