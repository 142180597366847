import React from "react";

import dayjs from "dayjs";
import { useQuery } from "react-query";
import styled from "styled-components";
import { Badge, showToast, type TTableColumn } from "tap2visit-ui-kit";

import { getUpdatedDataTableApi } from "api/api.arcus-integration";
import useTablePaginationLogic from "hooks/useTablePaginationLogic";
import type { TSortingDirection } from "interfaces/ISorting";
import { QueriesKeys } from "interfaces/queriesKeys";
import { IMPORT_TYPE, IMPORT_TYPE_FILTERS, STATUS } from "pages/updateData/constants/constants";
import UpdateDataTabsStore from "pages/updateData/store/UpdateData.tabs.store";
import { ReactComponent as UnCheckSvg } from "public/uncheckCircle.svg";

import TableStore from "../store/Table.store";

const useTableData = () => {
	const paginationData = useTablePaginationLogic();

	const [sortedBy, setSortedBy] = React.useState<{ [key: string]: TSortingDirection }>({
		createDateTime: "desc",
	});

	const [filteredTypes, setFilteredTypes] = React.useState<any[]>([]);
	const [searchEmployee, setSearchEmployee] = React.useState<string>("");

	const preSelectedTime = React.useRef<{ startCreationTime: Date | undefined; endCreationTime: Date | undefined }>({
		startCreationTime: undefined,
		endCreationTime: undefined,
	});

	const handleSortChange = (key: string, direction: TSortingDirection | undefined) => {
		setSortedBy((prevState) => {
			const newState = { ...prevState };
			if (direction) {
				newState[key] = direction;
			} else {
				delete newState[key];
			}
			return newState;
		});
	};

	const resetFilters = () => {
		setSortedBy({});
		setFilteredTypes([]);
		setSearchEmployee("");
		preSelectedTime.current = { startCreationTime: undefined, endCreationTime: undefined };
		TableStore.setStartCreationTime(undefined);
		TableStore.setEndCreationTime(undefined);

		paginationData.setClientsCurrentPage(1);
	};

	const counters = useQuery({
		queryFn: () => {
			const sort = Object.entries(sortedBy).map(([key, direction]) => ({ key, direction }));
			return getUpdatedDataTableApi({
				params: {
					page: paginationData.clientsCurrentPage - 1,
					size: paginationData.clientsSize,
					types: filteredTypes,
					createDateTimeStart: TableStore?.startCreationTime ? dayjs(TableStore?.startCreationTime).toISOString() : undefined,
					createDateTimeEnd: TableStore?.endCreationTime ? dayjs(TableStore?.endCreationTime).toISOString() : undefined,
					createdBySearch: searchEmployee,
					sort,
				},
			});
		},
		queryKey: [
			QueriesKeys.payments,
			paginationData.clientsSize,
			paginationData.clientsCurrentPage,
			sortedBy,
			filteredTypes,
			TableStore.startCreationTime,
			TableStore.endCreationTime,
			searchEmployee,
		],
	});

	const handleApplyFilters = () => {
		TableStore.setStartCreationTime(preSelectedTime.current.startCreationTime);
		TableStore.setEndCreationTime(preSelectedTime.current.endCreationTime);

		paginationData.setClientsCurrentPage(1);
	};

	const getCorrectWordForm = (number: number, words: [string, string, string]) => {
		const mod10 = number % 10;
		const mod100 = number % 100;

		if (mod100 >= 11 && mod100 <= 14) {
			return words[2];
		}
		if (mod10 === 1) {
			return words[0];
		}
		if (mod10 >= 2 && mod10 <= 4) {
			return words[1];
		}
		return words[2];
	};

	const getShortErrorMessage = (errorMessage: string): JSX.Element => {
		if (errorMessage.startsWith("ЛС не найдены")) {
			const accountNumbers = errorMessage.match(/\b\d{11}\b/g);
			const numberOfAccounts = accountNumbers ? accountNumbers.length : 0;

			const wordForm = getCorrectWordForm(numberOfAccounts, ["номер", "номера", "номеров"]);

			return <ShortErrorMessage>{`ЛС не найдены. (${numberOfAccounts} ${wordForm})`}</ShortErrorMessage>;
		}
		return <ShortErrorMessage>{errorMessage}</ShortErrorMessage>;
	};

	React.useEffect(() => {
		if (counters.data) {
			paginationData.setClientsTotal(counters.data.totalPages);
		}
	}, [counters.data?.totalPages]);
	const onRowErrorClick = () => {
		UpdateDataTabsStore.setActiveTab("download");
		UpdateDataTabsStore.setIsTransitionFromHistory();
	};

	const columns: TTableColumn[] = [
		{
			dataIndex: "importType",
			title: "Импорт",
			key: "importType",
			render: (importType: string[]) => {
				const importTypeNames = importType.map((type) => IMPORT_TYPE[type] || "Неизвестный тип");
				return <span>{importTypeNames.join(", ")}</span>;
			},
			filters: IMPORT_TYPE_FILTERS,
			onChangeFilter: (data) => {
				setFilteredTypes(data.map((r) => r.key));
				paginationData.setClientsCurrentPage(1);
			},
			columnCellStyles: {
				minWidth: "100px",
			},
		},
		{
			dataIndex: "status",
			title: "Статус",
			key: "status",
			render: ({ status, errorMessage }: { status: string; errorMessage?: string }) => {
				const statusData = STATUS[status] || { text: "Неизвестно", color: "grey" };
				if (statusData.text === "Ошибка") {
					return (
						<StatusWrapper onClick={onRowErrorClick}>
							<UnCheckSvg /> {errorMessage === null ? <ShortErrorMessage>Ошибка</ShortErrorMessage> : getShortErrorMessage(errorMessage)}
						</StatusWrapper>
					);
				}

				return <Badge text={statusData.text} type="status" status={statusData.color} />;
			},
			onSortedBy: (direction) => {
				handleSortChange("status", direction);
				paginationData.setClientsCurrentPage(1);
			},
			columnCellStyles: {
				minWidth: "100px",
			},
		},
		{
			dataIndex: "dateTime",
			title: "Дата и время",
			key: "dateTime",
			onSearchByFormatterString: (v) => v.replace(/[^\d.]/g, ""),
			onSortedBy: (direction) => {
				if (direction !== sortedBy.dateTime) {
					handleSortChange("createDateTime", direction);
					paginationData.setClientsCurrentPage(1);
				}
			},
			filters: [
				{ text: "Current year", key: "startCreationTime", as: "date", label: "от" } as const,
				{ text: "Next year", key: "endCreationTime", as: "date", label: "до" } as const,
			],
			datePickerFiltersProps: [
				{
					key: "startCreationTime",
					props: {
						isValidDateCallback: (date) => {
							const clickedDate = dayjs(date);
							const today = dayjs().endOf("day");

							if (today.isBefore(clickedDate)) {
								showToast({ type: "danger", description: "Нельзя выбрать дату в будущем" });
								return false;
							}

							if (preSelectedTime.current.endCreationTime && dayjs(preSelectedTime.current.endCreationTime).isBefore(clickedDate)) {
								const endTime = dayjs(preSelectedTime.current.endCreationTime).format("DD.MM.YYYY");
								showToast({ type: "danger", description: `Нельзя указать дату после ${endTime}` });
								return false;
							}

							preSelectedTime.current.startCreationTime = date;
							return true;
						},
					},
				},
				{
					key: "endCreationTime",
					props: {
						isValidDateCallback: (date) => {
							const lastDayOfCurrentYear = dayjs().endOf("year");
							const today = dayjs();
							let clickedDate = dayjs(date);

							if (clickedDate.month() === 0 && today.month() === 11) {
								clickedDate = clickedDate.year(today.year() + 1);
							}

							if (clickedDate.isAfter(lastDayOfCurrentYear)) {
								showToast({ type: "danger", description: "Нельзя выбрать дату из следующего года" });
								return false;
							}

							if (today.isBefore(clickedDate)) {
								showToast({ type: "danger", description: "Нельзя выбрать период в будущем" });
								return false;
							}

							if (preSelectedTime.current.startCreationTime && dayjs(preSelectedTime.current.startCreationTime).isAfter(clickedDate)) {
								const startTime = dayjs(preSelectedTime.current.startCreationTime).format("DD.MM.YYYY");
								showToast({ type: "danger", description: `Нельзя указать дату до ${startTime}` });
								return false;
							}

							preSelectedTime.current.endCreationTime = date;
							return true;
						},
					},
				},
			],

			onChangeFilter: (data) => {
				data.forEach((item) => {
					if (item.key === "startCreationTime") {
						preSelectedTime.current.startCreationTime = item.resultData?.dates?.date ? new Date(item.resultData.dates.date) : undefined;
					}
					if (item.key === "endCreationTime") {
						preSelectedTime.current.endCreationTime = item.resultData?.dates?.date ? new Date(item.resultData.dates.date) : undefined;
					}
				});
				handleApplyFilters();
			},
			columnCellStyles: {
				minWidth: "120px",
			},
		},
		{
			dataIndex: "employee",
			title: "Сотрудник",
			key: "employee",
			onSearch: (search) => {
				setSearchEmployee(search);
				paginationData.setClientsCurrentPage(1);
				paginationData.setClientsSize(counters.data.size);
			},
			onSearchByFormatterString: (v) => v.replace(/\d/g, ""),
			columnCellStyles: {
				minWidth: "150px",
			},
		},
	];

	const data =
		counters.data?.content?.map((item) => ({
			importType: item.types,
			status: item,
			dateTime: dayjs(item.createDateTime).format("DD.MM.YYYY HH:mm"),
			employee: item?.fullName,
		})) ?? [];

	return {
		data,
		columns,
		isFetching: counters.isFetching,
		paginationData,
		resetFilters,
	};
};

const StatusWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;
`;

const ShortErrorMessage = styled.div`
	font-size: 14px;
`;

export default useTableData;
