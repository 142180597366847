import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import styled from "styled-components";
import { ButtonGroup, FormItem, Input, Select, Space, TContextMenuItem, Text } from "tap2visit-ui-kit";

import { getUserByIdApi } from "api/api.clients";
import { getClientApi, TGetClientApiParams } from "api/api.gateway";
import { getRolesApi } from "api/api.realEstate";
import { useTableFilterContext } from "components/TableFilter/TableFilter.context";
import { mask } from "constants/constants.mask";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import { ValidationStatuses } from "modules/validation/Validation.store";
import AppealsClientIdValidation from "pages/appeals/store/AppealsClientIdValidation";
import AppealsCreateValidationStore from "pages/appeals/store/AppealsCreateValidationStore";
import DrawerStore from "pages/appeals/store/Drawer.store";
import { useDebounce } from "pages/appeals/utils/useDebounce";

const AppealsApplicantDrawer = ({ isViewDrawer }: { isViewDrawer?: boolean }) => {
	const [selectButton, setSelectButton] = useState<"OWNER" | "NOTOWNER">("OWNER");
	const [fullName, setFullName] = useState("");
	const [items, setItems] = useState<TContextMenuItem[]>([]);
	const [isOpenSelect, setIsOpenSelect] = useState(false);
	const tableFilterContext = useTableFilterContext();
	const validationFields = AppealsCreateValidationStore.validationData;
	const validationFieldClientId = AppealsClientIdValidation.validationData;
	const errorMessageClientId = AppealsClientIdValidation.getMessageIfError("clientId");

	const errorMessageLastName = AppealsCreateValidationStore.getMessageIfError("lastName");
	const errorMessageFirstName = AppealsCreateValidationStore.getMessageIfError("firstName");
	const errorMessagePhone = AppealsCreateValidationStore.getMessageIfError("phone");
	const errorMessageEmail = AppealsCreateValidationStore.getMessageIfError("email");
	const errorMessageLogin = AppealsCreateValidationStore.getMessageIfError("login");
	const isOwner = DrawerStore.selectedAppeals?.applicant?.isOwner;
	const debouncedSearchFullName = useDebounce(fullName, 1000);

	const roles = useQuery({
		queryFn: () => getRolesApi(),
		queryKey: [QueriesKeys.roles],
		staleTime: Infinity,
	});

	const { data: currentUser, isSuccess: isCurrentUserSuccess } = useQuery({
		queryFn: () => getUserByIdApi({ path: { id: DrawerStore.selectedAppeals.applicant.clientId } }),
		queryKey: [QueriesKeys.user, DrawerStore.selectedAppeals.applicant?.clientId, DrawerStore.selectedAppeals?.isCreatedByClient],
		enabled: !!DrawerStore.selectedAppeals.applicant?.clientId && !!DrawerStore.selectedAppeals?.isCreatedByClient,
		cacheTime: 0,
		staleTime: 0,
	});

	useQuery({
		queryFn: () => {
			const params: TGetClientApiParams = {
				buildingObjectIds: isViewDrawer
					? [DrawerStore.selectedAppeals.buildingObjectAddress.buildingObjectId]
					: tableFilterContext.selectedBuildingObjectIds,
				fullNameSearch: debouncedSearchFullName,
				roleIds: [roles.data.find((role) => role.name === "OWNER")?.id],
			};

			return getClientApi({
				params,
			});
		},
		enabled: isViewDrawer
			? !!DrawerStore.selectedAppeals.buildingObjectAddress.buildingObjectId && !!debouncedSearchFullName
			: !!tableFilterContext.selectedBuildingObjectIds.length,
		queryKey: [
			QueriesKeys.clientsAppeals,
			DrawerStore.selectedAppeals?.buildingObjectAddress?.buildingObjectId,
			tableFilterContext.selectedBuildingObjectIds[0],
			debouncedSearchFullName,
		],
		onSuccess(data) {
			setItems(
				data.content.map((client) => ({
					key: client.id,
					text: client.fullName,
					focused: client.id === DrawerStore.selectedAppeals.applicant?.clientId ? true : false,
				})),
			);
		},
	});

	useEffect(() => {
		if (tableFilterContext.selectedBuildingObjectIds?.length > 0) {
			setFullName("");
		}
	}, [tableFilterContext.selectedBuildingObjectIds[0]]);

	useEffect(() => {
		if (debouncedSearchFullName) {
			queryClient.invalidateQueries([QueriesKeys.clientsAppeals]);
		}
	}, [debouncedSearchFullName]);

	useEffect(() => {
		if (DrawerStore.openDrawer) {
			queryClient.invalidateQueries([QueriesKeys.clientsAppeals]);
		}
	}, [DrawerStore.openDrawer]);

	useEffect(() => {
		if (!isViewDrawer) {
			setFullName("");
		}
	}, [isViewDrawer]);

	useEffect(() => {
		if (isOwner && isViewDrawer) {
			const lastName = DrawerStore.selectedAppeals.applicant.lastName;
			const firstName = DrawerStore.selectedAppeals.applicant.firstName;
			const middleName = DrawerStore.selectedAppeals.applicant.middleName ?? "";

			setFullName(`${lastName} ${firstName} ${middleName}`);
		}
	}, [DrawerStore.selectedAppeals.applicant]);

	const buttons = [
		{
			text: "Собственник",
			selected: selectButton === "OWNER",
			disabled: isViewDrawer && !DrawerStore.selectedAppeals.applicant.isOwner && !DrawerStore.selectedAppeals.isCreatedByClient,
			onClick: () => {
				setSelectButton("OWNER");
				DrawerStore.setSelectButtonIsOwner(true);
			},
		},
		{
			text: "Не собственник",
			selected: selectButton === "NOTOWNER",
			disabled: isViewDrawer && DrawerStore.selectedAppeals.applicant.isOwner,
			onClick: () => {
				setSelectButton("NOTOWNER");
				DrawerStore.setSelectButtonIsOwner(false);
			},
		},
	];

	useEffect(() => {
		if (isViewDrawer && !DrawerStore.selectedAppeals.applicant.isOwner) {
			setSelectButton("NOTOWNER");
		} else {
			setSelectButton("OWNER");
		}
	}, [isViewDrawer, DrawerStore.selectedAppeals?.applicant?.isOwner]);

	useEffect(() => {
		if (
			tableFilterContext.selectedFloorsIds.length > 0 ||
			tableFilterContext.selectedEntrancesIds.length > 0 ||
			tableFilterContext.selectedComplexIds.length > 0 ||
			tableFilterContext.selectedBuildingsIds.length > 0 ||
			tableFilterContext.selectedBuildingObjectIds.length > 0
		)
			setFullName("");
	}, [
		tableFilterContext.selectedFloorsIds,
		tableFilterContext.selectedEntrancesIds,
		tableFilterContext.selectedComplexIds,
		tableFilterContext.selectedBuildingsIds,
		tableFilterContext.selectedBuildingObjectIds,
	]);

	const onClickSelectItem = (item) => {
		setIsOpenSelect(false);
		setItems(
			items.map((itemValue) => ({
				...itemValue,
				focused: itemValue.key === item.key,
			})),
		);
		DrawerStore.setApplicant({ ...DrawerStore.applicant, clientId: item.key });
		DrawerStore.setSelectedAppeals({
			...DrawerStore.selectedAppeals,
			applicant: {
				...DrawerStore.selectedAppeals.applicant,
				clientId: item.key,
			},
		});
	};
	return (
		<Space width="100%" direction="column" align="start" size={8}>
			{!isViewDrawer && <Text h="h5">Заявитель</Text>}
			<AppealsDrawerWrapper isViewDrawer={isViewDrawer}>
				<Space>
					<ButtonGroup buttons={buttons} />
				</Space>
				{selectButton === "OWNER" && (
					<FormItem
						state={validationFieldClientId.clientId !== ValidationStatuses.VALID ? "error" : "default"}
						message={errorMessageClientId}
						isRequiredStarLeft
						required
						label="Житель">
						<Select
							isError={validationFieldClientId.clientId !== ValidationStatuses.VALID}
							iconSearch
							showSearch
							style={{ width: "100%" }}
							size="m"
							styleInput={{ width: "100%" }}
							styleContent={{ width: "100%" }}
							isDisabled={
								(isViewDrawer && !DrawerStore.selectedAppeals.buildingObjectAddress.buildingObjectId) ||
								(!isViewDrawer && tableFilterContext.selectedBuildingObjectIds.length === 0)
							}
							onChangeValue={(value) => {
								setFullName(value);
							}}
							value={isCurrentUserSuccess ? currentUser.fullName : fullName}
							items={items}
							tag={false}
							onClickSelect={() => setIsOpenSelect(true)}
							isOpened={isOpenSelect}
							onClose={() => setIsOpenSelect(false)}
							onClickSelectItem={(item) => {
								onClickSelectItem(item);
							}}
							placeholder="Введите  ФИО"
						/>
					</FormItem>
				)}
				{selectButton === "NOTOWNER" && (
					<>
						<FormItem
							state={validationFields.lastName !== ValidationStatuses.VALID ? "error" : "default"}
							message={errorMessageLastName}
							isRequiredStarLeft
							required
							label="Фамилия">
							<Input
								state={validationFields.lastName !== ValidationStatuses.VALID ? "error" : "default"}
								value={isViewDrawer ? DrawerStore.selectedAppeals.applicant.lastName : DrawerStore.applicant.lastName}
								onChange={(e) => {
									isViewDrawer
										? DrawerStore.setSelectedAppeals({
												...DrawerStore.selectedAppeals,
												applicant: {
													...DrawerStore.selectedAppeals.applicant,
													lastName: e.target.value,
												},
											})
										: DrawerStore.setApplicant({ ...DrawerStore.applicant, lastName: e.target.value });
								}}
								sizeInput="large"
								placeholder="Иванов"
							/>
						</FormItem>
						<FormItem
							state={validationFields.firstName !== ValidationStatuses.VALID ? "error" : "default"}
							message={errorMessageFirstName}
							isRequiredStarLeft
							required
							label="Имя">
							<Input
								state={validationFields.firstName !== ValidationStatuses.VALID ? "error" : "default"}
								value={isViewDrawer ? DrawerStore.selectedAppeals.applicant.firstName : DrawerStore.applicant.firstName}
								onChange={(e) => {
									isViewDrawer
										? DrawerStore.setSelectedAppeals({
												...DrawerStore.selectedAppeals,
												applicant: {
													...DrawerStore.selectedAppeals.applicant,
													firstName: e.target.value,
												},
											})
										: DrawerStore.setApplicant({ ...DrawerStore.applicant, firstName: e.target.value });
								}}
								sizeInput="large"
								placeholder="Иван"
							/>
						</FormItem>
						<FormItem isRequiredStarLeft required label="Отчество">
							<Input
								value={isViewDrawer ? (DrawerStore.selectedAppeals.applicant.middleName ?? "") : DrawerStore.applicant.middleName}
								onChange={(e) => {
									isViewDrawer
										? DrawerStore.setSelectedAppeals({
												...DrawerStore.selectedAppeals,
												applicant: {
													...DrawerStore.selectedAppeals.applicant,
													middleName: e.target.value,
												},
											})
										: DrawerStore.setApplicant({ ...DrawerStore.applicant, middleName: e.target.value });
								}}
								sizeInput="large"
								placeholder="Петрович"
							/>
						</FormItem>
						<FormItem
							state={validationFields.login !== ValidationStatuses.VALID ? "error" : "default"}
							message={errorMessageLogin}
							label="Логин в приложении">
							<Input
								state={validationFields.login !== ValidationStatuses.VALID ? "error" : "default"}
								value={isViewDrawer ? DrawerStore.selectedAppeals.applicant.login : DrawerStore.applicant.login}
								onChange={(e) =>
									isViewDrawer
										? DrawerStore.setSelectedAppeals({
												...DrawerStore.selectedAppeals,
												applicant: {
													...DrawerStore.selectedAppeals.applicant,
													login: e.target.value,
												},
											})
										: DrawerStore.setApplicant({ ...DrawerStore.applicant, login: e.target.value })
								}
								sizeInput="large"
								mask={mask.phone}
								maskChair=""
								placeholder="+7 000 000-00-00"
							/>
						</FormItem>
						<FormItem
							state={validationFields.phone !== ValidationStatuses.VALID ? "error" : "default"}
							isRequiredStarLeft
							required
							message={errorMessagePhone}
							label="Телефон для связи">
							<Input
								state={validationFields.phone !== ValidationStatuses.VALID ? "error" : "default"}
								value={
									isViewDrawer ? DrawerStore.selectedAppeals.applicant.phoneNumbers[0] : (DrawerStore.applicant?.phoneNumbers?.[0] ?? "")
								}
								onChange={(e) =>
									isViewDrawer
										? DrawerStore.setSelectedAppeals({
												...DrawerStore.selectedAppeals,
												applicant: {
													...DrawerStore.selectedAppeals.applicant,
													phoneNumbers: [e.target.value],
												},
											})
										: DrawerStore.setApplicant({ ...DrawerStore.applicant, phoneNumbers: [e.target.value] })
								}
								sizeInput="large"
								mask={mask.phone}
								maskChair=""
								placeholder="+7 000 000-00-00"
							/>
						</FormItem>
						<FormItem
							state={validationFields.email !== ValidationStatuses.VALID ? "error" : "default"}
							message={errorMessageEmail}
							isRequiredStarLeft
							required
							label="Эл. почта">
							<Input
								state={validationFields.email !== ValidationStatuses.VALID ? "error" : "default"}
								value={isViewDrawer ? DrawerStore.selectedAppeals.applicant.emails[0] : (DrawerStore.applicant.emails?.[0] ?? "")}
								onChange={(e) =>
									isViewDrawer
										? DrawerStore.setSelectedAppeals({
												...DrawerStore.selectedAppeals,
												applicant: {
													...DrawerStore.selectedAppeals.applicant,
													emails: [e.target.value],
												},
											})
										: DrawerStore.setApplicant({ ...DrawerStore.applicant, emails: [e.target.value] })
								}
								sizeInput="large"
								placeholder="example@example.com"
							/>
						</FormItem>
					</>
				)}
			</AppealsDrawerWrapper>
		</Space>
	);
};

const AppealsDrawerWrapper = styled.div<{ isViewDrawer?: boolean }>`
	padding: 18px;
	border-radius: 12px;
	width: 100%;
	gap: 15px;
	display: flex;
	flex-direction: column;
	background: ${({ isViewDrawer }) => (!isViewDrawer ? "#12121205" : "none")};
	padding: ${({ isViewDrawer }) => (!isViewDrawer ? "18px" : "0")};
`;
export default observer(AppealsApplicantDrawer);
