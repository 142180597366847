import { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { useMutation } from "react-query";
import styled from "styled-components";
import { Drawer, FormItem, Input, showToast, Space, Text, TextArea } from "tap2visit-ui-kit";

import { createAppealApi } from "api/api.gateway";
import CitizenFiles from "components/Citizens/components/CitizenFiles";
import useNewFileState from "components/Citizens/hooks/useNewFileState";
import useUploadCitizenFiles from "components/Citizens/hooks/useUploadCitizenFiles";
import BaseModal from "components/Modals/BaseModal";
import BuildingFilterStore from "components/TableFilter/BuildingFilter.store";
import TableFilterValidation from "components/TableFilter/store/TableFilterValidation";
import { TableFilterContext } from "components/TableFilter/TableFilter.context";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import AppealsClientIdValidation from "pages/appeals/store/AppealsClientIdValidation";
import AppealsCreateTitleValidationStore from "pages/appeals/store/AppealsCreateTitleValidationStore";
import AppealsCreateValidationStore from "pages/appeals/store/AppealsCreateValidationStore";

import DrawerStore from "../../store/Drawer.store";
import getFieldState from "../../utils/getState";

import AppealsAddressDrawer from "./AppealsAddressDrawer";
import AppealsApplicantDrawer from "./AppealsApplicantDrawer";
import AppealsSettingsDrawer from "./AppealsSettingsDrawer";

const TableFilterInstanceStore = new BuildingFilterStore();

const AppealsCreateDrawer = () => {
	const validationFields = AppealsCreateTitleValidationStore.validationData;
	const errorMessageTitle = AppealsCreateTitleValidationStore.getMessageIfError("title");
	const errorMessageDescription = AppealsCreateTitleValidationStore.getMessageIfError("description");
	const [isOpenModal, setIsOpenModal] = useState(false);

	const { needToRemoveFileIds, newFiles, setNeedToRemoveFileIds, setNewFiles } = useNewFileState();
	const uploadFiles = useUploadCitizenFiles({
		needToRemoveFileIds: needToRemoveFileIds,
		newFiles: newFiles,
		bucket: "APPEAL_FILES",
	});

	const createAppealFuncApi = useMutation(createAppealApi);

	useEffect(() => {
		DrawerStore.setSelectButtonIsOwner(true);
	}, [DrawerStore.isOpenCreateDrawer]);

	const createAppeal = async () => {
		const { firstName, middleName, lastName, emails, phoneNumbers, login } = DrawerStore.applicant;

		const isValidAddress = TableFilterValidation.checkValidation({
			selectComplexes: TableFilterInstanceStore.selectedComplexIds[0],
			selectBuildings: TableFilterInstanceStore.selectedBuildingsIds[0],
			selectEntrances: TableFilterInstanceStore.selectedEntrancesIds[0],
			selectBuildingObjects: TableFilterInstanceStore.selectedBuildingObjectIds[0],
			selectFloors: TableFilterInstanceStore.selectedFloorsIds[0],
		});

		const isValidTitle = AppealsCreateTitleValidationStore.checkValidation({
			title: DrawerStore.titleAppeal,
			description: DrawerStore.descriptionAppeal,
		});

		if (DrawerStore.selectButtonIsOwner) {
			const isValid = AppealsClientIdValidation.checkValidation({
				clientId: DrawerStore.applicant.clientId,
			});

			if (!isValid || !isValidTitle || !isValidAddress) return;
		}

		if (!DrawerStore.selectButtonIsOwner) {
			const isValid = AppealsCreateValidationStore.checkValidation({
				firstName,
				lastName,
				email: emails?.[0] ?? "",
				phone: phoneNumbers?.[0]?.replace(/[-+()_\s]/g, "") ?? "",
				login: login?.replace(/[-+()_\s]/g, "") ?? undefined,
			});

			if (!isValid || !isValidTitle || !isValidAddress) return;
		}

		if (!isValidAddress) return;

		if (!isValidTitle) return;

		if (TableFilterInstanceStore.selectedBuildingObjectIds.length === 0) {
			return showToast({
				description: "Выберите номер квартиры",
				type: "danger",
			});
		}

		if (DrawerStore.selectButtonIsOwner && !DrawerStore.applicant.clientId) {
			return showToast({
				description: "Выберите заявителя",
				type: "danger",
			});
		}

		const newFileIds = await uploadFiles();

		createAppealFuncApi
			.mutateAsync({
				title: DrawerStore.titleAppeal,
				description: DrawerStore.descriptionAppeal,
				buildingObjectId: TableFilterInstanceStore.selectedBuildingObjectIds[0],
				fileIds: newFileIds,
				priority: DrawerStore.selectedSettingsAppeals ?? "NONE",
				applicant: {
					clientId: DrawerStore.selectButtonIsOwner ? DrawerStore.applicant.clientId : undefined,
					firstName: !DrawerStore.selectButtonIsOwner ? firstName : undefined,
					lastName: !DrawerStore.selectButtonIsOwner ? lastName : undefined,
					middleName: !DrawerStore.selectButtonIsOwner ? middleName : undefined,
					phoneNumber: !DrawerStore.selectButtonIsOwner ? phoneNumbers[0] : undefined,
					email: !DrawerStore.selectButtonIsOwner ? emails[0] : undefined,
					// eslint-disable-next-line no-nested-ternary
					login: !DrawerStore.selectButtonIsOwner ? (login?.length === 0 ? undefined : login) : undefined,
				},
			})
			.then(() => {
				queryClient.invalidateQueries([QueriesKeys.appealsList]);
				showToast({
					description: "Обращение создано",
					type: "success",
				});
				AppealsCreateValidationStore.resetValidation();
				AppealsCreateTitleValidationStore.resetValidation();
				AppealsClientIdValidation.resetValidation();
				TableFilterInstanceStore.clearFilter();
				DrawerStore.closeCreateDrawer();
			});
	};

	const checkInputOfAppeal = () =>
		DrawerStore.applicant?.firstName ||
		DrawerStore.applicant.emails?.length > 0 ||
		DrawerStore.applicant.phoneNumbers?.length > 0 ||
		DrawerStore?.titleAppeal ||
		DrawerStore?.descriptionAppeal ||
		TableFilterInstanceStore.selectedBuildingObjectIds?.length > 0;

	const closeDrawer = () => {
		if (checkInputOfAppeal()) {
			return setIsOpenModal(true);
		}

		AppealsCreateValidationStore.resetValidation();
		AppealsCreateTitleValidationStore.resetValidation();
		TableFilterValidation.resetValidation();
		TableFilterInstanceStore.clearFilter();
		AppealsClientIdValidation.resetValidation();
		DrawerStore.closeCreateDrawer();
	};

	useEffect(() => {
		setNewFiles([]);
	}, [DrawerStore.isOpenCreateDrawer]);

	return (
		<TableFilterContext.Provider value={TableFilterInstanceStore}>
			<Drawer
				width="560px"
				title={<Text h="h4">Новое обращение</Text>}
				textOk="Сохранить"
				textCancel="Отменить"
				onCancel={closeDrawer}
				onClose={closeDrawer}
				onOk={createAppeal}
				visible={DrawerStore.isOpenCreateDrawer}>
				<Space size={24} direction="column" align="start">
					<AppealsAddressDrawer />
					<AppealsApplicantDrawer />
					<Space width="100%" direction="column" align="start" size={8}>
						<Text h="h5">Обращение</Text>
						<AppealsDrawerWrapper>
							<FormItem
								state={getFieldState(validationFields.title)}
								message={errorMessageTitle}
								label="Заголовок"
								isRequiredStarLeft
								required>
								<Input
									state={getFieldState(validationFields.title)}
									onChange={(e) => DrawerStore.setTitleAppeal(e.target.value)}
									value={DrawerStore.titleAppeal}
									sizeInput="large"
									placeholder="Введите заголовок"
								/>
							</FormItem>
							<FormItem
								state={getFieldState(validationFields.description)}
								message={errorMessageDescription}
								label="Описание"
								isRequiredStarLeft
								required>
								<TextArea
									state={getFieldState(validationFields.description)}
									onChange={(e) => DrawerStore.setDescriptionAppeal(e.target.value)}
									value={DrawerStore.descriptionAppeal}
									size="large"
									style={{ width: "100%", background: "white" }}
									placeholder="Введите текст"
								/>
							</FormItem>
							<CitizenFiles
								setNeedToRemoveFileIds={setNeedToRemoveFileIds}
								needToRemoveFileIds={needToRemoveFileIds}
								newFiles={newFiles}
								setNewFiles={setNewFiles}
								title="Прикрепить фотографии"
								accept="image/jpeg,image/png,image/jpg,image/webp"
							/>
						</AppealsDrawerWrapper>
					</Space>
					<AppealsSettingsDrawer />
				</Space>
				<BaseModal
					isVisible={isOpenModal}
					okText="Продолжить"
					onCancel={() => setIsOpenModal(false)}
					onOk={() => {
						DrawerStore.closeCreateDrawer();
						setIsOpenModal(false);
					}}
					cancelText="Отменить"
					status="delete"
					title="Не сохраненные изменения будут потеряны"
					description="Вы внесли изменения и не сохранили их"
				/>
			</Drawer>
		</TableFilterContext.Provider>
	);
};

const AppealsDrawerWrapper = styled.div`
	padding: 18px;
	background: #12121205;
	border-radius: 12px;
	width: 100%;
	gap: 15px;
	display: flex;
	flex-direction: column;
`;

export default observer(AppealsCreateDrawer);
