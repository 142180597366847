import React, { useState, useEffect } from "react";

import { observer } from "mobx-react-lite";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Drawer, Avatar, Segmented, showToast } from "tap2visit-ui-kit";

import { getClientsByBuildingObjectId } from "api/api.realEstate";
import { downloadFileApi } from "api/api.storage";
import CitizensDrawer from "components/Citizens/Citizens.drawer";
import Collapse from "components/Collapse/Collapse";
import type { IRequestClientWithRole } from "interfaces/IRequest";
import type { IRole } from "interfaces/IRole";
import { QueriesKeys } from "interfaces/queriesKeys";
import { ReactComponent as DocumentIcon } from "public/documentIcon.svg";
import emitter from "services/emitter";

import { PROPERTY_TYPES } from "../../constants";
import useUserRole from "../../hooks/useUserRole";
import AccessRequestStore from "../../store/AccessRequest.Store";
import AccessRequestAlert from "../AccessRequestAlert/AccessRequestAlert";
import AccessRequestCollapseTable from "../AccessRequestCollapseTable";
import AccessRequestUserTable from "../AccessRequestUserTable";
import ActionButtons from "../ActionButtons/ActionButtons";

import { AccessRequestClientListRow } from "./AccessRequest.clientListRow";
import {
	AccessRequestBottomSubTitle,
	AccessRequestBottomTitle,
	AccessRequestDrawerWidth,
	AccessRequestUserTableBorder,
	AccessRequestUserTableWrapper,
	AccessRequestWrapperBottom,
	AccessRequestWrapperTitleTable,
	ComparedUserAvatarContainer,
	ComparedUserAvatarWrapper,
	CustomDivider,
	TableAvatarContainer,
	UserName,
	UserNameSubtitle,
	WrapperUser,
	WrapperUserAvatar,
	WrapperUserName,
} from "./AccessRequestDrawer.styled";

interface IAccessRequestDrawerProps {
	open: boolean;
	setOpen: (v: boolean) => void;
	setOpenChangeDrawer: (v: boolean) => void;
}

export interface IItems {
	key: string;
	label: JSX.Element;
	children: JSX.Element;
}

const AccessRequestDrawer = ({ open, setOpen }: IAccessRequestDrawerProps) => {
	const [similarUsers, setSimilarUsers] = useState<IRequestClientWithRole[] | undefined>([]);
	const [buildingUserRoles, setBuildingUserRoles] = React.useState<IRole[]>([]);
	const [items, setItems] = useState<IItems[]>([]);
	const [selectedComparedUserId, setSelectedComparedUserId] = React.useState<string>();
	const [avatarUrl, setAvatarUrl] = useState<string[] | null>([]);
	const [mainAvatarUrl, setMainAvatarUrl] = useState<string | null>(null);

	const item = AccessRequestStore.accessRequestItem;
	const userFirstName = item.fullName?.split(" ")?.[1];
	const userLastName = item.fullName?.split(" ")?.[0];
	const userInitialsText = !!userFirstName && !!userLastName ? `${userLastName?.[0]}${userFirstName?.[0]}` : "-";
	const currentSimilarUser = similarUsers?.find((user) => user?.id === selectedComparedUserId);

	const roleString = useUserRole({ clientId: item.clientId, roleId: item.roleId });
	const handleCloseModal = () => {
		setOpen(false);
		resetState();
	};

	const resetState = () => {
		setSimilarUsers([]);
		setSelectedComparedUserId(undefined);
		setBuildingUserRoles([]);
		setItems([]);
		setAvatarUrl([]);
		setMainAvatarUrl(null);
	};

	const fetchUserAvatar = async (avatarId: string | null) => {
		if (!avatarId) {
			console.warn("avatarId отсутствует, запрос не будет отправлен");
			return null;
		}
		try {
			const data = await downloadFileApi(avatarId);
			if (data) {
				const blob = new Blob([data]);
				return URL.createObjectURL(blob);
			}
		} catch (error) {
			console.error("Ошибка загрузки аватара", error);
		}
		return null;
	};

	const cancelRequestHandler = () => {
		handleCloseModal();
	};

	const handleUserComparison = (user: IRequestClientWithRole, isComparedUser: boolean) => {
		if (isComparedUser) {
			setSimilarUsers((users) => users.filter((currUser) => user.id !== currUser.id));
		} else {
			const updatedUsers = [...(similarUsers ?? []), user];

			if (updatedUsers.length > 4) {
				showToast({
					description: "Нельзя добавлять в сравнение более 4-х участников.",
					type: "info",
				});
			} else {
				setSimilarUsers(updatedUsers);
			}
		}
	};

	const {
		data: clientList,
		isFetching: clientListIsFetching,
		refetch,
	} = useQuery({
		queryFn: () =>
			getClientsByBuildingObjectId({
				buildingObjectId: item.buildingObjectId,
				roleId: item.roleId,
			}),
		queryKey: [QueriesKeys.clients, item.buildingObjectId],
		enabled: !!item.buildingObjectId,
	});

	useEffect(() => {
		(async () => {
			if (!similarUsers) return;

			const avatarPromises = similarUsers.map((user) => fetchUserAvatar(user.avatarId));

			if (avatarPromises.length > 0) {
				try {
					const loadedAvatars = await Promise.all(avatarPromises);
					setAvatarUrl((prev) => [...(prev || []), ...(loadedAvatars.filter(Boolean) as string[])]);
				} catch (error) {
					console.error("Ошибка загрузки аватаров", error);
				}
			}
		})();
	}, [similarUsers]);

	useEffect(() => {
		(async () => {
			if (item.client?.avatarId) {
				try {
					const imgLink = await fetchUserAvatar(item.client.avatarId);
					setMainAvatarUrl(imgLink);
				} catch (error) {
					console.error("Ошибка загрузки аватарки", error);
				}
			}
		})();
	}, [item.client?.avatarId]);

	useEffect(() => {
		emitter.addListener("cancelAccessRequest", cancelRequestHandler);

		return () => {
			emitter.removeListener("cancelAccessRequest", cancelRequestHandler);
		};
	}, []);

	useEffect(() => {
		if (clientList) {
			const updatedUsers = similarUsers?.map((user) => {
				const updatedUser = clientList.find((currUser) => currUser.id === user.id);
				return updatedUser;
			});
			setSimilarUsers(updatedUsers);
		}
	}, [clientList]);

	useEffect(() => {
		if (item.buildingObjectId) {
			refetch();
		}
	}, [open, item]);

	useEffect(() => {
		if (item && clientList) {
			for (const user of clientList) {
				const userRoles = clientList.find((client) => client.id === user.id)?.roles;
				const isEqualRole = userRoles?.map((role) => role.id)?.includes(item.roleId);
				const firstName = user.firstName || "";
				const lastName = user.lastName || "";
				const [itemLastName = "", itemFirstName = ""] = item.fullName?.split(" ") || [];

				if (lastName === itemLastName && firstName === itemFirstName && isEqualRole) {
					setSimilarUsers([user]);
					setSelectedComparedUserId(user.id);
				}
			}
		}
	}, [item, clientList]);

	useEffect(() => {
		const userRoles = clientList?.find((client) => client.id === selectedComparedUserId)?.roles;

		setBuildingUserRoles(userRoles);
	}, [selectedComparedUserId]);

	useEffect(() => {
		const isExistCurrentSelectedUserIdInComparedList = similarUsers?.some((user) => user.id === selectedComparedUserId);

		if (!isExistCurrentSelectedUserIdInComparedList) {
			if (similarUsers?.length) {
				setSelectedComparedUserId(similarUsers[0].id);
			} else {
				setSelectedComparedUserId(undefined);
			}
			return;
		}

		if (similarUsers.length === 1 && !selectedComparedUserId) {
			setSelectedComparedUserId(similarUsers[0].id);
			return;
		}

		setSelectedComparedUserId(similarUsers[similarUsers.length - 1].id);
	}, [similarUsers]);

	useEffect(() => {
		if (clientList && open) {
			const similarUserIds = similarUsers?.map((user) => user?.id);

			setItems(
				clientList
					.filter((user) => user)
					.map((user) => {
						const isComparedUser = similarUserIds?.includes(user.id);

						return {
							key: user.id || "unknown",
							label: user ? (
								<AccessRequestClientListRow
									user={user}
									status={item.status}
									isComparedUser={isComparedUser}
									handleUserComparison={handleUserComparison}
								/>
							) : (
								<span>Недоступный пользователь</span>
							),
							children: user ? <AccessRequestCollapseTable item={user} /> : <span>No data available</span>,
						};
					}),
			);
		}
	}, [clientList, open, similarUsers, item]);

	return (
		<>
			<Drawer
				title="Запрос от пользователя"
				visible={open}
				onClose={handleCloseModal}
				onCancel={handleCloseModal}
				width="700px"
				showCancel={false}
				showOk={false}
				iconButtonCancel={DocumentIcon}>
				<WrapperUser>
					<WrapperUserAvatar>
						<Avatar size="40" text={userInitialsText} img={mainAvatarUrl} />
						<WrapperUserName>
							<UserName>{item.fullName}</UserName>
							<UserNameSubtitle>{roleString}</UserNameSubtitle>
						</WrapperUserName>
					</WrapperUserAvatar>
					<ActionButtons requestUser={item} onRequestSuccess={handleCloseModal} compareUserId={currentSimilarUser?.id} />
				</WrapperUser>

				<CustomDivider />

				<AccessRequestAlert
					requestUser={AccessRequestStore.accessRequestItem}
					compareUser={currentSimilarUser}
					compareUserRoles={buildingUserRoles}
					isFetching={clientListIsFetching}
				/>

				<AccessRequestUserTableWrapper>
					<AccessRequestUserTableBorder />
					<AccessRequestWrapperTitleTable>
						<AccessRequestDrawerWidth />
						<AccessRequestDrawerWidth>
							<TableAvatarContainer>
								<Avatar size="32" text={userInitialsText} img={mainAvatarUrl} />
							</TableAvatarContainer>
						</AccessRequestDrawerWidth>
						{item.status === "NEW" && (
							<AccessRequestDrawerWidth>
								{similarUsers?.length > 0 && (
									<ComparedUserAvatarWrapper>
										<ComparedUserAvatarContainer>
											<Segmented
												size="contentWidth"
												items={similarUsers.map((user, index) => {
													const userInitials =
														!!user?.firstName && !!user?.lastName ? `${user?.lastName?.[0]}${user?.firstName?.[0]}` : "-";
													return {
														key: user?.id,
														title: <Avatar size="32" text={userInitials} img={avatarUrl[index]} />,
													};
												})}
												onClick={(item) => setSelectedComparedUserId(item.key)}
												selectedKey={selectedComparedUserId}
												direction={"horizontal"}
											/>
										</ComparedUserAvatarContainer>
									</ComparedUserAvatarWrapper>
								)}
							</AccessRequestDrawerWidth>
						)}
					</AccessRequestWrapperTitleTable>

					<AccessRequestUserTable similarObject={currentSimilarUser} buildingUserRoles={buildingUserRoles} />
				</AccessRequestUserTableWrapper>

				<AccessRequestWrapperBottom>
					<AccessRequestBottomTitle>{PROPERTY_TYPES[item.buildingObjectType]}</AccessRequestBottomTitle>
					<AccessRequestBottomSubTitle>{item.addressValue}</AccessRequestBottomSubTitle>
				</AccessRequestWrapperBottom>

				{clientListIsFetching && <Skeleton height={148} />}
				{!clientListIsFetching && <Collapse accordion items={items} />}
			</Drawer>
			<CitizensDrawer />
		</>
	);
};

export default observer(AccessRequestDrawer);
