import ValidationStore, { ValidationStatuses } from "modules/validation/Validation.store";

export type TAppealsClientIdValidation = {
	clientId: keyof typeof ValidationStatuses;
};

const initialValidationData: TAppealsClientIdValidation = {
	clientId: ValidationStatuses.VALID,
};

const COMMON_REQUIRED_ERROR_MESSAGE = "Обязательно к заполнению";

export const ERROR_MESSAGES: Record<keyof TAppealsClientIdValidation, Partial<Record<keyof typeof ValidationStatuses, string>>> = {
	clientId: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE },
};

const checkRules = {
	clientId: {
		[ValidationStatuses.REQUIRED]: true,
	},
};

const AppealsClientIdValidation = new ValidationStore<TAppealsClientIdValidation>(initialValidationData, ERROR_MESSAGES, checkRules);

export default AppealsClientIdValidation;
