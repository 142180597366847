import { Space } from "antd";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Tag, Text } from "tap2visit-ui-kit";

import { ReactComponent as Building } from "public/Bulk_Building.svg";

import CitizenBuildingObjectStore from "../../../components/Citizens/store/CitizenBuildingObject.store";

const CitizenHeaderInformation = () => {
	const number = CitizenBuildingObjectStore?.selectedBuilding?.number;

	return (
		<Container>
			<CustomSpace>
				<Tag leftElement={Building} text={number ? `Помещение: ${number}` : "Помещение: 502"} color="grey" size="l" />
				<Text type="large-regular">{CitizenBuildingObjectStore.selectedBuildingAddress} </Text>
			</CustomSpace>
		</Container>
	);
};

const CustomSpace = styled(Space)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const Container = styled.div`
	width: 100%;
	background: white;
	border-radius: 10px;
	padding: 12px;
`;

export default observer(CitizenHeaderInformation);
