import React from "react";

import { BoldEdit } from "t2v-icons";
import { Button, Space } from "tap2visit-ui-kit";

import type { IRequestClientWithRole } from "interfaces/IRequest";
import { ReactComponent as AlignIcon } from "public/Align.svg";

import ModalConfirmEditCitizenStore from "../ModalConfirmEditCitizen/store/ModalConfirmEditCitizen.store";

import { AccessRequestCollapseTitle, LabelWrapper } from "./AccessRequestDrawer.styled";

interface IUserActionsProps {
	user: IRequestClientWithRole;
	status: string;
	isComparedUser: boolean;
	handleUserComparison: (user: IRequestClientWithRole, isComparedUser: boolean) => void;
}

export const AccessRequestClientListRow = ({ user, status, isComparedUser, handleUserComparison }: IUserActionsProps) => (
	<LabelWrapper>
		<AccessRequestCollapseTitle>{`${user.lastName} ${user.firstName} ${user.middleName}`}</AccessRequestCollapseTitle>
		{status === "NEW" && (
			<Space size={8}>
				{/* TODO Need in future */}
				{/* <Button shape="square" size="medium" typeButton="primary" icon={DocumentIcon} /> */}
				<Button
					onClick={(e) => {
						e.stopPropagation();
						ModalConfirmEditCitizenStore.visible(user.id);
					}}
					shape="square"
					size="small"
					typeButton="primary"
					icon={BoldEdit}
				/>
				<Button
					shape="square"
					size="small"
					typeButton={isComparedUser ? "primary" : "secondary"}
					icon={AlignIcon}
					onClick={(e) => {
						e.stopPropagation();
						handleUserComparison(user, isComparedUser);
					}}
				/>
			</Space>
		)}
	</LabelWrapper>
);
