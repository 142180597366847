import React from "react";

import { TinyTable } from "tap2visit-ui-kit";

import type { IRequestClientWithRole } from "interfaces/IRequest";
import getRoleTranslate from "utils/getRoleTranslate";

import formatPhoneNumber from "../../../utils/format/formatPhone";

const AccessRequestCollapseTable = ({ item }: { item: IRequestClientWithRole }) => {
	const phones = item.phones.map((phone) => formatPhoneNumber(phone.value));
	const emails = item.emails.map((email) => email.value);

	const tableColumns = [
		["Роль", item.roles.length > 0 ? item.roles.map((role) => getRoleTranslate(role.name)).join(", ") : "Данные отсутствуют"],
		["Фамилия", item.lastName ?? "Данные отсутствуют"],
		["Имя", item.firstName ?? "Данные отсутствуют"],
		["Отчество", item.middleName ?? "Данные отсутствуют"],
		["ИНН", item.inn ?? "Данные отсутствуют"],

		["Логин приложения", formatPhoneNumber(item.phones.find((v) => v.isDefault)?.value) || "Данные отсутствуют"],

		...(phones.length > 0
			? phones.map((phone) => ["Телефон для связи", phone || "Данные отсутствуют"])
			: [["Телефон для связи", "Данные отсутствуют"]]),

		...(emails.length > 0 ? emails.map((email) => ["Эл. почта", email || "Данные отсутствуют"]) : [["Эл. почта", "Данные отсутствуют"]]),
	];

	return <TinyTable containerWidth={"1050px"} rows={tableColumns} />;
};

export default AccessRequestCollapseTable;
