import { useEffect, useState } from "react";
import React from "react";

import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import styled from "styled-components";
import { Alert, colors, Pagination, Table, Text } from "tap2visit-ui-kit";

import Page from "components/layout/Page";
import PaginationContainer from "components/PaginationContainer";
import TableEmptySlug from "components/Table/TableEmptySlug";
import TableLoader from "components/Table/TableLoader";
import { useTableFilterContext } from "components/TableFilter/TableFilter.context";
import { MIN_TABLE_HEIGHT } from "constants/constants.common";
import { emailRegExp } from "constants/constants.regexp";
import useTablePaginationLogic from "hooks/useTablePaginationLogic";
import useWindowWidth from "hooks/useWindowWidth";
import { TSortingDirection } from "interfaces/ISorting";
import { QueriesKeys } from "interfaces/queriesKeys";
import AccessRequestRejectModalStore from "pages/accessRequest/components/ModalCommentRejection/store/AccessRequestRejectModal.store";
import SideBarStore from "store/SideBar.store";
import { getOnlyDigitsString } from "utils/getOnlyDigitsString";
import { joinArrParams } from "utils/joinParams";

import { getRequestApi } from "../../../api/api.gateway";
import { queryClient } from "../../../index";
import { IAccessRequestArray } from "../../../interfaces/IRequest";
import formatPhoneNumber from "../../../utils/format/formatPhone";
import AccessRequestStore from "../store/AccessRequest.Store";
import AccessRequestTabStore from "../store/AccessRequestTab.store";
import getStatus from "../utils/getStatus";

import AccessRequestDrawer from "./AccessRequestDrawer/AccessRequestDrawer";
import AccessRequestButtons from "./AccessRequestTable/AccessRequest.buttons";
import { AccessRequestStatus } from "./AccessRequestTable/AccessRequest.status";

const EmptyComponent = () => <TableEmptySlug text="Нет заявок для отображения" />;

const AccessRequestTable = () => {
	const tableFilterContext = useTableFilterContext();
	const windowWidth = useWindowWidth();
	const paginationData = useTablePaginationLogic();

	const [fullName, setFullName] = useState<string | undefined>(undefined);
	const [searchAccountValue, setSearchAccountValue] = React.useState("");
	const [phone, setPhone] = useState<string | undefined>(undefined);
	const [email, setEmail] = useState<string | undefined>(undefined);
	const [sortedBy, setSortedBy] = useState<{ [key: string]: TSortingDirection }>({ createDateTime: "desc" });
	const [open, setOpen] = useState(false);
	const [openChangeDrawer, setOpenChangeDrawer] = useState(false);
	const isMounted = React.useRef(false);

	const handleSortChange = (key: string, direction: TSortingDirection | undefined) => {
		setSortedBy((prevState) => {
			const newState = { ...prevState };
			if (direction) {
				newState[key] = direction;
			} else {
				delete newState[key];
			}
			return newState;
		});
	};

	const createTableColumns = (activeTab: string) => {
		const mobileAppColumns = [
			{
				initialSortDirection: "desc",
				dataIndex: "date",
				onSortedBy: (direction) => handleSortChange("createDateTime", direction),
				key: "date",
				title: "Дата",
			},
			{
				dataIndex: "initials",
				key: "initials",
				title: "ФИО",
				onSearch: (name: string) => {
					setFullName(name);
					paginationData.setClientsCurrentPage(1);
				},
				onSortedBy: (direction) => handleSortChange("fullName", direction),
			},
			{
				dataIndex: "tel",
				key: "tel",
				title: "Телефон",
				onSearchByFormatterString: (v) => v.replace(/[^\d]/g, ""),
				onSearch: (item: string) => {
					setPhone(item);
					paginationData.setClientsCurrentPage(1);
				},
			},
			{
				dataIndex: "email",
				key: "email",
				title: "Эл. почта",
				onSearch: (item: string) => {
					setEmail(item);
					paginationData.setClientsCurrentPage(1);
				},
			},
			{
				dataIndex: "account",
				key: "account",
				title: "Лицевой счет",
				onSearch: (item: string) => {
					setSearchAccountValue(item);
					paginationData.setClientsCurrentPage(1);
				},
				onSearchByFormatterString: (v) => v.replace(/[^\d]/g, ""),
				onSortedBy: (direction) => handleSortChange("accountValue", direction),
			},
			{
				dataIndex: "address",
				key: "address",
				title: "Адрес",
			},
		];

		const archiveColumns = [
			{
				dataIndex: "status",
				key: "status",
				title: "",
				onSortedBy: (direction) => handleSortChange("status", direction),
			},
			{
				dataIndex: "button",
				key: "button",
				title: "",
			},
		];

		return activeTab === "archive" ? [...mobileAppColumns, ...archiveColumns] : mobileAppColumns;
	};

	const tableColumns = createTableColumns(AccessRequestTabStore.activeTab);

	const { data: requests, isFetching: requestApiFetching } = useQuery({
		queryFn: () =>
			getRequestApi({
				complexIds: joinArrParams(tableFilterContext.selectedComplexIds),
				buildingIds: joinArrParams(tableFilterContext.selectedBuildingsIds),
				entranceIds: joinArrParams(tableFilterContext.selectedEntrancesIds),
				floorIds: joinArrParams(tableFilterContext.selectedFloorsIds),
				buildingObjectIds: joinArrParams(tableFilterContext.selectedBuildingObjectIds),
				fullNameSearch: fullName,
				phoneNumberSearch: getOnlyDigitsString(phone),
				emailSearch: email,
				accountNumberSearch: searchAccountValue,
				statuses: AccessRequestTabStore.activeTab === "mobileApp" ? ["NEW"] : ["APPROVED", "CANCELED"],
				page: paginationData.clientsCurrentPage - 1,
				size: paginationData.clientsSize,
				sort: Object.entries(sortedBy).map(([key, direction]) => ({ key, direction })),
			}),
		queryKey: [
			QueriesKeys.requests,
			tableFilterContext.selectedComplexIds,
			tableFilterContext.selectedBuildingsIds,
			tableFilterContext.selectedEntrancesIds,
			tableFilterContext.selectedFloorsIds,
			tableFilterContext.selectedBuildingObjectIds,
			fullName,
			email,
			phone,
			searchAccountValue,
			sortedBy,
			AccessRequestTabStore.activeTab,
			paginationData.clientsSize,
			paginationData.clientsCurrentPage,
		],
	});

	const tableData = requests?.content?.map((user) => {
		const phoneToShow = formatPhoneNumber(user.client.phones.find((phone) => phone.isDefault)?.value || "Данные отсутствуют");

		const emailToShow = user.client.emails.length
			? user.client.emails.find((email) => email.isDefault)?.value || user.client.emails[0]?.value
			: "Данные отсутствуют";

		const emailComponent = emailRegExp.test(emailToShow) ? (
			<Link
				onClick={(e) => {
					e.stopPropagation();
				}}
				href={`mailto:${emailToShow}`}>
				{emailToShow}
			</Link>
		) : (
			<Text type="base-medium">{emailToShow}</Text>
		);

		return {
			initials: user.fullName,
			tel: phoneToShow,
			email: emailComponent,
			date: new Date(user.createDateTime).toLocaleDateString(),
			address: user.addressValue,
			account: user.accountValue,
			buildingObjectId: user.buildingObjectId,
			id: user.id,
			status: <AccessRequestStatus status={getStatus(user.status)} />,
			button: <AccessRequestButtons id={user.id} status={getStatus(user.status)} canBeRestored={user.canBeRestored} />,
		};
	});

	const isVisiblePagination = !requestApiFetching && requests;

	useEffect(() => {
		paginationData.setClientsCurrentPage(1);
		paginationData.setClientsTotal(requests?.totalPages);
	}, [
		paginationData.clientsSize,
		tableFilterContext.selectedComplexIds,
		tableFilterContext.selectedBuildingsIds,
		tableFilterContext.selectedEntrancesIds,
		tableFilterContext.selectedFloorsIds,
		tableFilterContext.selectBuildingObjects,
		sortedBy,
	]);

	useEffect(() => {
		if (isMounted.current) {
			queryClient.invalidateQueries([QueriesKeys.requests]);
		}
		isMounted.current = true;
	}, [fullName, phone, email]);

	useEffect(() => {
		if (requests) {
			paginationData.setClientsTotal(requests.totalPages);
		}
	}, [requests?.page, fullName, phone, email, AccessRequestTabStore.activeTab]);

	useEffect(() => {
		AccessRequestStore.setAccessRequestArray(tableData);
	}, [tableData]);

	return (
		<Page>
			<AlertWrapper>
				<Alert
					visible={AccessRequestRejectModalStore.isVisibleAlert}
					closeIcon
					withIcon
					onClose={() => AccessRequestRejectModalStore.closeAlert()}
					variant="info"
					title={AccessRequestRejectModalStore.alertMessage}
				/>
			</AlertWrapper>

			<AccessRequestDrawer open={open} setOpen={setOpen} setOpenChangeDrawer={setOpenChangeDrawer} />
			<Table
				onRowClick={(record: IAccessRequestArray, index) => {
					const request = requests?.content?.[index];
					if (request) {
						AccessRequestStore.setAccessRequestItem(request);
					}
					setOpen(true);
				}}
				style={{
					width: `calc(${windowWidth}px - ${SideBarStore.sideBarWidth} )`,
					overflowX: "auto",
					minHeight: MIN_TABLE_HEIGHT,
				}}
				isFullWidth
				columns={tableColumns}
				data={tableData ?? []}
				size="m"
				emptyComponent={requestApiFetching ? TableLoader : EmptyComponent}
			/>
			{isVisiblePagination && (
				<PaginationContainer>
					<Pagination
						onChangeSize={paginationData.setClientsSize}
						currentPage={paginationData.clientsCurrentPage}
						setCurrentPage={paginationData.setClientsCurrentPage}
						total={paginationData.clientsTotal}
						size={paginationData.clientsSize as 10 | 20 | 50 | 100}
					/>
				</PaginationContainer>
			)}
		</Page>
	);
};

export default observer(AccessRequestTable);

const AlertWrapper = styled.div`
	position: fixed;
	top: 32px;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	display: flex;
	justify-content: center;
	z-index: 1000;
`;

const Link = styled.a`
	color: ${colors.textInfoDefault};
	text-decoration: none;
`;
