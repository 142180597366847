import React from "react";

import { observer } from "mobx-react-lite";
import styled from "styled-components";

import BuildingFilterStore from "components/TableFilter/BuildingFilter.store";
import TableFilter from "components/TableFilter/TableFilter";
import { TableFilterContext } from "components/TableFilter/TableFilter.context";

import AppealsTab from "./components/AppealsTab";
import AppealsTable from "./components/AppealsTable";
import AppealsCreateDrawer from "./components/DrawerCreate/AppealsCreateDrawer";

const TableFilterInstanceStore = new BuildingFilterStore();

const Appeals = () => (
	<TableFilterContext.Provider value={TableFilterInstanceStore}>
		<AppealsTab />

		<AppealsFilterWrapper>
			<TableFilter customSwitchOptions={{ isVisibleSwitch: false }} />
		</AppealsFilterWrapper>
		<AppealsTable />
		<AppealsCreateDrawer />
	</TableFilterContext.Provider>
);

const AppealsFilterWrapper = styled.div`
	margin: 32px 22px;
`;

export default observer(Appeals);
