// ! temporary

import { Permissions } from "constants/constants.roles";

const getUserRoleByKey = (roleKey: string) => {
	switch (roleKey) {
		case Permissions.ADMIN:
			return "Главный администратор";
		case Permissions.EMPLOYEE:
			return "Сотрудник";
		case Permissions.MASTER:
			return "Мастер";
	}
};
export default getUserRoleByKey;
