import { MenuProps } from "antd";
import { useQuery } from "react-query";

import { getConfig } from "api/api.gateway";
import { isVisibleFunctionality } from "constants/constants.common";
import { ReactComponent as Chat } from "public/Chat.svg";
import { ReactComponent as AnnouncemetPoint } from "public/menu/announcement_point.svg";
import { ReactComponent as BoldDocumentpen } from "public/menu/BoldDocumentpen.svg";
import { ReactComponent as Build } from "public/menu/build.svg";
import { ReactComponent as Counter } from "public/menu/counter.svg";
import { ReactComponent as File } from "public/menu/file.svg";
import { ReactComponent as Home } from "public/menu/home.svg";
import { ReactComponent as List } from "public/menu/list.svg";
import { ReactComponent as Staff } from "public/menu/staf.svg";
import { ReactComponent as Update } from "public/menu/update.svg";
import { ReactComponent as User } from "public/menu/user.svg";
import { ReactComponent as PollSvg } from "public/poll.svg";
import { ROUTES } from "routes/constants/routes.constants";
import UKStore from "store/UK.Store";

type TMenuItem = Required<MenuProps>["items"][number];

const useMenuItems = () => {
	const currentUK = UKStore.getUK();

	const configUK = useQuery({
		queryFn: () => getConfig(currentUK.managingOrganizationId),
		queryKey: ["config", currentUK?.id],
		enabled: !!currentUK?.managingOrganizationId,
	});

	const menuOrderAndVisibility = [
		{ label: "Жилые комплексы", key: ROUTES.RESIDENTIAL_COMPLEX, icon: <Build />, type: "RESIDENTIAL_COMPLEX" },
		{ label: "Профиль УК", key: ROUTES.PROFILE_MO, icon: <Home />, type: "PROFILE_MO" },
		{ label: "Квитанции", key: ROUTES.PAYMENT, icon: <File />, type: "PAYMENT" },
		{ label: "Счетчики", key: ROUTES.METERING_DEVICE, icon: <Counter />, type: "METERING_DEVICE" },
		{ label: "Жители", key: ROUTES.RESIDENT, icon: <List />, type: "RESIDENT" },
		{ label: "Запросы на доступ", key: ROUTES.REQUEST_ACCESS, icon: <User />, type: "REQUEST_ACCESS" },
		{ label: "Чат с жителями", key: ROUTES.CHAT, icon: <Chat />, type: "CHAT" },
		{ label: "Обращения", key: ROUTES.APPEAL, icon: <BoldDocumentpen />, type: "APPEAL" },
		{ label: "Объявления", key: ROUTES.ANNOUNCEMENT, icon: <AnnouncemetPoint />, type: "ANNOUNCEMENT" },
		{ label: "Сотрудники", key: ROUTES.EMPLOYEE, icon: <Staff />, type: "EMPLOYEE" },
		{ label: "Опросы и голосования", key: ROUTES.VOTE, icon: <PollSvg />, type: "VOTE" },
		{ label: "Обновление данных", key: ROUTES.ARCUS, icon: <Update />, type: "ARCUS" },
	];

	const enabledModules = configUK.data?.modules || [];

	const visibleMenuItems = isVisibleFunctionality
		? menuOrderAndVisibility
		: menuOrderAndVisibility.filter((item) => enabledModules.some((module) => module.type === item.type && module.enabled));

	return { visibleMenuItems };
};

export { useMenuItems, type TMenuItem };
