import React, { FC, useEffect } from "react";

import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { FormItem, Select, Switch, Button, colors } from "tap2visit-ui-kit";

import { getBuildingsBaseApi } from "api/api.gateway";
import { getComplexApi, getEntranceApi, getFloorsApi, getRolesApi } from "api/api.realEstate";
import { IRole } from "interfaces/IRole";
import { QueriesKeys } from "interfaces/queriesKeys";
import { ValidationStatuses } from "modules/validation/Validation.store";
import { ReactComponent as EraserSvg } from "public/eraser.svg";

import useIsDisabledFilters from "./hooks/useIsDisabledFilters";
import useSelectBuildingData from "./hooks/useSelectBuildingData";
import useSelectStateIsOpen from "./hooks/useSelectStateIsOpen";
import TableFilterValidation from "./store/TableFilterValidation";
import { useTableFilterContext } from "./TableFilter.context";

interface ICitizensFilters {
	customSwitchOptions?: {
		label?: string;
		callback?: () => void;
		isActive?: boolean;
		switchLabel?: string;
		isVisibleSwitch?: boolean;
		isNotShowButton?: boolean;
		isRequired?: boolean;
		isFullWidth?: boolean;
		isNotLabel?: boolean;
		size?: "s" | "m" | "l";
	};
}

const TableFilter: FC<ICitizensFilters> = (props) => {
	const tableFilterStore = useTableFilterContext();
	const [isOpenComplexesSelect, toggleIsOpenComplexesSelect] = useSelectStateIsOpen();
	const [isOpenBuildingsSelect, toggleIsOpenBuildingsSelect] = useSelectStateIsOpen();
	const [isOpenEntrancesSelect, toggleIsOpenEntrancesSelect] = useSelectStateIsOpen();
	const [isOpenFloorsSelect, toggleIsOpenFloorsSelect] = useSelectStateIsOpen();
	const [isOpenBuildingObjectsSelect, toggleIsOpenBuildingObjectsSelect] = useSelectStateIsOpen();
	const location = useLocation();
	const validationFields = TableFilterValidation.validationData;
	const errorMessageSelectComplexes = TableFilterValidation.getMessageIfError("selectComplexes");
	const errorMessageSelectBuildings = TableFilterValidation.getMessageIfError("selectBuildings");
	const errorMessageSelectEntrances = TableFilterValidation.getMessageIfError("selectEntrances");
	const errorMessageSelectFloors = TableFilterValidation.getMessageIfError("selectFloors");
	const errorMessageSelectBuildingObjects = TableFilterValidation.getMessageIfError("selectBuildingObjects");

	useSelectBuildingData({ filterStore: tableFilterStore });
	const { isBuildingsSelectDisabled, isEntrancesSelectDisabled, isFloorsSelectDisabled, isBuildingObjectsDisabled } = useIsDisabledFilters({
		filterStore: tableFilterStore,
	});

	const complexes = useQuery({
		queryFn: () => getComplexApi(),
		queryKey: [QueriesKeys.complexes],
	});

	const buildings = useQuery({
		queryFn: () => getBuildingsBaseApi({ params: { complexIds: tableFilterStore.selectedComplexIds } }),
		queryKey: [QueriesKeys.buildingsBase, ...(tableFilterStore.selectedComplexIds ?? [])],
		enabled: !!tableFilterStore.selectedComplexIds.length,
	});

	const entrances = useQuery({
		queryFn: () => getEntranceApi({ params: { buildingIds: tableFilterStore.selectedBuildingsIds } }),
		queryKey: [QueriesKeys.entrances, ...(tableFilterStore.selectedBuildingsIds ?? [])],
		enabled: !!tableFilterStore.selectedBuildingsIds.length,
	});

	const floors = useQuery({
		queryFn: () => getFloorsApi({ params: { entranceIds: tableFilterStore.selectedEntrancesIds } }),
		queryKey: [QueriesKeys.floors, ...(tableFilterStore.selectedEntrancesIds ?? [])],
		enabled: !!tableFilterStore.selectedEntrancesIds.length,
	});

	const setInitRoles = (data: IRole[]) => {
		const ownerRoleID = { role: "OWNER", id: "" };
		const trustedPerson = { role: "TRUSTED_PERSON", id: "" };
		data.forEach((item) => {
			if (item.name === "OWNER") ownerRoleID.id = item.id;
			if (item.name === "TRUSTED_PERSON") trustedPerson.id = item.id;
		});

		tableFilterStore.setInitRole([ownerRoleID, trustedPerson], "roles");
	};

	const roles = useQuery({
		queryFn: () => getRolesApi(),
		queryKey: [QueriesKeys.roles],
		onSuccess: setInitRoles,
		// retryOnMount: true, // TODO workaround | bug with open announcements page (empty roles list)
		staleTime: Infinity,
	});

	React.useEffect(() => {
		if (roles.data) {
			setInitRoles(roles.data);
		}
	}, []);

	useEffect(() => {
		if (location.state?.selectedId) {
			tableFilterStore.toggleSingleItem(
				{
					key: location.state?.selectedId,
					focused: false,
					text: complexes.data?.find((v) => v.id === location.state.selectedId).name,
				},
				"complexes",
			);
		}
		if (location.state?.buildingId) {
			tableFilterStore.toggleSingleItem(
				{
					key: location.state?.buildingId,
					focused: false,
					text: buildings.data?.find((v) => v.id === location.state.buildingId).addressDto.value,
				},
				"buildings",
			);
		}
		if (location.state?.entranceId) {
			tableFilterStore.toggleSingleItem(
				{
					key: location.state?.entranceId,
					focused: false,
					text: String(entrances.data?.find((v) => v.id === location.state.entranceId).number),
				},
				"entrances",
			);
		}
		if (location.state?.floorId) {
			tableFilterStore.toggleSingleItem(
				{
					key: location.state?.floorId,
					focused: false,
					text: String(floors.data?.find((v) => v.id === location.state.floorId).number),
				},
				"floors",
			);
		}
	}, [location.state]);

	const filterRole = (state: boolean) => {
		if (!tableFilterStore.initRole.length) return;
		if (state) {
			const ownerRole = tableFilterStore.initRole.find((i) => i.role === "OWNER");
			tableFilterStore.setSelectItems(ownerRole ? [ownerRole.id] : [], "roles");
		} else {
			tableFilterStore.setSelectItems(
				tableFilterStore.initRole.map((i) => i.id),
				"roles",
			);
		}
	};

	return (
		<Container isFullWidth={props.customSwitchOptions?.isFullWidth} isNotLabel={props.customSwitchOptions?.isNotLabel}>
			<FormItem
				isRequiredStarLeft
				state={
					props.customSwitchOptions?.isFullWidth && validationFields.selectComplexes !== ValidationStatuses.VALID ? "error" : "default"
				}
				message={props.customSwitchOptions?.isFullWidth && errorMessageSelectComplexes}
				required={props.customSwitchOptions?.isRequired}
				label={props.customSwitchOptions?.isNotLabel ? "" : "Жилой комплекс"}>
				<Select
					isError={props.customSwitchOptions?.isFullWidth && validationFields.selectComplexes !== ValidationStatuses.VALID}
					onClickSelect={toggleIsOpenComplexesSelect}
					onClickSelectItem={(item) => {
						tableFilterStore.toggleSingleItem(item, "complexes");
						toggleIsOpenComplexesSelect();
					}}
					items={tableFilterStore.selectComplexes ?? []}
					isOpened={isOpenComplexesSelect}
					placeholder={props.customSwitchOptions?.isNotLabel ? "ЖК" : "Выберите"}
					onClose={toggleIsOpenComplexesSelect}
					style={{
						// eslint-disable-next-line no-nested-ternary
						width: props.customSwitchOptions?.isNotLabel ? "92px" : props.customSwitchOptions?.isFullWidth ? "320px" : "180px",
						background: props.customSwitchOptions?.isFullWidth ? colors.surfaceNeutralBgWhite : "unset",
					}}
					size={props.customSwitchOptions?.size || "s"}
				/>
			</FormItem>

			<FormItem
				isRequiredStarLeft
				required={props.customSwitchOptions?.isRequired}
				state={
					props.customSwitchOptions?.isFullWidth && validationFields.selectBuildings !== ValidationStatuses.VALID ? "error" : "default"
				}
				message={props.customSwitchOptions?.isFullWidth && errorMessageSelectBuildings}
				label={props.customSwitchOptions?.isNotLabel ? "" : "Дом"}>
				<Select
					isError={props.customSwitchOptions?.isFullWidth && validationFields.selectBuildings !== ValidationStatuses.VALID}
					onClickSelect={toggleIsOpenBuildingsSelect}
					onClickSelectItem={(item) => {
						tableFilterStore.toggleSingleItem(item, "buildings");
						toggleIsOpenBuildingsSelect();
					}}
					items={tableFilterStore.selectBuildings ?? []}
					isOpened={isOpenBuildingsSelect}
					placeholder={props.customSwitchOptions?.isNotLabel ? "Дом" : "Выберите"}
					onClose={toggleIsOpenBuildingsSelect}
					isDisabled={isBuildingsSelectDisabled}
					dropdownTextStyle={{
						whiteSpace: "nowrap",
					}}
					style={{
						// eslint-disable-next-line no-nested-ternary
						width: props.customSwitchOptions?.isNotLabel ? "92px" : props.customSwitchOptions?.isFullWidth ? "320px" : "180px",
						// eslint-disable-next-line no-nested-ternary
						background: isBuildingsSelectDisabled
							? colors.surfaceNeutralDisabled
							: props.customSwitchOptions?.isFullWidth
								? colors.surfaceNeutralBgWhite
								: "unset",
					}}
					size={props.customSwitchOptions?.size || "s"}
				/>
			</FormItem>

			<FormItem
				isRequiredStarLeft
				state={
					props.customSwitchOptions?.isFullWidth && validationFields.selectEntrances !== ValidationStatuses.VALID ? "error" : "default"
				}
				message={props.customSwitchOptions?.isFullWidth && errorMessageSelectEntrances}
				required={props.customSwitchOptions?.isRequired}
				label={props.customSwitchOptions?.isNotLabel ? "" : "Подъезд"}>
				<Select
					isError={props.customSwitchOptions?.isFullWidth && validationFields.selectEntrances !== ValidationStatuses.VALID}
					onClickSelect={toggleIsOpenEntrancesSelect}
					onClickSelectItem={(item) => {
						tableFilterStore.toggleSingleItem(item, "entrances");
						toggleIsOpenEntrancesSelect();
					}}
					items={tableFilterStore.selectEntrances ?? []}
					isOpened={isOpenEntrancesSelect}
					placeholder={props.customSwitchOptions?.isNotLabel ? "Подъезд" : "Выберите"}
					onClose={toggleIsOpenEntrancesSelect}
					isDisabled={isEntrancesSelectDisabled}
					style={{
						// eslint-disable-next-line no-nested-ternary
						width: props.customSwitchOptions?.isNotLabel ? "92px" : props.customSwitchOptions?.isFullWidth ? "140px" : "180px",
						// eslint-disable-next-line no-nested-ternary
						background: isEntrancesSelectDisabled
							? colors.surfaceNeutralDisabled
							: props.customSwitchOptions?.isFullWidth
								? colors.surfaceNeutralBgWhite
								: "unset",
					}}
					size={props.customSwitchOptions?.size || "s"}
				/>
			</FormItem>

			<FormItem
				state={props.customSwitchOptions?.isFullWidth && validationFields.selectFloors !== ValidationStatuses.VALID ? "error" : "default"}
				message={props.customSwitchOptions?.isFullWidth && errorMessageSelectFloors}
				isRequiredStarLeft
				required={props.customSwitchOptions?.isRequired}
				label={props.customSwitchOptions?.isNotLabel ? "" : "Этаж"}>
				<Select
					isError={props.customSwitchOptions?.isFullWidth && validationFields.selectFloors !== ValidationStatuses.VALID}
					onClickSelect={toggleIsOpenFloorsSelect}
					onClickSelectItem={(item) => {
						tableFilterStore.toggleSingleItem(item, "floors");
						toggleIsOpenFloorsSelect();
					}}
					items={tableFilterStore.selectFloors ?? []}
					isOpened={isOpenFloorsSelect}
					placeholder={props.customSwitchOptions?.isNotLabel ? "Этаж" : "Выберите"}
					onClose={toggleIsOpenFloorsSelect}
					isDisabled={isFloorsSelectDisabled}
					style={{
						// eslint-disable-next-line no-nested-ternary
						width: props.customSwitchOptions?.isNotLabel ? "92px" : props.customSwitchOptions?.isFullWidth ? "140px" : "180px",
						// eslint-disable-next-line no-nested-ternary
						background: isFloorsSelectDisabled
							? colors.surfaceNeutralDisabled
							: props.customSwitchOptions?.isFullWidth
								? colors.surfaceNeutralBgWhite
								: "unset",
					}}
					size={props.customSwitchOptions?.size || "s"}
				/>
			</FormItem>

			<FormItem
				isRequiredStarLeft
				state={
					props.customSwitchOptions?.isFullWidth && validationFields.selectBuildingObjects !== ValidationStatuses.VALID
						? "error"
						: "default"
				}
				message={props.customSwitchOptions?.isFullWidth && errorMessageSelectBuildingObjects}
				required={props.customSwitchOptions?.isRequired}
				label={props.customSwitchOptions?.isNotLabel ? "" : "Квартира"}>
				<Select
					isError={props.customSwitchOptions?.isFullWidth && validationFields.selectBuildingObjects !== ValidationStatuses.VALID}
					onClickSelect={toggleIsOpenBuildingObjectsSelect}
					onClickSelectItem={(item) => {
						tableFilterStore.toggleSingleItem(item, "buildingObjects");
						toggleIsOpenBuildingObjectsSelect();
					}}
					items={tableFilterStore.selectBuildingObjects ?? []}
					isOpened={isOpenBuildingObjectsSelect}
					placeholder={props.customSwitchOptions?.isNotLabel ? "Квартира" : "Выберите"}
					onClose={toggleIsOpenBuildingObjectsSelect}
					isDisabled={isBuildingObjectsDisabled}
					style={{
						// eslint-disable-next-line no-nested-ternary
						width: props.customSwitchOptions?.isNotLabel ? "92px" : props.customSwitchOptions?.isFullWidth ? "140px" : "180px",
						// eslint-disable-next-line no-nested-ternary
						background: isBuildingObjectsDisabled
							? colors.surfaceNeutralDisabled
							: props.customSwitchOptions?.isFullWidth
								? colors.surfaceNeutralBgWhite
								: "unset",
					}}
					size={props.customSwitchOptions?.size || "s"}
				/>
			</FormItem>

			{props.customSwitchOptions && props.customSwitchOptions?.isVisibleSwitch && (
				<FormItem isRequiredStarLeft required={props.customSwitchOptions?.isRequired} label={props.customSwitchOptions.label}>
					<Switch
						label={props.customSwitchOptions.switchLabel}
						labelPosition="right"
						size={"medium"}
						isActive={props.customSwitchOptions.isActive}
						onChangeActive={props.customSwitchOptions.callback}
						style={{
							gap: 0,
						}}
					/>
				</FormItem>
			)}
			{!props.customSwitchOptions && (
				<AlignToCenterSwitch>
					<Switch
						label="Показать только собственников"
						labelPosition="right"
						size="medium"
						isActive={tableFilterStore.selectRoles.length === 1}
						onChangeActive={filterRole}
						style={{
							gap: 0,
						}}
					/>
				</AlignToCenterSwitch>
			)}
			{!props.customSwitchOptions?.isNotShowButton && (
				<AlignToCenterButton>
					<Button onClick={tableFilterStore.clearFilter} typeButton="secondary" icon={EraserSvg} size="small">
						Сбросить
					</Button>
				</AlignToCenterButton>
			)}
		</Container>
	);
};

export default observer(TableFilter);

const Container = styled.div<{ isNotLabel?: boolean; isFullWidth?: boolean }>`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: ${({ isNotLabel }) => (isNotLabel ? "10px" : "16px")};
	flex-direction: ${({ isFullWidth }) => (isFullWidth ? "column" : "row")};
	align-items: ${({ isFullWidth }) => (isFullWidth ? "start" : "center")};
`;

const AlignToCenterSwitch = styled.div`
	width: 211px;
	transform: translateY(50%);
`;

const AlignToCenterButton = styled.div`
	transform: translateY(38%);
	button {
		padding-left: 2px;
	}
`;
