import dayjs from "dayjs";

import CitizenBuildingObjectStore from "components/Citizens/store/CitizenBuildingObject.store";
import { getBuildingObjectType, getTextLivingArea } from "utils/getBuildingObjectType";

export const getBuildingTypeConfigurations = () => ({
	flat: [
		{
			label: "Тип помещения",
			as: "input",
			value: getTextLivingArea(CitizenBuildingObjectStore.selectedBuilding?.isLivingSpace) || "-",
			disabled: true,
		},
		{
			label: "Тип недвижимости",
			as: "input",
			value: getBuildingObjectType(CitizenBuildingObjectStore.selectedBuilding?.objectType) || "-",
			disabled: true,
		},
		{
			label: "Адрес помещения",
			as: "textarea",
			value: CitizenBuildingObjectStore.selectedBuildingAddress || "-",
			disabled: true,
		},
		{
			label: "Подъезд",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.entranceNumber || "-",
			disabled: true,
		},
		{
			label: "Этаж",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.floorNumber || "-",
			disabled: true,
		},
		{
			label: "Номер помещения",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.number || "-",
			disabled: true,
		},
		{
			label: "Количество комнат",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.countOfRooms || "-",
			disabled: true,
		},
		{
			label: "Дата выдачи тех.паспорта",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.dateIssueTechnicalPassport
				? dayjs(CitizenBuildingObjectStore.selectedBuilding.dateIssueTechnicalPassport).format("DD.MM.YYYY")
				: "-",
			disabled: true,
		},
		{
			label: "Орган выдавший тех.паспорт",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.authorityIssuedTechnicalPassport
				? CitizenBuildingObjectStore.selectedBuilding?.authorityIssuedTechnicalPassport
				: "-",
			disabled: true,
		},
		{
			label: "Общая площадь",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.totalArea || "-",
			disabled: true,
		},
		{
			label: "Жилая площадь/площадь БТИ, м²",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.livingArea || "-",
			disabled: true,
		},
	],
	storage: [
		{
			label: "Тип недвижимости",
			as: "input",
			value: getBuildingObjectType(CitizenBuildingObjectStore.selectedBuilding?.objectType) || "-",
			disabled: true,
		},
		{
			label: "Тип помещения",
			as: "input",
			value: getTextLivingArea(CitizenBuildingObjectStore.selectedBuilding?.isLivingSpace) || "-",
			disabled: true,
		},
		{
			label: "Адрес помещения",
			as: "textarea",
			value: CitizenBuildingObjectStore.selectedBuildingAddress || "-",
			disabled: true,
		},
		{
			label: "Подъезд",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.entranceNumber || "-",
			disabled: true,
		},
		{
			label: "Этаж",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.floorNumber || "-",
			disabled: true,
		},
		{
			label: "Номер помещения",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.number || "-",
			disabled: true,
		},
		{
			label: "Дата выдачи тех.паспорта",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.dateIssueTechnicalPassport
				? dayjs(CitizenBuildingObjectStore.selectedBuilding.dateIssueTechnicalPassport).format("DD.MM.YYYY")
				: "-",
			disabled: true,
		},
		{
			label: "Орган выдавший тех.паспорт",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.authorityIssuedTechnicalPassport
				? CitizenBuildingObjectStore.selectedBuilding?.authorityIssuedTechnicalPassport
				: "-",
			disabled: true,
		},
		{
			label: "Общая площадь",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.totalArea || "-",
			disabled: true,
		},
	],
	parking: [
		{
			label: "Тип недвижимости",
			as: "input",
			value: getBuildingObjectType(CitizenBuildingObjectStore.selectedBuilding?.objectType) || "-",
			disabled: true,
		},
		{
			label: "Тип помещения",
			as: "input",
			value: getTextLivingArea(CitizenBuildingObjectStore.selectedBuilding?.isLivingSpace) || "-",
			disabled: true,
		},
		{
			label: "Адрес помещения",
			as: "textarea",
			value: CitizenBuildingObjectStore.selectedBuildingAddress || "-",
			disabled: true,
		},
		{
			label: "Подъезд",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.entranceNumber || "-",
			disabled: true,
		},
		{
			label: "Этаж",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.floorNumber || "-",
			disabled: true,
		},
		{
			label: "Номер помещения",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.number || "-",
			disabled: true,
		},
		{
			label: "Дата выдачи тех.паспорта",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.dateIssueTechnicalPassport
				? dayjs(CitizenBuildingObjectStore.selectedBuilding.dateIssueTechnicalPassport).format("DD.MM.YYYY")
				: "-",
			disabled: true,
		},
		{
			label: "Орган выдавший тех.паспорт",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.authorityIssuedTechnicalPassport
				? CitizenBuildingObjectStore.selectedBuilding?.authorityIssuedTechnicalPassport
				: "-",
			disabled: true,
		},
		{
			label: "Общая площадь",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.totalArea || "-",
			disabled: true,
		},
	],
	basement: [
		{
			label: "Тип недвижимости",
			as: "input",
			value: getBuildingObjectType(CitizenBuildingObjectStore.selectedBuilding?.objectType) || "-",
			disabled: true,
		},
		{
			label: "Тип помещения",
			as: "input",
			value: getTextLivingArea(CitizenBuildingObjectStore.selectedBuilding?.isLivingSpace) || "-",
			disabled: true,
		},
		{
			label: "Адрес помещения",
			as: "textarea",
			value: CitizenBuildingObjectStore.selectedBuildingAddress || "-",
			disabled: true,
		},
		{
			label: "Подъезд",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.entranceNumber || "-",
			disabled: true,
		},
		{
			label: "Этаж",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.floorNumber || "-",
			disabled: true,
		},
		{
			label: "Номер помещения",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.number || "-",
			disabled: true,
		},
		{
			label: "Дата выдачи тех.паспорта",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.dateIssueTechnicalPassport
				? dayjs(CitizenBuildingObjectStore.selectedBuilding.dateIssueTechnicalPassport).format("DD.MM.YYYY")
				: "-",
			disabled: true,
		},
		{
			label: "Орган выдавший тех.паспорт",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.authorityIssuedTechnicalPassport
				? CitizenBuildingObjectStore.selectedBuilding?.authorityIssuedTechnicalPassport
				: "-",
			disabled: true,
		},
		{
			label: "Общая площадь",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.totalArea || "-",
			disabled: true,
		},
	],
	office: [
		{
			label: "Тип недвижимости",
			as: "input",
			value: getBuildingObjectType(CitizenBuildingObjectStore.selectedBuilding?.objectType) || "-",
			disabled: true,
		},
		{
			label: "Тип помещения",
			as: "input",
			value: getTextLivingArea(CitizenBuildingObjectStore.selectedBuilding?.isLivingSpace) || "-",
			disabled: true,
		},
		{
			label: "Адрес помещения",
			as: "textarea",
			value: CitizenBuildingObjectStore.selectedBuildingAddress || "-",
			disabled: true,
		},
		{
			label: "Подъезд",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.entranceNumber || "-",
			disabled: true,
		},
		{
			label: "Этаж",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.floorNumber || "-",
			disabled: true,
		},
		{
			label: "Номер помещения",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.number || "-",
			disabled: true,
		},
		{
			label: "Дата выдачи тех.паспорта",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.dateIssueTechnicalPassport
				? dayjs(CitizenBuildingObjectStore.selectedBuilding.dateIssueTechnicalPassport).format("DD.MM.YYYY")
				: "-",
			disabled: true,
		},
		{
			label: "Орган выдавший тех.паспорт",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.authorityIssuedTechnicalPassport
				? CitizenBuildingObjectStore.selectedBuilding?.authorityIssuedTechnicalPassport
				: "-",
			disabled: true,
		},
		{
			label: "Общая площадь",
			as: "input",
			value: CitizenBuildingObjectStore.selectedBuilding?.totalArea || "-",
			disabled: true,
		},
	],
});
