import React, { FC } from "react";

import dayjs from "dayjs";
import { DatePicker as T2VDatePicker } from "tap2visit-ui-kit";

interface IDatePicker {
	currentDate: dayjs.Dayjs;
	onChangeDate: (dates: Date[]) => void;
	minDate?: dayjs.Dayjs;
	width?: string;
	placeholder?: string;
	isError?: boolean;
	disabledDates?: { from?: Date; to?: Date };
	onSave?: (datesLocal?: Date | null) => void;
	disabled?: boolean;
	onClickContainer?: () => void;
}

const DatePicker: FC<IDatePicker> = (props) => {
	const [isOpenPicker, setIsOpenPicker] = React.useState(false);

	return (
		<div style={{ position: "relative" }}>
			<T2VDatePicker
				onClickContainer={() => props.onClickContainer?.()}
				disabled={props.disabled}
				disabledDates={props.disabledDates}
				state={props.isError ? "error" : "default"}
				setOpen={setIsOpenPicker}
				isOpen={isOpenPicker}
				sizeInput={"medium"}
				dates={props.currentDate ? [props.currentDate.toDate()] : []}
				setDates={(v: any) => {
					props.onChangeDate(v);
				}}
				onSave={props.onSave}
				isSingleDate={true}
				isMonthRange={false}
				isSingleMonth={false}
				isSingleYear={false}
				isDateRange={false}
				iconPosition="right"
			/>
		</div>
	);
};

export default DatePicker;
