// ! temporary

enum Permissions {
	ADMIN = "ADMINISTRATOR",
	EMPLOYEE = "EMPLOYEE",
	MASTER = "MASTER",
}

const roles = [
	{
		text: "Главный администратор",
		key: Permissions.ADMIN,
	},
	{
		text: "Сотрудник",
		key: Permissions.EMPLOYEE,
	},
	{
		text: "Мастер",
		key: Permissions.MASTER,
	},
];

export { Permissions, roles };
