import React, { FC } from "react";

import { Divider } from "components/DrawerFormFields/DrawerFormFields";
import { Spacer } from "components/layout/Spacer";
import { IQuestionDto, TQuestionType } from "interfaces/IVote";
import { observer } from "mobx-react-lite";
import PollsEditValidationStore from "pages/polls/store/PollsEdit.validation.store";
import PollsEditStore from "pages/polls/store/PollsItem.store";
import { ReactComponent as CopySvg } from "public/copy.svg";
import { ReactComponent as DraggableMenu } from "public/draggable_menu.svg";
import { ReactComponent as TrashSvg } from "public/greyTrash.svg";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { Button, Input, Select, TContextMenuItem, Text, colors, showToast } from "tap2visit-ui-kit";

import useSelectItems from "./hooks/useSelectItems";
import QuestionItemOption from "./QuestionItem.option";

interface IQuestionItemEditable {
	question: IQuestionDto;
	index: number;
}

const QuestionItemEditable: FC<IQuestionItemEditable> = (props) => {
	const [isOpenSelectPollType, setIsOpenSelectPollType] = React.useState(false);

	const { selectItems, PollTypeIcon } = useSelectItems({ question: props.question });

	const isShowAddFreeAnswerButton = props.question.answerOptions.every((option) => !option.isFree) && PollsEditStore.poll.type === "POLL";

	const onSelectItem = (item: TContextMenuItem) => {
		PollsEditStore.changePollQuestion({
			question: {
				id: props.question.id,
				type: item.key as TQuestionType,
			},
		});
		setIsOpenSelectPollType(false);
	};

	const onRemoveQuestion = () => {
		if (PollsEditStore.poll.questions.length === 1) {
			showToast({ type: "danger", description: "Нельзя удалить последний опрос/голосование" });
			return;
		}

		PollsEditStore.removePollQuestion({
			question: {
				id: props.question.id,
			},
		});
	};

	React.useEffect(() => {
		if (props.question.type === "SINGLE_CHOICE" && props.question.answerOptions.length < 2) {
			for (let i = props.question.answerOptions.length; i < 2; i++) {
				onAddNewOption();
			}
		}

		if (props.question.type === "MULTIPLE_CHOICE" && props.question.answerOptions.length < 3) {
			for (let i = props.question.answerOptions.length; i < 3; i++) {
				onAddNewOption();
			}
		}
	}, [props.question.type]);

	const onAddNewOption = () => {
		PollsEditStore.addPollQuestionOption({ question: { id: props.question.id } });
	};

	const onChangeOption = (args: { optionIndex: number; value: string }) => {
		PollsEditStore.changeQuestionOption({
			question: { id: props.question.id },
			option: { index: args.optionIndex, value: args.value },
		});
	};

	const onChangeQuestion = (args: { value: string }) => {
		PollsEditStore.changePollQuestion({
			question: { id: props.question.id, value: args.value },
		});
	};

	const toggleFreeAnswer = () => {
		PollsEditStore.toggleQuestionOptionFreeAnswer({
			question: { id: props.question.id },
		});
	};

	const onRemoveOption = (args: { optionIndex: number }) => {
		if (props.question.type === "SINGLE_CHOICE" && props.question.answerOptions.length === 2) {
			showToast({
				type: "info",
				description: "Количество вариантов ответа не может быть меньше 2-x",
			});
			return;
		}

		if (props.question.type === "MULTIPLE_CHOICE" && props.question.answerOptions.length === 3) {
			showToast({
				type: "info",
				description: "Количество вариантов ответа не может быть меньше 3-x",
			});
			return;
		}

		PollsEditStore.removeQuestionOption({
			question: { id: props.question.id },
			option: { index: args.optionIndex },
		});
	};

	const onEndDragQuestions = (endDragResult: DropResult) => {
		PollsEditStore.reorderQuestionOptions({
			endDragResult: endDragResult,
			question: { id: props.question.id },
		});
	};

	const onCopyPoll = () => {
		PollsEditStore.copyQuestion({ question: { id: props.question.id } });
	};

	const freeOption = props.question.answerOptions.find((option) => option.isFree);
	const options = props.question.answerOptions.filter((option) => !option.isFree);
	const isValidQuestionName = !PollsEditValidationStore.notValidQuestionNameIds.includes(props.question.id);

	const onChangeQuestionName = (e: React.ChangeEvent<HTMLInputElement>) => {
		PollsEditValidationStore.validQuestionName(props.question.id);
		onChangeQuestion({ value: e.target.value });
	};

	return (
		<Draggable draggableId={props.question.id} index={props.index}>
			{(provided, snapshot) => (
				<div ref={provided.innerRef} {...provided.draggableProps}>
					<OuterContainer>
						<Container>
							<MenuIconContainer {...provided.dragHandleProps}>
								<DraggableMenu />
							</MenuIconContainer>
							<Row id="row">
								<CustomInput
									placeholder="Вопрос"
									value={props.question.value}
									onChange={onChangeQuestionName}
									sizeInput={"medium"}
									contentContainerStyle={{ width: "100%" }}
									state={isValidQuestionName ? "default" : "error"}
								/>
								<Select
									items={selectItems}
									isOpened={isOpenSelectPollType}
									onClickSelect={() => setIsOpenSelectPollType(true)}
									onClickSelectItem={onSelectItem}
									onClose={() => setIsOpenSelectPollType(false)}
									style={{ width: "245px" }}
									selectItemsListWidth="300px"
								/>
							</Row>

							<Spacer px={20} />
							{props.question.type === "FREE_CHOICE" && (
								<Text type="base-regular" color={colors.textNeutralDisabled}>
									Ответ пользователя в свободной форме
								</Text>
							)}
							{props.question.type !== "FREE_CHOICE" && (
								<>
									<DragDropContext onDragEnd={onEndDragQuestions}>
										<Droppable droppableId="poll_options" direction="vertical">
											{(provided) => (
												<div ref={provided.innerRef} {...provided.droppableProps}>
													{options.map((option, idx) => (
														<QuestionItemOption
															key={option.id}
															icon={PollTypeIcon}
															option={option}
															index={idx}
															onChangeOption={(value) => onChangeOption({ value, optionIndex: idx })}
															onRemoveOption={() => onRemoveOption({ optionIndex: idx })}
															placeholder={`Вариант ${idx + 1}`}
															editable={true}
														/>
													))}
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</DragDropContext>

									{freeOption && (
										<>
											<QuestionItemOption
												icon={PollTypeIcon}
												option={freeOption}
												onRemoveOption={() => onRemoveOption({ optionIndex: props.question.answerOptions.length - 1 })}
												placeholder={"Свой ответ..."}
												isFreeAnswer={true}
											/>
										</>
									)}

									<Spacer px={8} />
									<ButtonsContainer>
										<Button onClick={onAddNewOption} typeButton="secondary">
											Добавить вариант
										</Button>
										{isShowAddFreeAnswerButton && (
											<Button onClick={toggleFreeAnswer} typeButton="secondary">
												Добавить «Свой ответ»
											</Button>
										)}
									</ButtonsContainer>
								</>
							)}
						</Container>

						<Spacer px={8} />
						<Divider />
						<BottomButtonsContainer>
							<Button onClick={onCopyPoll} icon={CopySvg} size="large" shape="square" typeButton="text" />
							<Button onClick={onRemoveQuestion} icon={() => <TrashSvg fill="yellow" />} size="large" shape="square" typeButton="text" />
						</BottomButtonsContainer>
					</OuterContainer>
				</div>
			)}
		</Draggable>
	);
};

export default observer(QuestionItemEditable);

const Row = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	gap: 16px;
`;

const CustomInput = styled(Input)`
	width: 100%;
	flex-grow: 1;
`;

const ButtonsContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const Container = styled.div`
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 32px;
`;

const OuterContainer = styled.div`
	border: 0.5px solid ${colors.strokeLine};
	border-radius: 12px;
	width: 100%;

	position: relative;

	background-color: ${colors.surfaceNeutralBgWhite};
`;

const BottomButtonsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 10px 10px;
	gap: 10px;
`;

const MenuIconContainer = styled.div`
	position: absolute;
	top: 0;
	left: 50%;
`;
