import styled from "styled-components";

import { CustomDivider } from "pages/accessRequest/components/AccessRequestDrawer/AccessRequestDrawer.styled";

export const TabsWrapper = styled.div`
	padding: 16px 16px 0 16px;
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const MainWrapper = styled.div`
	width: 100%;
`;

export const MainPaddingContent = styled.div`
	padding: 16px 16px 0 16px;
`;

const ItemWrapper = styled.div`
	margin-top: 24px;
	display: flex;
	gap: 16px;
`;

const Item = styled.div`
	background-color: #fff;
	border-radius: 16px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	flex: 1 1 auto;
	padding: 16px;
`;

const OrderedListItem = styled.li<{ isNumbered?: boolean }>`
	position: relative;
	padding-left: 20px;
	margin: 8px 0;

	&::before {
		content: ${(props) => (props.isNumbered ? "counter(custom-counter)" : "'•'")};
		counter-increment: ${(props) => (props.isNumbered ? "custom-counter" : "none")};
		position: absolute;
		left: 0;
		color: ${(props) => (props.isNumbered ? "#000" : "#cccс")};
		font-weight: ${(props) => (props.isNumbered ? "bold" : "normal")};
	}
`;

const CustomList = styled.ul`
	padding: 0;
	margin: 0;
	list-style: none;
	counter-reset: custom-counter;
`;

const FormListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const ConfirmButtonWrapper = styled.div`
	align-self: flex-end;
`;

const StatusContainer = styled.div`
	display: flex;
	gap: 4px;
	align-items: start;
	flex-direction: column;
	margin: 4px 0 12px 0;
`;

const ErrorContainer = styled.div`
	display: flex;
	align-items: start;
	justify-content: start;
	flex-direction: column;
	margin: 12px 0;
`;

const HeaderError = styled.div`
	display: flex;
	align-items: center;
	justify-content: start;
	gap: 8px;
`;

const Description = styled.div`
	margin-top: 8px;
`;

const ErrorAttention = styled.div`
	display: flex;
	align-items: center;
	justify-content: start;
	padding: 16px;
	margin-top: 5px;
	background-color: rgba(202, 80, 74, 0.1);
`;

const Divider = styled(CustomDivider)`
	margin: 0;
`;

export {
	Item,
	ItemWrapper,
	OrderedListItem,
	FormListWrapper,
	ConfirmButtonWrapper,
	CustomList,
	StatusContainer,
	ErrorContainer,
	HeaderError,
	Description,
	ErrorAttention,
	Divider,
};
