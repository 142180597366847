import React from "react";
import styled from "styled-components";
import { Loader, Text } from "tap2visit-ui-kit";

const LoadingTemplate: React.FC = () => (
	<Wrapper>
		<LoaderWrapper>
			<Loader width="43px" height="43px" />
		</LoaderWrapper>
		<TextWrapper>
			<Text type="large-bold" h="h4">
				Шаблон загружается
			</Text>
			<Text color="rgba(18, 18, 18, 0.6)" type="large-regular">
				Пожалуйста подождите
			</Text>
		</TextWrapper>
	</Wrapper>
);

export default LoadingTemplate;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 24px;
	text-align: center;
`;

const LoaderWrapper = styled.div`
	margin-bottom: 16px;
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
`;
