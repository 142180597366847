import { makeAutoObservable } from "mobx";

class ModalConfirmEditCitizenStore {
	isVisible = false;
	clickedUserId: string = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	visible = (id: string) => {
		this.isVisible = true;
		this.clickedUserId = id;
	};

	close = () => {
		this.clickedUserId = undefined;
		this.isVisible = false;
	};
}

export default new ModalConfirmEditCitizenStore();
