import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { useMutation, useQuery } from "react-query";
import { Button, Chip, colors, FormItem, Space, Text, TextArea } from "tap2visit-ui-kit";

import { changeStatusAppealApi, getAppealsListByIds } from "api/api.gateway";
import BaseModal from "components/Modals/BaseModal";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import { ValidationStatuses } from "modules/validation/Validation.store";
import AppealsCloseStore from "pages/appeals/store/AppealsClose.store";
import AppealsCloseValidation from "pages/appeals/store/AppealsCloseValidation";
import DrawerStore from "pages/appeals/store/Drawer.store";

import AppealCloseApplicant from "./AppealCloseApplicant";
import AppealCloseChild from "./AppealCloseChild";

const AppealCloseDrawer = () => {
	const { login, emails } = DrawerStore.selectedAppeals.applicant;

	const [notification, setNotification] = useState("");
	const [selectedEmail, setSelectedEmail] = useState(true);
	const validationFields = AppealsCloseValidation.validationData;
	const errorMessageNotification = AppealsCloseValidation.getMessageIfError("notification");
	const isHaveParentAppeal = DrawerStore.selectedAppeals.parentAppealId;

	const changeStatusAppeal = useMutation(changeStatusAppealApi);

	const childAppeals = useQuery({
		queryFn: () =>
			getAppealsListByIds(
				isHaveParentAppeal ? DrawerStore.selectedAppeals.parentAppealId : DrawerStore.selectedAppeals.childAppealIds.join(","),
			),
		queryKey: [QueriesKeys.appealsListByIds, DrawerStore.selectedAppeals.childAppealIds, DrawerStore.selectedAppeals.parentAppealId],
		enabled: isHaveParentAppeal ? !!DrawerStore.selectedAppeals.parentAppealId : !!DrawerStore.selectedAppeals.childAppealIds?.length,
	});

	const closeApplicant = () => {
		const isValidTitle = AppealsCloseValidation.checkValidation({
			notification: notification,
		});

		if (!isValidTitle) return;

		changeStatusAppeal
			.mutateAsync({
				args: {
					status: "COMPLETED",
					notification,
					sendEmailNotification: selectedEmail,
				},
				id: DrawerStore.selectedAppeals.id,
			})
			.then((res) => {
				queryClient.invalidateQueries([QueriesKeys.appealsList]);
				DrawerStore.setSelectedAppeals(res);
				AppealsCloseStore.setIsOpenDrawer(false);
			});
	};

	const isHaveLogin = !!login || childAppeals.data?.filter((childAppeal) => childAppeal.applicant.login)?.length > 0;
	const isHaveEmail = emails?.length > 0 || childAppeals.data?.filter((childAppeal) => childAppeal.applicant.emails)?.length > 0;

	useEffect(() => {
		if (isHaveEmail) return setSelectedEmail(true);
		if (!isHaveEmail) return setSelectedEmail(false);
	}, [isHaveEmail]);

	const changeSelectedEmail = () => {
		if (isHaveEmail && !isHaveLogin) return;
		if (!isHaveEmail) return;
		setSelectedEmail(!selectedEmail);
	};

	return (
		<BaseModal
			onCloseButtonClick={() => AppealsCloseStore.setIsOpenDrawer(false)}
			onExit={() => AppealsCloseStore.setIsOpenDrawer(false)}
			onCancel={() => AppealsCloseStore.setIsOpenDrawer(false)}
			title="Закрытие обращения"
			contentContainerStyle={{ maxHeight: "80vh", overflow: "auto" }}
			isVisible={AppealsCloseStore.isOpenDrawer}
			footerSlot={
				<Space size={8} width="100%" justify="flex-end">
					<Button
						onClick={() => {
							AppealsCloseStore.setIsOpenDrawer(false);
						}}
						typeButton="secondary">
						Отменить
					</Button>
					<Button onClick={closeApplicant}>Отправить</Button>
				</Space>
			}>
			<>
				<FormItem
					state={validationFields.notification !== ValidationStatuses.VALID ? "error" : "default"}
					message={errorMessageNotification}
					required
					isRequiredStarLeft
					label="Уведомление">
					<TextArea
						state={validationFields.notification !== ValidationStatuses.VALID ? "error" : "default"}
						width={510}
						value={notification}
						onChangeValue={setNotification}
					/>
				</FormItem>
				<Text style={{ marginTop: "16px", marginBottom: "6px" }} type="table-cell-m-regular" color={colors.textNeutralSecondary}>
					Отправить с помощью
				</Text>
				<Space>
					<Chip selected={selectedEmail} onClick={changeSelectedEmail} title="Эл. почта" size="m" />
					<Chip selected={isHaveLogin} title="Моб. приложение" size="m" />
				</Space>
				<Text style={{ marginTop: "25px", marginBottom: "6px" }} h="h5">
					Заявитель
				</Text>
				<AppealCloseApplicant applicant={DrawerStore.selectedAppeals.applicant} />
				{childAppeals?.data?.length > 0 && (
					<>
						<Text style={{ marginTop: "25px", marginBottom: "6px" }} h="h5">
							{isHaveParentAppeal ? "Обращение связано" : "Связанные обращения по заявителям"}
						</Text>
						{childAppeals?.data?.map((v) => <AppealCloseChild value={v} />)}
					</>
				)}
			</>
		</BaseModal>
	);
};

export default observer(AppealCloseDrawer);
