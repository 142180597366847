import React, { FC, useEffect } from "react";

import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, showToast } from "tap2visit-ui-kit";

import { Spacer } from "components/layout/Spacer";
import { ReactComponent as ArrowLeftSvg } from "public/arrow_left.svg";
import { ROUTES } from "routes/constants/routes.constants";
import { DEFAULT_PAGE_HEADER_PADDING } from "routes/withSideBar";

import useSavePoll from "../hooks/useSavePoll";
import PollsEditStore from "../store/PollsItem.store";
import PollsItemStore from "../store/PollsItem.store";
import PollsEditTabsStore, { TPollsEditTabsVariants } from "../store/PollsItem.tabs.store";
import PollsSendModalStore from "../store/PollsSendModal.store";

interface IPollsHeader {}

const tabs: { key: TPollsEditTabsVariants; label: string }[] = [
	{
		key: "questions",
		label: "Вопросы",
	},
	{
		key: "answers",
		label: "Ответы",
	},
];

const PollsHeader: FC<IPollsHeader> = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const isTemplate = PollsItemStore.poll?.isTemplate;
	const savePoll = useSavePoll();
	const { name, questions, type } = PollsEditStore.poll;

	React.useEffect(() => {
		const topHeaderContainer = document.getElementById("top-page-header");
		if (topHeaderContainer) {
			topHeaderContainer.style.padding = "14px 16px 0 16px";
		}

		if (location.pathname.startsWith(`/${ROUTES.VOTE}/${ROUTES.CREATE_TEMPLATE}`)) {
			PollsItemStore.poll.isTemplate = true;
		} else {
			PollsItemStore.poll.isTemplate = false;
		}

		return () => {
			if (topHeaderContainer) {
				topHeaderContainer.style.padding = DEFAULT_PAGE_HEADER_PADDING;
			}
		};
	}, []);

	const onSend = (isTemplate?: boolean) => {
		const entityName = type === "POLL" ? "опроса" : "голосования";

		if (!name || name.trim() === "") {
			showToast({
				description: `Название ${entityName} не может быть пустым`,
				type: "danger",
			});
			return;
		}

		if (name.length > 255) {
			showToast({
				description: `Превышен лимит текста в названии ${entityName}`,
				type: "danger",
			});
			return;
		}

		for (const question of questions) {
			if (question.type === "FREE_CHOICE" && question.isFree) {
				continue;
			}

			if (!question.value || question.value.trim() === "") {
				showToast({
					description: "Текст вопроса не может быть пустым",
					type: "danger",
				});
				return;
			}
			if (question.value.length > 255) {
				showToast({
					description: "Превышен лимит текста в названии вопроса",
					type: "danger",
				});
				return;
			}

			for (const option of question.answerOptions) {
				if (option.isFree) {
					continue;
				}

				if (!option.value || option.value.trim() === "") {
					showToast({
						description: "Текст ответа не может быть пустым",
						type: "danger",
					});
					return;
				}

				if (option.value.length > 255) {
					showToast({
						description: "Превышен лимит текста в названии ответа",
						type: "danger",
					});
					return;
				}
			}
		}

		PollsSendModalStore.changeStatusModal(true, isTemplate);
	};

	const isVisibleSaveButtons = location.pathname.startsWith("/polls/edit") || location.pathname.startsWith("/polls/create");

	const patchTemplate = () => {
		savePoll({
			realEstateIds: {
				complexIds: PollsItemStore.poll.complexIds,
				buildingIds: PollsItemStore.poll.buildingIds,
				entranceIds: PollsItemStore.poll.entranceIds,
				floorIds: PollsItemStore.poll.floorIds,
				buildingObjectIds: PollsItemStore.poll.buildingObjectIds,
			},
		});
	};

	useEffect(
		() => () => {
			PollsEditStore.dispose();
			PollsEditTabsStore.setActiveTab("questions");
		},
		[],
	);

	return (
		<Container>
			<HeaderTopContainer>
				<BackLink onClick={() => navigate("/polls", { replace: true })}>
					<Button shape="square" size="medium" icon={ArrowLeftSvg} typeButton="text" />
					<HeaderTextContainer>
						<HeaderText>{PollsEditStore.poll?.name}</HeaderText>
					</HeaderTextContainer>
				</BackLink>

				{isVisibleSaveButtons && !isTemplate && (
					<GroupButtons>
						<Button onClick={() => onSend(true)} typeButton="secondary">
							Сохранить как шаблон
						</Button>
						<Button onClick={() => onSend()}>Отправить</Button>
					</GroupButtons>
				)}

				{isVisibleSaveButtons && isTemplate && (
					<GroupButtons>
						<Button onClick={() => onSend(true)}>Сохранить шаблон</Button>
					</GroupButtons>
				)}
			</HeaderTopContainer>

			<Spacer px={16} />
		</Container>
	);
};

export default observer(PollsHeader);

const HeaderTopContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const GroupButtons = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	flex-shrink: 0;
`;

const BackLink = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	min-width: 0;
`;

const TabsContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const TabsWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const HeaderTextContainer = styled.div`
	flex-grow: 1;
	min-width: 0;
`;

const HeaderText = styled.h4`
	white-space: normal;
	word-wrap: break-word;
	text-overflow: ellipsis;
`;
