import React, { useEffect, useMemo, useRef, useState } from "react";

import { Client } from "@stomp/stompjs";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
	BoldAttach,
	BoldDelete,
	BoldEdit,
	BoldInfomenu2,
	BoldReplyShareLeft1,
	OutlineArrowDown2,
	OutlineArrowUp2,
	OutlineCloseremove,
	OutlineImage2,
	OutlineSearch3,
} from "t2v-icons";
import { Button, colors, DropDown, getUid, Loader, Search, showToast, Text, TextArea, Upload } from "tap2visit-ui-kit";

import { changeMessagePatch, createMessage, deleteMessage, getMessagesList } from "api/api.chats";
import { getListChat } from "api/api.gateway";
import { postFileUploadApi } from "api/api.storage";
import BaseModal from "components/Modals/BaseModal";
import { queryClient } from "index";
import { IMessageArray, IMessageContent, IPostMessage } from "interfaces/IChat";
import { QueriesKeys } from "interfaces/queriesKeys";
import AuthStore from "store/Auth.Store";

import AvatarChat from "./AvatarChat";
import ChatFileDrawer from "./ChatFileDrawer";
import ChatFileInformation from "./ChatFileInformation";
import ChatReplayFotterInformation from "./ChatReplayFotterInformation";

const DROPDOWN_BUTTON_ID = getUid("dropdown_chat_information");

const ChatInformation = observer(() => {
	const { id } = useParams<{ id: string }>();

	const itemsDropDown = [
		{
			meta: {
				id: "reply",
			},
			text: (
				<TextStyled type="base-medium">
					<BoldReplyShareLeft1 fill="black" />
					Ответить
				</TextStyled>
			),
		},
		{
			meta: {
				id: "edit",
			},
			text: (
				<TextStyled type="base-medium">
					<BoldEdit fill="black" />
					Редактировать
				</TextStyled>
			),
		},
		{
			meta: {
				id: "delete",
			},
			text: (
				<TextStyled color="red" type="base-medium">
					<BoldDelete fill="red" />
					Удалить
				</TextStyled>
			),
		},
	];
	const [searchText, setSearchText] = useState<string | undefined>(undefined);
	const [open, setOpen] = useState(false);
	const [isOpenDropDown, setIsOpenDropDown] = useState(false);
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
	const [isReplay, setIsReplay] = useState(false);
	const [isChangeMessage, setIsChangeMessage] = useState(false);
	const [files, setFiles] = useState([]);
	const [text, setText] = useState("");
	const [isShowSearchInput, setIsShowSearchInput] = useState(false);
	const [messageListArray, setMessagesListArray] = useState<IMessageContent[]>([]);
	const [currentIdMesssage, setCurrentIdMesssage] = useState("");
	const fileRef = useRef();
	const [currentIndexArray, setCurrentIndexArray] = useState(0);

	const user = useQuery({
		queryFn: () => getListChat({ params: {} }),
		queryKey: [QueriesKeys.chatList],
	});

	useEffect(() => {
		if (id) {
			queryClient.invalidateQueries([QueriesKeys.chatClientById]);
			queryClient.invalidateQueries([QueriesKeys.messagesList]);
		}
	}, [id]);

	const postFileUpload = useMutation(postFileUploadApi);

	useQuery({
		queryFn: () => getMessagesList({ params: { chatId: id, size: 10000 } }),
		queryKey: [QueriesKeys.messagesList, id],
		onSuccess(data) {
			setMessagesListArray(data.content);
		},
		enabled: !!id,
	});

	const searchArray = useQuery({
		queryFn: () => getMessagesList({ params: { chatId: id, query: searchText, size: 10000 } }),
		queryKey: [QueriesKeys.messagesListSearchList, searchText],
		enabled: !!id,
	});

	useEffect(() => {
		if (id) {
			setIsReplay(false);
			setIsChangeMessage(false);
			setText("");
		}
	}, [id]);

	const messageArray = useMemo(() => {
		const dataArray = messageListArray
			.sort((a, b) => (a.time > b.time ? 1 : -1))
			.reduce((acc, message) => {
				const date = dayjs(message.time).format("DD.MM.YYYY");

				if (acc[date]) {
					acc[date].content.push(message);
				} else {
					acc[date] = {
						isCreatedByCurrentUser: message.isCreatedByCurrentUser,
						time: dayjs(message.time).format("DD.MM.YYYY"),
						content: [message],
					};
				}
				return acc;
			}, {});
		return Object.values(dataArray);
	}, [messageListArray]);

	const postMessage = useMutation({
		mutationFn: (args: IPostMessage) => createMessage(args),
	});
	const deleteMessageFunc = useMutation({
		mutationFn: () => deleteMessage({ id: currentIdMesssage }),
	});
	const changeMessageFunc = useMutation({
		mutationFn: (args: { id: string; text: string }) => changeMessagePatch(args),
	});

	const [messages, setMessages] = useState<IMessageContent>({} as IMessageContent);

	useEffect(() => {
		const client = new Client({
			brokerURL: process.env.REACT_APP_BASIC_BROKER_URL,
			connectHeaders: {
				Authorization: `Bearer ${AuthStore.getTokens().access_token}`,
			},
			reconnectDelay: 5000,
			heartbeatIncoming: 4000,
			heartbeatOutgoing: 4000,
			onConnect: () => {
				client.subscribe("/user/topic/employee-messages", (message) => {
					const newMessage = JSON.parse(message.body);
					setMessages(newMessage);
				});
			},
		});

		client.activate();

		return () => {
			client.deactivate();
		};
	}, [AuthStore.getTokens().access_token]);

	useEffect(() => {
		if (messages) {
			if (messages.action === "DELETE") {
				setMessagesListArray(messageListArray.filter((v) => v.id !== messages.id));
			}
			if (messages.action === "CREATE") {
				setMessagesListArray([...messageListArray, messages]);
			}
			if (messages.action === "UPDATE") {
				setMessagesListArray(messageListArray.map((v) => (v.id === messages.id ? messages : v)));
			}
		}
	}, [messages]);

	const changeMessage = (meta: string) => {
		if (meta === "delete") {
			setIsOpenDeleteModal(true);
		}
		if (meta === "reply") {
			setIsReplay(true);
			setIsOpenDropDown(false);
		}
		if (meta === "edit") {
			setIsChangeMessage(true);
			setIsReplay(false);
			setIsOpenDropDown(false);
		}
	};

	useEffect(() => {
		if (isChangeMessage) {
			setText(messageListArray.find((v) => v.id === currentIdMesssage).text);
		}
	}, [isChangeMessage, currentIdMesssage]);

	const postMessageFunc = () => {
		if (files.length > 0) {
			return files.forEach(async (v) => {
				const formData = new FormData();
				if (v) {
					formData.append("file", v);
				}
				try {
					const uploadedFile = await postFileUpload.mutateAsync({ file: formData, bucket: "CHAT_FILES" });
					postMessage.mutateAsync({
						chatId: id,
						text,
						repliedMessageId: isReplay ? currentIdMesssage : undefined,
						fileIds: [uploadedFile.id],
					});
					setIsReplay(false);
					setIsChangeMessage(false);
					setText("");
					setFiles([]);
				} catch (error) {
					showToast({
						description: error,
						type: "error",
					});
				}
			});
		}
		if (!text) {
			return showToast({
				description: "Введите сообщение",
				type: "danger",
			});
		}
		if (!isChangeMessage && text) {
			postMessage.mutateAsync({ chatId: id, text, repliedMessageId: isReplay ? currentIdMesssage : undefined });
		}
		if (isChangeMessage && text) {
			changeMessageFunc.mutateAsync({ id: currentIdMesssage, text });
		}
		setIsReplay(false);
		setIsChangeMessage(false);
		setText("");
	};
	const deleteMessageModalOk = () => {
		deleteMessageFunc.mutateAsync();
		setIsOpenDeleteModal(false);
	};

	const handleChange = (e) => {
		const [file] = e.target.files;
		setFiles([...files, file]);
	};

	const refs = messageListArray.reduce((acc, value) => {
		acc[value.id] = React.createRef();
		return acc;
	}, {});

	const handleClick = (id) => {
		if (refs[id]) {
			refs[id].current?.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	};
	const bottomRef = useRef(null);
	const boxRef = useRef();

	useEffect(() => {
		const bottomElement = document.getElementById("bottom");
		bottomElement?.scrollIntoView({ behavior: "smooth" });
	}, [messageArray]);

	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			const ref: any = boxRef;
			if (isShowSearchInput && !searchText && ref.current && !ref.current?.contains(e.target)) {
				setIsShowSearchInput(false);
			}
		};

		document.addEventListener("mousedown", checkIfClickedOutside);

		return () => {
			document.removeEventListener("mousedown", checkIfClickedOutside);
		};
	}, [isShowSearchInput, searchText]);

	const handleClickUp = () => {
		if (currentIndexArray > 0) {
			const currentIndex = currentIndexArray > 0 ? currentIndexArray - 1 : 0;
			handleClick(searchArray.data.content.sort((a, b) => (a.time > b.time ? 1 : -1))[currentIndex]?.id);
			setCurrentIndexArray(currentIndex);
		}
	};

	const handleClickDown = () => {
		const currentIndex =
			searchArray.data.content.sort((a, b) => (a.time > b.time ? 1 : -1)).length - 1 >= currentIndexArray
				? currentIndexArray + 1
				: searchArray.data.content.length - 1;

		handleClick(searchArray.data.content.sort((a, b) => (a.time > b.time ? 1 : -1))[currentIndex]?.id);
		setCurrentIndexArray(currentIndex);
	};

	const dropdownClick = (v) => {
		setIsReplay(false);
		setIsChangeMessage(false);
		setIsOpenDropDown(!isOpenDropDown);
		setCurrentIdMesssage(v.id);
		setTimeout(() => {
			if (messageListArray[messageListArray.length - 1].id === v.id) {
				bottomRef.current.scrollTo({
					top: bottomRef.current.scrollHeight - 300,
					behavior: "smooth",
				});
			}
		}, 100);
	};
	const firstName = user.data?.content.find((v) => v.id === id).name.split("")[0];
	const lastName = user.data?.content.find((v) => v.id === id).name.split("")[1];

	if (!id || !messageListArray.length)
		return (
			<LoaderWrapper>
				<Loader />
			</LoaderWrapper>
		);
	return (
		<ChatInformationContentWrapper>
			<ChatInformationHeader>
				<ChatInformationHeaderTextWrapper>
					<AvatarChat avatarId={user.data?.content.find((v) => v.id === id).avatarId} firstName={firstName} lastName={lastName} />
					<div>
						<Text type="base-bold">{user.data?.content.find((v) => v.id === id).name}</Text>
						<Text type="base-regular">{""}</Text>
					</div>
				</ChatInformationHeaderTextWrapper>
				<ChatInformationHeaderButton>
					<Button onClick={() => setIsShowSearchInput(!isShowSearchInput)} typeButton="secondary" shape="square" icon={OutlineSearch3} />
					<Button onClick={() => setOpen(true)} typeButton="secondary" shape="square" icon={OutlineImage2} />
				</ChatInformationHeaderButton>
			</ChatInformationHeader>
			{isShowSearchInput && (
				<SearchWrapper ref={boxRef}>
					{searchArray.data?.content?.length > 0 && (
						<>
							<Button onClick={handleClickUp} typeButton="text" shape="square" icon={OutlineArrowUp2} />
							<Button onClick={handleClickDown} typeButton="text" shape="square" icon={OutlineArrowDown2} />
						</>
					)}

					<Search
						styleInputContainer={{ width: "100%" }}
						style={{ width: "100%" }}
						searchInputStyle={{ width: "100%" }}
						value={searchText}
						onChangeValue={setSearchText}
						sizeInput="large"
					/>
				</SearchWrapper>
			)}

			<ChatInformationWrapper ref={bottomRef}>
				{messageArray.map((val: IMessageArray) => (
					<>
						<ChatInformationWrapperDate type="base-medium">{val.time}</ChatInformationWrapperDate>
						{val.content?.map((v: IMessageContent) => (
							<>
								{v.isCreatedByCurrentUser && (
									<ChatInformationWrapperRight ref={refs[v.id]} id={v.id}>
										<ChatInformationWrapperRightBox>
											{v.repliedMessage && (
												<RepliedMessageBox>
													<TextStyledMessage type="base-medium" color="white">
														{v.repliedMessage.text}
													</TextStyledMessage>
												</RepliedMessageBox>
											)}
											{v?.fileIds?.length > 0 && (
												<ChatFileInformation
													isCreatedByCurrentUser={v.isCreatedByCurrentUser}
													isFileDrawer={false}
													fileId={v?.fileIds[0]}
												/>
											)}
											<TextStyledMessage color="white" type="base-regular">
												{v.text}
											</TextStyledMessage>
											<ChatInformationWrapperTimeWrapper>
												<ChatInformationWrapperTime color="white" type="base-regular">
													{dayjs(v.time).format("HH:mm")} {v.isModified && "(изменено)"}
												</ChatInformationWrapperTime>
												<Button id={DROPDOWN_BUTTON_ID} onClick={() => dropdownClick(v)} shape="square" icon={BoldInfomenu2} />
												<DropDown
													onClick={(item) => changeMessage(item.meta.id)}
													position="bottom-middle-right"
													outerButtonDropdownId={DROPDOWN_BUTTON_ID}
													isVisible={isOpenDropDown && currentIdMesssage === v.id}
													items={itemsDropDown}
													onClickOutside={() => {
														setIsOpenDropDown(false);
														setIsChangeMessage(false);
														setIsReplay(false);
													}}
												/>
											</ChatInformationWrapperTimeWrapper>
										</ChatInformationWrapperRightBox>
									</ChatInformationWrapperRight>
								)}
								{!v.isCreatedByCurrentUser && (
									<ChatInformationWrapperLeft ref={refs[v.id]}>
										<AvatarChat avatarId={user.data?.content.find((v) => v.id === id).avatarId} firstName={firstName} lastName={lastName} />
										<ChatInformationWrapperLeftBox>
											<Text color="rgba(153, 117, 44, 1)" type="base-bold">
												{user.data?.content.find((v) => v.id === id).name}
											</Text>
											{v.repliedMessage && (
												<RepliedMessageBox>
													<TextStyledMessage type="base-medium">{v.repliedMessage.text}</TextStyledMessage>
												</RepliedMessageBox>
											)}
											{v?.fileIds?.length > 0 && (
												<ChatFileInformation
													isCreatedByCurrentUser={v.isCreatedByCurrentUser}
													isFileDrawer={false}
													fileId={v?.fileIds[0]}
												/>
											)}
											<TextStyledMessage type="base-regular">{v.text}</TextStyledMessage>
											<ChatInformationWrapperTimeWrapper>
												<ChatInformationWrapperTime color="rgba(18, 18, 18, 0.6)" type="base-regular">
													{dayjs(v.time).format("HH:mm")} {v.isModified && "(изменено)"}
												</ChatInformationWrapperTime>
												<BoldReplyShareLeft1
													onClick={() => {
														setIsReplay(true);
														setCurrentIdMesssage(v.id);
													}}
													cursor="pointer"
													fill="rgba(18, 18, 18, 0.6)"
												/>{" "}
											</ChatInformationWrapperTimeWrapper>
										</ChatInformationWrapperLeftBox>
									</ChatInformationWrapperLeft>
								)}
							</>
						))}
					</>
				))}
				<div id="bottom" />
			</ChatInformationWrapper>
			<ChatInformationFotter>
				{isReplay && (
					<ReplyWrapper>
						<ReplyWrapperBox>
							<BoldReplyShareLeft1 size={40} fill="#99752C" />
							<div>
								{messageListArray.find((v) => v.id === currentIdMesssage)?.fileIds?.length > 0 ? (
									<ChatReplayFotterInformation
										messageListArray={messageListArray}
										currentIdMesssage={currentIdMesssage}
										user={user.data?.content}
									/>
								) : (
									<>
										<Text type="large-medium" color="#99752C">
											В ответ{" "}
											{messageListArray.filter((v) => v.id === currentIdMesssage && !v.isCreatedByCurrentUser).length > 0
												? user.data?.content.find((v) => v.id === id).name
												: ""}
										</Text>
										<Text type="base-regular">{messageListArray.find((v) => v.id === currentIdMesssage)?.text}</Text>
									</>
								)}
							</div>
						</ReplyWrapperBox>
						<OutlineCloseremove onClick={() => setIsReplay(false)} cursor="pointer" size={20} fill="black" />
					</ReplyWrapper>
				)}
				{isChangeMessage && (
					<ReplyWrapper>
						<ReplyWrapperBox>
							<BoldEdit size={40} fill="#99752C" />
							<div>
								<Text type="large-medium" color="#99752C">
									Редактирование
								</Text>
								<Text type="base-regular">{messageListArray.find((v) => v.id === currentIdMesssage)?.text}</Text>
							</div>
						</ReplyWrapperBox>
						<OutlineCloseremove onClick={() => setIsChangeMessage(false)} cursor="pointer" size={20} fill="black" />
					</ReplyWrapper>
				)}
				<TextArea
					maxLength={220}
					style={{ width: "100%" }}
					value={text}
					onKeyDownCapture={(e) => {
						if (e.keyCode === 13) {
							postMessageFunc();
						}
					}}
					onChangeValue={(value) => {
						setText(value);
						bottomRef.current.scrollTo({
							top: bottomRef.current.scrollHeight,
							behavior: "smooth",
						});
					}}
				/>
				<ChatInformationFotterButton>
					<UploadWrapper>
						{files.map((v) => (
							<Upload
								files={[v]}
								accept="image/jpeg,image/png,image/gif,image/jpg"
								onChangeFiles={() => undefined}
								onRemove={() => setFiles(files.filter((val) => v !== val))}
								type="image"
								description={v.name}
							/>
						))}
					</UploadWrapper>

					<ButtonWrapper>
						<Button
							onClick={() => {
								const ref: React.RefObject<HTMLInputElement> = fileRef;
								if (ref.current) {
									ref.current.click();
								}
							}}
							shape="square"
							typeButton="secondary"
							icon={BoldAttach}
						/>
						<input ref={fileRef} onChange={handleChange} multiple={false} type="file" hidden />
						<Button onClick={postMessageFunc}>Отправить</Button>
					</ButtonWrapper>
				</ChatInformationFotterButton>
			</ChatInformationFotter>
			<ChatFileDrawer id={id} open={open} setOpen={setOpen} />
			<BaseModal
				isVisible={isOpenDeleteModal}
				description="Сообщение будет удалено у всех"
				okText="Да"
				onCancel={() => setIsOpenDeleteModal(false)}
				onOk={deleteMessageModalOk}
				cancelText="Нет"
				status="delete"
				title="Удалить сообщение?"
			/>
		</ChatInformationContentWrapper>
	);
});
const TextStyledMessage = styled(Text)`
	max-width: 500px;
	overflow: hidden;
	text-overflow: ellipsis;
`;
const LoaderWrapper = styled.div`
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 65%;
	right: 90%;
	top: 50%;
	text-align: center;
`;

const SearchWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 10px 15px;
	z-index: 1000;
	border-bottom: 1px solid ${colors.strokeDivider};
`;
const UploadWrapper = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;
const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;
const RepliedMessageBox = styled.div`
	border-left: 3px solid #99752c;
	background: #12121214;
	padding: 10px;
	border-radius: 2px;
`;
const ReplyWrapperBox = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;
const ReplyWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
`;

const TextStyled = styled(Text)`
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 5px 0px;
`;
const ChatInformationFotterButton = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
`;
const ChatInformationFotter = styled.div`
	padding: 16px;
`;
const ChatInformationWrapperDate = styled(Text)`
	border: 0.5px solid ${colors.strokeDivider};
	background: rgba(18, 18, 18, 0.04);
	border-radius: 5px;
	padding: 0px 8px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	width: 100px;
`;
const ChatInformationWrapperTimeWrapper = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: flex-end;
	position: relative;
`;
const ChatInformationWrapperTime = styled(Text)`
	display: flex;
	justify-content: flex-end;
`;
const ChatInformationWrapperLeftBox = styled.div`
	background: rgba(18, 18, 18, 0.04);
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	border-radius: 16px;
	min-width: 200px;
`;
const ChatInformationWrapperRightBox = styled.div`
	background: rgba(190, 152, 65, 1);
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	border-radius: 16px;
	min-width: 200px;
`;
const ChatInformationWrapperLeft = styled.div`
	display: flex;
	gap: 10px;
	margin: 10px 0;
`;
const ChatInformationWrapperRight = styled.div`
	display: flex;
	gap: 10px;
	justify-content: flex-end;
	margin-bottom: 20px;
`;
const ChatInformationContentWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-left: 320px;
`;
const ChatInformationWrapper = styled.div`
	background: rgba(18, 18, 18, 0.02);
	height: 70vh;
	padding: 16px;
	position: relative;
	gap: 10px;
	overflow: scroll;
`;
const ChatInformationHeaderButton = styled.div`
	display: flex;
	gap: 10px;
`;

const ChatInformationHeaderTextWrapper = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;
const ChatInformationHeader = styled.div`
	padding: 18px;
	border-bottom: 1px solid ${colors.strokeDivider};
	height: 62px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export default ChatInformation;
