export const getToastMessage = ({
	isTemplate,
	isUpdatePoll,
	pollType,
}: {
	isTemplate: boolean;
	isUpdatePoll: boolean;
	pollType: string;
}) => {
	let baseMessage = "Голосование";
	if (isTemplate) {
		baseMessage = "Шаблон";
	} else if (pollType === "POLL") {
		baseMessage = "Опрос";
	}

	let actionMessage = "создано";
	if (isUpdatePoll) {
		actionMessage = "обновлен";
	} else if (pollType === "POLL") {
		actionMessage = "создан";
	}

	return `${baseMessage} ${actionMessage}`;
};
