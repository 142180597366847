import { Row } from "antd";
import styled from "styled-components";
import { BoldDocumentpaper3Lines } from "t2v-icons";
import { Button } from "tap2visit-ui-kit";

import BuildingFilterStore from "components/TableFilter/BuildingFilter.store";
import TableFilter from "components/TableFilter/TableFilter";
import { TableFilterContext } from "components/TableFilter/TableFilter.context";
import ReceiptsDrawerStore from "pages/receipts/components/store/ReceiptsDrawer.store";

import CountersDrawer from "./components/CountersDrawer";
import Table from "./Table/Table";

const TableFilterInstanceStore = new BuildingFilterStore();

export const Counters = () => (
	<>
		<TableFilterContext.Provider value={TableFilterInstanceStore}>
			<PaddingHorizontalOffset>
				<Row justify="end">
					<Button size="medium" icon={BoldDocumentpaper3Lines} onClick={ReceiptsDrawerStore.toggleIsOpenDrawer}>
						Сформировать отчет
					</Button>
				</Row>
			</PaddingHorizontalOffset>

			<CountersFilterWrapper>
				<TableFilter
					customSwitchOptions={{
						isVisibleSwitch: false,
					}}
				/>
			</CountersFilterWrapper>

			<Table />

			<CountersDrawer />
		</TableFilterContext.Provider>
	</>
);

export const HORIZONTAL_PADDING = "16px";

const CountersFilterWrapper = styled.div`
	padding: 0px 12px;
	padding-bottom: 24px;
`;
const PaddingHorizontalOffset = styled.div`
	padding: 32px ${HORIZONTAL_PADDING};
`;
