import { useState } from "react";

import CitizensDrawerStore from "../store/Citizens.drawer.store";

const useNewFileState = () => {
	const [newFiles, setNewFiles] = useState<File[]>([]);
	const [needToRemoveFileIds, setNeedToRemoveFileIds] = useState<string[]>([]);

	const checkIfFilesChanged = (updatedFiles: File[]): boolean => !!updatedFiles.length || !!needToRemoveFileIds.length;

	const handleFilesChange = (updatedFiles: File[]) => {
		setNewFiles(updatedFiles);
		const filesChanged = checkIfFilesChanged(updatedFiles);
		CitizensDrawerStore.setHasUnsavedData(filesChanged);
	};

	return {
		newFiles,
		setNewFiles: handleFilesChange,
		needToRemoveFileIds,
		setNeedToRemoveFileIds,
	};
};

export default useNewFileState;
