import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Space, Text } from "tap2visit-ui-kit";

import TableFilter from "components/TableFilter/TableFilter";

const AppealsAddressDrawer = () => (
	<Space width="100%" direction="column" align="start" size={8}>
		<Text h="h5">Адрес</Text>
		<AppealsDrawerWrapper>
			<TableFilter
				customSwitchOptions={{
					size: "l",
					isFullWidth: true,
					isVisibleSwitch: false,
					isNotShowButton: true,
					isRequired: true,
				}}
			/>
		</AppealsDrawerWrapper>
	</Space>
);

const AppealsDrawerWrapper = styled.div`
	padding: 18px;
	background: #12121205;
	border-radius: 12px;
	width: 100%;
`;
export default observer(AppealsAddressDrawer);
