import { observer } from "mobx-react-lite";
import styled from "styled-components";

import Page from "components/layout/Page";
import BuildingFilterStore from "components/TableFilter/BuildingFilter.store";
import TableFilter from "components/TableFilter/TableFilter";
import { TableFilterContext } from "components/TableFilter/TableFilter.context";

import ReceiptsDrawer from "./components/ReceiptsDrawer/ReceiptsDrawer";
import Statistic from "./components/Statistic/Statistic";
import ReceiptsDrawerStore from "./components/store/ReceiptsDrawer.store";
import Table from "./components/Table/Table";
import Tabs from "./components/Tabs";

const TableFilterInstanceStore = new BuildingFilterStore();

const Receipts = () => (
	<Page>
		<TableFilterContext.Provider value={TableFilterInstanceStore}>
			<PaddingHorizontalOffset>
				<Tabs />
			</PaddingHorizontalOffset>

			<FilterWrapper>
				<TableFilter
					customSwitchOptions={{
						isVisibleSwitch: false,
					}}
				/>
			</FilterWrapper>

			<StatisticContainer>
				<Statistic />
			</StatisticContainer>

			<Table />
		</TableFilterContext.Provider>
		{ReceiptsDrawerStore.isOpen && <ReceiptsDrawer />}
	</Page>
);

export default observer(Receipts);

export const HORIZONTAL_PADDING = "16px";

const PaddingHorizontalOffset = styled.div`
	padding: 32px ${HORIZONTAL_PADDING};
`;

const FilterWrapper = styled.div`
	padding: 0px 12px;
	padding-bottom: 24px;
`;

const StatisticContainer = styled.div`
	padding: 24px 15px;
	padding-top: 3px;
`;
