import React, { FC } from "react";

import { styled } from "styled-components";
import { Alert } from "tap2visit-ui-kit";

import type { IRequestClientWithRole, IRequestUser } from "interfaces/IRequest";
import type { IRole } from "interfaces/IRole";
import useMatchUsers, { TUseMatchUsersReturn } from "pages/accessRequest/hooks/useMatchUsers";

import AlertSkeleton from "./AlertSkeleton";

interface IAccessRequestAlert {
	requestUser: IRequestUser;
	compareUser: IRequestClientWithRole;
	compareUserRoles: IRole[];
	isFetching: boolean;
}

const AccessRequestAlert: FC<IAccessRequestAlert> = (props) => {
	const [isVisibleAlert, setIsVisibleAlert] = React.useState(props.requestUser.status === "NEW");

	const matchUser = useMatchUsers({
		requestUser: props.requestUser,
		compareUser: props.compareUser,
		compareUserRoles: props.compareUserRoles,
	});

	const alertProps = getAlertProps(matchUser, !!props.compareUser);

	React.useEffect(() => {
		if (props.requestUser.status === "NEW") {
			setIsVisibleAlert(true);
		} else {
			setIsVisibleAlert(false);
		}
	}, [props.compareUser]);

	return (
		<Container>
			{props.isFetching && <AlertSkeleton />}

			{isVisibleAlert &&
				!props.isFetching &&
				alertProps?.map((alertCurrentProps) => (
					<Alert
						key={alertCurrentProps.title}
						{...alertCurrentProps}
						visible={true}
						closeIcon={true}
						withIcon={true}
						onClose={() => setIsVisibleAlert(false)}
					/>
				))}
		</Container>
	);
};

export default AccessRequestAlert;

const getAlertProps = (
	matchData: TUseMatchUsersReturn,
	isCompareUserExist: boolean,
): { variant: "success" | "warning" | "error"; title: string; description: string }[] => {
	if (!isCompareUserExist || !matchData.rolesMatch || !matchData.firstNameMatch || !matchData.lastNameMatch) {
		return [
			{
				variant: "error",
				title: "Такой человек не найден",
				description: "Необходимо отклонить заявку с комментарием о просьбе явиться лично.",
			},
		];
	}

	const alertProps: { variant: "success" | "warning" | "error"; title: string; description: string }[] = [];

	const isAllMatch = Object.entries(matchData).every(([key, value]) => {
		if (key === "loginMatchStatus") {
			return value === "FOUND_IN_CONTACTS" || value === "LOGIN_MATCH";
		}
		return value;
	});

	if (isAllMatch) {
		alertProps.push({ variant: "success", title: "Все совпало!", description: "Можете принять запрос." });
	}

	if (
		matchData.rolesMatch &&
		matchData.firstNameMatch &&
		matchData.lastNameMatch &&
		(matchData.loginMatchStatus === "LOGIN_MATCH" ||
			matchData.loginMatchStatus === "FOUND_IN_CONTACTS" ||
			matchData.loginMatchStatus === "NOT_FOUND_IN_CONTACTS") &&
		(!matchData.middleNameMatch || !matchData.innMatch)
	) {
		alertProps.push({ variant: "warning", title: "Совпадение найдено", description: "Необходимо все проверить и принять решение!" });
	}

	if (matchData.loginMatchStatus === "NOT_FOUND_IN_CONTACTS") {
		alertProps.push({
			variant: "warning",
			title: "Логин приложения не найден",
			description: "Не найдено совпадений телефона(ов) для связи пользователя с логином клиента в запросе. Свяжитесь с пользователем.",
		});
	}

	if (matchData.loginMatchStatus === "LOGIN_NOT_MATCH") {
		alertProps.push({
			variant: "error",
			title: "Пользователь уже зарегистрировался под другим номером",
			description: "Необходимо отклонить заявку с комментарием о просьбе явиться лично.",
		});
	}

	return alertProps;
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;

	svg:last-of-type {
		margin-top: 3px;
	}
`;
