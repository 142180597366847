import React, { FC } from "react";

import { downloadFileApi } from "api/api.storage";
import { QueriesKeys } from "interfaces/queriesKeys";
import { useQuery } from "react-query";
import { getUid } from "tap2visit-ui-kit";

interface IUseDownloadedFilesArgs {
	fileIds?: string[];
}

const useDownloadedFiles = (args: IUseDownloadedFilesArgs) => {
	const downloadedFiles = useQuery({
		queryFn: async () => {
			const result: { file: File; fileId: string }[] = [];
			for await (const fileId of args.fileIds) {
				try {
					const file = await downloadFileApi(fileId);
					if (file) {
						const contentType = file.type || "application/octet-stream";
						const blob = new Blob([file], { type: contentType });

						const originalName = file.name || `${getUid("document_file_")}`;
						const fileExtension = contentType.split("/")[1] || "dat";

						const finalFileName = originalName.includes(".") ? originalName : `${originalName}.${fileExtension}`;

						const finalFile = new File([blob], finalFileName, { type: contentType });
						result.push({ file: finalFile, fileId: fileId });
					}
				} catch (error) {
					console.error(`Error while downloading file with ID ${fileId}:`);
				}
			}
			return result;
		},
		enabled: !!args?.fileIds?.length,
		queryKey: [QueriesKeys.downloadFile, ...(args?.fileIds ?? [])],
	});

	return downloadedFiles;
};

export default useDownloadedFiles;
