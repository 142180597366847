import React, { useEffect, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react-lite";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { colors, FormItem, Loader, Select, showToast, Space, Tag, Text, TimePicker } from "tap2visit-ui-kit";

import { getUsersApi } from "api/api.employee";
import { changeExecutionAppealApi } from "api/api.gateway";
import DatePicker from "components/DatePicker";
import { QueriesKeys } from "interfaces/queriesKeys";
import DrawerStore from "pages/appeals/store/Drawer.store";

const AppealSpecialist = () => {
	const [date, setDate] = useState<Dayjs>();
	const [time, setTime] = useState<Dayjs>();

	const [isOpenedSelect, setIsOpenedSelect] = useState(false);
	const [tagsMaster, setTagsMaster] = useState([]);
	const {
		data: masters,
		isSuccess: isSuccessMasters,
		isFetching: isFetchingMasters,
		isLoading: isLoadingMasters,
	} = useQuery({
		queryFn: () =>
			getUsersApi({
				roles: ["MASTER"],
				page: 0,
				size: 100,
			}),
		queryKey: [QueriesKeys.masters],
	});

	const changeAppeal = useMutation(changeExecutionAppealApi);

	useEffect(() => {
		if (isSuccessMasters && DrawerStore.selectedAppeals.execution?.masterIds) {
			setTagsMaster([
				...DrawerStore.selectedAppeals.execution.masterIds.map((v) => ({
					key: v,
					focused: false,
					text: `${masters?.content.find((val) => val.id === v)?.lastName ?? ""} ${
						masters?.content.find((val) => val.id === v)?.firstName ?? ""
					} ${masters?.content.find((val) => val.id === v)?.middleName ?? ""}`,
				})),
			]);
		}
	}, [DrawerStore.selectedAppeals.execution, isSuccessMasters]);

	const changeAppealExecution = ({ masterIds, time }: { masterIds: string[]; time: Date }) => {
		changeAppeal
			.mutateAsync({
				args: {
					masterIds,
					time,
				},
				id: DrawerStore.selectedAppeals.id,
			})
			.then((res) => {
				DrawerStore.setSelectedAppeals(res);
			});
	};

	const isShowSelect = DrawerStore.selectedAppeals.status !== "COMPLETED" && DrawerStore.selectedAppeals.status !== "ARCHIVED";

	const isDisabledSelect =
		DrawerStore.selectedAppeals.status === "COMPLETED" ||
		DrawerStore.selectedAppeals.status === "ARCHIVED" ||
		!!DrawerStore.selectedAppeals.parentAppealId;

	const deleteTag = (master) => {
		if (!DrawerStore.selectedAppeals.parentAppealId) {
			setTagsMaster(tagsMaster?.filter((masterItem) => master.id !== masterItem.id));
			changeAppealExecution({
				masterIds: tagsMaster.filter((masterItemFilter) => masterItemFilter.key !== master.key).map((masterItem) => masterItem.key),
				time: dayjs(date).toDate(),
			});
		}
	};

	const clickSelectItem = (item) => {
		const items = [...tagsMaster, item];
		setTagsMaster(items);
		setIsOpenedSelect(false);
		changeAppealExecution({ masterIds: items.map((masterItem) => masterItem.key), time: dayjs(date).toDate() });
	};

	const itemsMasters = masters?.content.map((master) => ({
		text: `${master.lastName} ${master.firstName} ${master.middleName}`,
		key: master.id,
		focused: false,
	}));

	useEffect(() => {
		if (DrawerStore.selectedAppeals.execution.time) {
			setDate(dayjs(DrawerStore.selectedAppeals.execution.time));
			setTime(dayjs(DrawerStore.selectedAppeals.execution.time));
		}
	}, [DrawerStore.selectedAppeals.execution.time]);

	const showDangerToast = () => {
		showToast({
			description: "Обращение связано. Для редактирования необходимо изменить главное обращение",
			type: "danger",
		});
	};

	if (isFetchingMasters || isLoadingMasters) return <Loader />;
	return (
		<Space width="100%" direction="column" align="start">
			<Text h="h5" color={colors.textNeutralPrimary}>
				Специалист
			</Text>
			{isShowSelect && (
				<FormItem label="Мастер">
					<Select
						isDisabled={isDisabledSelect}
						style={{ width: "510px" }}
						items={itemsMasters}
						onClickSelect={() => {
							if (DrawerStore.selectedAppeals.parentAppealId) {
								return showDangerToast();
							}
							if (isDisabledSelect) {
								return;
							}

							setIsOpenedSelect(true);
						}}
						onClose={() => setIsOpenedSelect(false)}
						onClickSelectItem={(item) => clickSelectItem(item)}
						isOpened={isOpenedSelect}
					/>
				</FormItem>
			)}

			<Space size={8} wrap="wrap" width="100%">
				{isShowSelect && tagsMaster?.map((master) => <Tag onDelete={() => deleteTag(master)} text={master.text} />)}
			</Space>

			{!isShowSelect && tagsMaster?.map((master) => <Tag text={master.text} />)}
			<FormItem label="Время посещения">
				<DateWrapper
					style={{
						pointerEvents:
							DrawerStore.selectedAppeals.status === "COMPLETED" || DrawerStore.selectedAppeals.status === "ARCHIVED" ? "none" : "unset",
						opacity: DrawerStore.selectedAppeals.status === "COMPLETED" || DrawerStore.selectedAppeals.status === "ARCHIVED" ? "0.8" : "1",
					}}>
					<DatePicker
						onClickContainer={() => DrawerStore.selectedAppeals.parentAppealId && showDangerToast()}
						disabled={isDisabledSelect}
						currentDate={date}
						onChangeDate={(dateValue) => {
							setDate(dayjs(dateValue[0]));
						}}
						onSave={(value) => {
							changeAppealExecution({
								masterIds: tagsMaster.map((masterItem) => masterItem.key),
								time: dayjs(value).add(1, "day").toDate(),
							});
							setDate(dayjs(value));
						}}
					/>
					<TimePicker
						onClickOpenSelectTime={() => DrawerStore.selectedAppeals.parentAppealId && showDangerToast()}
						isChangeClickOkButton
						disabled={isDisabledSelect}
						onChange={(time) => {
							changeAppealExecution({
								masterIds: tagsMaster.map((masterItem) => masterItem.key),
								time: dayjs(date).hour(time.get("hour")).minute(time.get("minute")).toDate(),
							});
							setTime(dayjs(time));
						}}
						size="medium"
						initialTime={isNaN(time?.day()) ? undefined : time}
					/>
				</DateWrapper>
			</FormItem>
		</Space>
	);
};

const DateWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

export default observer(AppealSpecialist);
