import { useEffect } from "react";

import { Badge, Text } from "tap2visit-ui-kit";

import { ReactComponent as UnCheckSvg } from "public/uncheckCircle.svg";

import UpdateDataTabsStore from "../store/UpdateData.tabs.store";
import {
	Item,
	ItemWrapper,
	OrderedListItem,
	CustomList,
	StatusContainer,
	Divider,
	ErrorContainer,
	HeaderError,
	Description,
	ErrorAttention,
} from "../UpdateData.styled";

import UpdateDataForm from "./UpdateData.form";

export const UpdateDataDownload = () => {
	useEffect(() => {
		if (UpdateDataTabsStore.getIsTransitionFromHistory()) {
			window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
		}
	}, []);

	return (
		<ItemWrapper>
			<Item>
				<Text h="h5" type="base-bold">
					Инструкция по работе с разделом “Обновление данных”
				</Text>
				<CustomList>
					<OrderedListItem isNumbered>Загрузить отчёт «Загрузка файла».</OrderedListItem>
					<OrderedListItem>Выгрузить отчёт из системы «Аркус» и сохранить его на рабочем компьютере.</OrderedListItem>
					<OrderedListItem>Выбрать жилой комплекс из списка доступных вашей управляющей компании.</OrderedListItem>
					<OrderedListItem>Выбрать адрес объекта недвижимости, для которого нужно обновить данные (дом, литер, корпус).</OrderedListItem>
					Выберите тип данных:
					<OrderedListItem>«Недвижимость» (аналог отчёта «Недвижимость» из системы «Аркус»);</OrderedListItem>
					<OrderedListItem>«Открытые лицевые счета» (аналог отчёта «Открытые лицевые счета» из системы «Аркус»);</OrderedListItem>
					<OrderedListItem>«Должники» (аналог отчёта «Должники» из системы «Аркус»).</OrderedListItem>
					<OrderedListItem isNumbered>Перейти в область «Файл» и загрузить нужный файл.</OrderedListItem>
					<OrderedListItem isNumbered>
						Проверьте файл на первичную загрузку. При успешной проверке появятся следующие действия:
					</OrderedListItem>
					<OrderedListItem>
						«Подтвердить загрузку» — отправка файла на вторую проверку и итоговую загрузку в хранилище данных;
					</OrderedListItem>
					<OrderedListItem>
						«Корзина» — удаление файла, если он был загружен некорректно. При неудачной проверке на экране будет сообщение об ошибке с
						указанием причины (некорректный тип файла, некорректная шапка файла, некорректный тип данных в файле). В этом случае можно
						попробовать обновить загрузку или удалить файл и внести корректировки в списки выбранных ранее.{" "}
					</OrderedListItem>
					Инструкция по использованию раздела "История":
				</CustomList>

				<CustomList>
					<OrderedListItem isNumbered>Перейдите в раздел «История».</OrderedListItem>
					<OrderedListItem isNumbered>Здесь найдёте таблицу с данными о загрузках отчётов в Хранилище данных.</OrderedListItem>
					<OrderedListItem isNumbered>Столбец «Импорт» показывает типы данных, загруженных в Хранилище.</OrderedListItem>
					<OrderedListItem isNumbered>Столбец «Статус» отображает текущий статус загрузки данных:</OrderedListItem>
					<OrderedListItem>Загружено — данные успешно загружены в Хранилище;</OrderedListItem>
					<OrderedListItem>Обработка — данные обрабатываются системой;</OrderedListItem>
					<OrderedListItem>Частично обработанный - данные частично загружены;</OrderedListItem>
					<OrderedListItem>Ошибка — произошла ошибка при загрузке данных.</OrderedListItem>
					<OrderedListItem isNumbered>Столбец «Дата и время» показывает время загрузки файла.</OrderedListItem>
					<OrderedListItem isNumbered>
						Столбец «Сотрудник» содержит фамилию и имя сотрудника, который загрузил данные в Хранилище.
					</OrderedListItem>
					Расширенное описание статусов:
				</CustomList>

				<StatusContainer>
					<Badge text={"Загружено"} type="status" status="green" /> Файл корректно загружен в Хранилище данных.
				</StatusContainer>
				<Divider />
				<StatusContainer>
					<Badge text={"Обработка"} type="status" status="blue" /> файл проходит проверку параметров перед загрузкой в хранилище данных,
					процесс может занять время.
				</StatusContainer>
				<Divider />
				<StatusContainer>
					<Badge text={"Частично обработанный"} type="status" status="yellow" /> файл загружен частично из-за технической ошибки, проблема
					будет решена автоматически в течение 30–120 минут. Если по истечении срока статус не изменился, обратитесь в техническую поддержку
				</StatusContainer>
				<Divider />
				<Divider />
				<ErrorContainer>
					<HeaderError>
						<UnCheckSvg /> Некорректное заполнение шапки файла
					</HeaderError>
					<Description>
						Ошибка связана с названиями столбцов в загружаемом документе. Производим первичную загрузку данных по жилым комплексам (домам,
						корпусам, литерам). Это определяет стандартный вид загружаемых данных на основе отчётов из «Аркуса». Пожалуйста, проверьте
						наименования столбцов в файле.
					</Description>
				</ErrorContainer>
				<Divider />
				<ErrorContainer>
					<HeaderError>
						<UnCheckSvg /> Нет данных для импорта
					</HeaderError>
					<Description>
						Ошибка из-за некорректных или отсутствующих данных в загружаемом файле. Проверьте данные и внесите исправления для повторной
						загрузки.
					</Description>
				</ErrorContainer>
				<Divider />
				<ErrorContainer>
					<HeaderError>
						<UnCheckSvg /> Лицевые счета не найдены (кол-во)
					</HeaderError>
					<Description>
						сверяем их по вашему адресу и загруженному файлу через сервис ГИС ЖКХ. Если есть различия хотя бы по одному счёту, возникает
						ошибка. Проверьте лицевые счета в файле.
					</Description>
				</ErrorContainer>
				<Divider />
				<ErrorContainer>
					<HeaderError>
						<UnCheckSvg /> Количество ЛС неверно
					</HeaderError>
					<Description>
						Ошибка связана с несоответствием количества лицевых счетов в загруженном файле. Сверяем данные с сервисом ГИС ЖКХ и обнаруживаем
						несоответствие количества счетов указанному адресу. Проверьте загруженный файл или настройки.
					</Description>
				</ErrorContainer>
				<Divider />
				<Divider />
				<ErrorContainer>
					<HeaderError>
						<UnCheckSvg /> Лицевой счёт некорректен
					</HeaderError>
					<Description>
						Некорректные данные по указанным ЛС(лицевым счетам) в загружаемом файле. Проверьте и исправьте данные в файле, затем повторите
						загрузку.
					</Description>
				</ErrorContainer>
				<Divider />
				<ErrorContainer>
					<HeaderError>
						<UnCheckSvg /> Задолженность некорректна
					</HeaderError>
					<Description>
						ошибка связана с некорректными данными в загруженном отчёте. Указывается количество строк с ошибками. Проверьте и исправьте
						информацию в файле.
					</Description>
				</ErrorContainer>
				<Divider />
				<ErrorContainer>
					<HeaderError>
						<UnCheckSvg /> Объект недвижимости некорректен
					</HeaderError>
					<Description>
						Ошибка связана с некорректными данными об объектах недвижимости в загруженном файле. Проверьте данные, внесите исправления и
						повторите загрузку.
					</Description>
				</ErrorContainer>
				<Divider />
				<ErrorAttention>
					<Text type="base-bold">
						Важно! Ошибки могут возникнуть, если после скачивания отчёта из «Аркуса» вы внесли изменения. Чтобы исправить большинство
						ошибок, попробуйте заново скачать отчёт и загрузить его в личный кабинет управляющей компании.
					</Text>
				</ErrorAttention>
			</Item>
			<Item style={{ height: "100%" }}>
				<UpdateDataForm />
			</Item>
		</ItemWrapper>
	);
};
