import React, { useState } from "react";

import dayjs from "dayjs";
import { useMutation } from "react-query";
import styled from "styled-components";
import { OutlineConnectingCable } from "t2v-icons";
import { colors, Text } from "tap2visit-ui-kit";

import { changeChildAppealApi } from "api/api.gateway";
import BaseModal from "components/Modals/BaseModal";
import { queryClient } from "index";
import { IAppeal } from "interfaces/IAppeals";
import { QueriesKeys } from "interfaces/queriesKeys";
import DrawerStore from "pages/appeals/store/Drawer.store";

const AppealConnectionItem = ({ value, childAppeals }: { value: IAppeal; childAppeals: IAppeal }) => {
	const [isOpen, setIsOpen] = useState(false);
	const changeChildAppealFunc = useMutation(changeChildAppealApi);
	const parentId = DrawerStore.selectedAppeals.parentAppealId;

	const getArgs = () => {
		if (parentId) {
			return {
				args: {
					childAppealIds: childAppeals[0].childAppealIds.filter((childAppeal) => childAppeal !== DrawerStore.selectedAppeals.id),
				},
				id: childAppeals[0].id,
			};
		}

		return {
			args: {
				childAppealIds: DrawerStore.selectedAppeals.childAppealIds.filter((childAppeal) => childAppeal !== value.id),
			},
			id: DrawerStore.selectedAppeals.id,
		};
	};
	const createChildAppeal = () => {
		changeChildAppealFunc.mutateAsync(getArgs()).then(() => {
			queryClient.invalidateQueries([QueriesKeys.appealsById]);
			setIsOpen(false);
		});
	};

	return (
		<AppealConnectionItemWrapper>
			<BaseModal
				isVisible={isOpen}
				okText="Да"
				onCancel={() => setIsOpen(false)}
				onOk={createChildAppeal}
				cancelText="Нет"
				status="delete"
				title="Вы уверены, что хотите открепить обращение?"
			/>
			<ConnectionWrapper onClick={() => DrawerStore.openDrawer(value)}>
				<AppealText type="table-cell-m-regular" color={colors.textNeutralSecondary}>
					{dayjs(value.createdAt).format("DD.MM.YYYY")}
				</AppealText>
				<AppealText type="table-cell-l-medium" color={colors.textNeutralPrimary}>
					№{value.number} {value.title}
				</AppealText>
				<AppealText type="table-cell-m-regular" color={colors.textNeutralSecondary}>
					{value.buildingObjectAddress?.addressValue}
				</AppealText>
			</ConnectionWrapper>
			{DrawerStore.selectedAppeals.status !== "COMPLETED" && DrawerStore.selectedAppeals.status !== "ARCHIVED" && (
				<OutlineConnectingCable style={{ cursor: "pointer" }} onClick={() => setIsOpen(true)} fill={colors.iconNeutralDefault} />
			)}
		</AppealConnectionItemWrapper>
	);
};

const ConnectionWrapper = styled.div`
	cursor: pointer;
`;

const AppealText = styled(Text)`
	width: 420px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
`;

const AppealConnectionItemWrapper = styled.div`
	padding: 12px;
	border-bottom: 1px solid #12121229;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export default AppealConnectionItem;
