import { FC } from "react";

import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Button, Loader } from "tap2visit-ui-kit";

import { Spacer } from "components/layout/Spacer";
import PollSendMethods from "pages/polls/components/PollSendMethods";
import PollsRoles from "pages/polls/components/PollsRoles";
import PollsTreeBuildings from "pages/polls/components/PollsTreeBuildings";
import { ReactComponent as PlusSvg } from "public/plus.svg";
import SideBarStore from "store/SideBar.store";

import useSelectedPoll from "../../hooks/useSelectedPoll";
import PollsEditStore from "../../store/PollsItem.store";
import PollsItemStore from "../../store/PollsItem.store";
import PollsSendModal from "../PollsSendModal/PollsSendModal";

import LoadingTemplate from "./components/LoadingTemplate/LoadingTemplate";
import PollsDetailListQuestions from "./components/PollsDetail.listQuestions";
import PollsDetailNameCard from "./components/PollsDetailNameCard/PollsDetailNameCard";
import { OuterContainer } from "./components/QuestionItem/styles/common";

interface IPollsDetail {
	editable?: boolean;
}

// eslint-disable-next-line arrow-body-style
const PollsDetail: FC<IPollsDetail> = (props) => {
	const isFetching = useSelectedPoll();
	const isLoadingTemplate = PollsItemStore.isLoadingTemplate;

	return (
		<>
			{isFetching && (
				<LoaderContainer>
					<Loader />
				</LoaderContainer>
			)}
			{!isFetching && (
				<>
					<Container>
						<PollsDetailNameCard editable={props.editable} />

						<Spacer px={16} />

						{isLoadingTemplate ? (
							<>
								<Spacer px={75} />
								<LoadingTemplate />
							</>
						) : (
							<>
								<PollsDetailListQuestions editable={props.editable} />

								{props.editable && (
									<>
										<Spacer px={16} />
										<Button icon={PlusSvg} typeButton="secondary" onClick={PollsEditStore.addPollQuestion}>
											Добавить вопрос
										</Button>
									</>
								)}
							</>
						)}

						{!props.editable && (
							<SendContainerInformation>
								<PollSendMethods />

								<Spacer px={16} />

								<PollsRoles />

								<Spacer px={16} />

								<PollsTreeBuildings editable={props.editable} />

								<Spacer px={16} />
							</SendContainerInformation>
						)}
					</Container>

					{props.editable && <PollsSendModal />}
				</>
			)}
		</>
	);
};

export default observer(PollsDetail);

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
`;

const LoaderContainer = styled.div`
	position: absolute;
	top: 50%;
	left: calc(50% + ${SideBarStore.sideBarWidth});
`;

const SendContainerInformation = styled(OuterContainer)`
	width: 50%;
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 16px;
`;
