import { FC, useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { FormItem, Chip } from "tap2visit-ui-kit";

import { TNotificationType } from "interfaces/IVote";

import PollsEditStore from "../store/PollsItem.store";

interface IPollSendMethods {
	editable?: boolean;
}

const PollSendMethods: FC<IPollSendMethods> = ({ editable }) => {
	const [sendMethods, setSendMethods] = useState([
		{ key: "PUSH" as TNotificationType, text: "Моб. приложение", selected: false },
		// { key: "EMAIL" as TNotificationType, text: "Эл. почта", selected: false },
		// { key: "SMS" as TNotificationType, text: "SMS", selected: false },
	]);

	useEffect(() => {
		const initialNotificationTypes = new Set(PollsEditStore.poll.notificationTypes);
		setSendMethods((methods) =>
			methods.map((method) => ({
				...method,
				selected: initialNotificationTypes.has(method.key as TNotificationType),
			})),
		);
	}, [PollsEditStore.poll.notificationTypes]);

	const handleSendMethodClick = (key: TNotificationType) => {
		PollsEditStore.changeNotificationTypes({ notificationType: key });
		setSendMethods((methods) => methods.map((method) => (method.key === key ? { ...method, selected: !method.selected } : method)));
	};

	return (
		<FormItem label="Отправить с помощью">
			<Row>
				{sendMethods.map((method) => (
					<Chip
						key={method.key}
						onClick={() => handleSendMethodClick(method.key)}
						title={method.text}
						selected={method.selected}
						size="m"
						disabled={!editable}
					/>
				))}
			</Row>
		</FormItem>
	);
};

export default observer(PollSendMethods);

const Row = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
	row-gap: 8px;
`;
